import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appDTFeatureToggle]',
  standalone: false
})
export class FeatureToggleDirective {
  // Fuer neue Feature Toggles einfach diese Liste erweitern
  features = {
    dsSucheSplitscreen: false
  };

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input() set appDTFeatureToggle(featureName: string) {
    const featureAngefragt = !featureName.startsWith('!');
    const cleanedToggleName = featureAngefragt ? featureName : featureName.substr(1);

    const featureEingeschalten = this.features[cleanedToggleName] ?? false;

    if (featureAngefragt === featureEingeschalten) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
