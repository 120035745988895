import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LayoutModule } from '@angular/cdk/layout'
import { DragDropModule } from '@angular/cdk/drag-drop'

import { AppRoutingModule } from './app-routing.module'
import { environment } from '../environments/environment'

import { NgxsModule } from '@ngxs/store'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AuftragState } from './store/states/auftrag.state'
import { EinstellungenState } from './store/states/einstellungen.state'

import { AppComponent } from './app.component'
import { KeycloakApiService } from './store/services/keycloak.api.service'
import { httpInterceptorProviders } from './http/http-interceptors'
import localeDe from '@angular/common/locales/de'

import { AuftraglistComponent } from './components/auftraglist/auftraglist.component'
import { DateienHttpService } from './store/services/dateien.http.service'
import { DokumentSplitscreenComponent } from './components/dokument-splitscreen/dokument-splitscreen.component'
import { DokumentenviewerComponent } from './components/dokumentenviewer/dokumentenviewer.component'
import { BasisFormControlDirective } from './components/formular/directives/basis-form-control.directive'
import { FormularFeldDirective } from './components/formular/directives/formular-feld.directive'
import { HeadlineComponent } from './components/formular/basis-formular-feld/headline/headline.component'
import {
  HorizontalLineComponent,
} from './components/formular/basis-formular-feld/horizontal-line/horizontal-line.component'
import { BetragComponent } from './components/formular/basis-number-input-formular-feld/betrag/betrag.component'
import { CheckboxComponent } from './components/formular/basis-input-formular-feld/checkbox/checkbox.component'
import { TextComponent } from './components/formular/basis-input-formular-feld/text/text.component'
import { DropdownComponent } from './components/formular/basis-input-formular-feld/dropdown/dropdown.component'
import { DatumComponent } from './components/formular/basis-input-formular-feld/datum/datum.component'
import { IbanComponent } from './components/formular/basis-input-formular-feld/iban/iban.component'
import { TextareaComponent } from './components/formular/basis-input-formular-feld/textarea/textarea.component'
import { AuftragComponent } from './components/auftraglist/auftrag/auftrag.component'
import { ProzentComponent } from './components/formular/basis-number-input-formular-feld/prozent/prozent.component'
import { UnknownComponent } from './components/formular/basis-formular-feld/unknown/unknown.component'
import { MultiTextComponent } from './components/formular/basis-input-formular-feld/multi-text/multi-text.component'
import { AccessdeniedComponent } from './components/accessdenied/accessdenied.component'
import { HinweisComponent } from './components/formular/basis-formular-feld/hinweis/hinweis.component'
import {
  CookieZustimmungsDialogComponent,
} from './components/cookies/cookie-zustimmung/cookie-zustimmungs-dialog/cookie-zustimmungs-dialog.component'

import { AngularIbanModule } from 'angular-iban'
import { GanzzahlComponent } from './components/formular/basis-number-input-formular-feld/ganzzahl/ganzzahl.component'
import { UploadComponentsModule } from 'upload-components'
import { take } from 'rxjs/operators'
import { FotokollektorModule } from '../../projects/fotokollektor/src/lib/fotokollektor.module'
import { DownloadAuftragComponent } from './components/download-auftrag/download-auftrag.component'
import { FeatureToggleDirective } from './directive/feature-toggle.directive'
import {
  DezimalzahlComponent,
} from './components/formular/basis-number-input-formular-feld/dezimalzahl/dezimalzahl.component'
import { DynSelectComponent } from './components/formular/basis-input-formular-feld/dyn-select/dyn-select.component'
import {
  DynAutocompleteComponent,
} from './components/formular/basis-input-formular-feld/dyn-autocomplete/dyn-autocomplete.component'
import { LeistungsradarModule } from '../../projects/leistungsradar/src/dt/leistungsradar.module'
import {
  LeistungsradarComponent,
} from './components/formular/basis-input-formular-feld/leistungsradar/leistungsradar.component'
import { ServiceWorkerModule } from '@angular/service-worker'
import { AppInitService } from './services/app-init.service'
import { InstallPwaToHomeScreenService } from './services/install-pwa-to-home-screen.service'
import { EnvironmentInfoService } from '../../projects/environment-info/src/lib/environment-info.service'
import { UserlaneInitService } from './services/userlane-init.service'
import { LocationWrapperService } from './services/location-wrapper.service'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { ApplicationInsightsService, DT_FE_CUSTOM_EVENT } from './services/application-insights.service'
import { LinkComponent } from './components/formular/basis-formular-feld/link/link.component'
import {
  WertEinheitComponent,
} from './components/formular/basis-number-input-formular-feld/wert-einheit/wert-einheit.component'
import { KennzeichenComponent } from './components/formular/basis-input-formular-feld/kennzeichen/kennzeichen.component'
import { ReadonlyTemplateComponent } from './components/formular/readonly-template/readonly-template.component'
import { UpdatePwaComponent } from './components/update-pwa/update-pwa.component'
import { AuftragBearbeitungComponent } from './components/auftrag-bearbeitung/auftrag-bearbeitung.component'
import {
  VertragsFormularComponent,
} from './components/auftrag-bearbeitung/components/vertrags-formular/vertrags-formular.component'
import { VertragComponent } from './components/auftrag-bearbeitung/components/vertrag/vertrag.component'
import { FormularListService } from './components/formular/services/formular-list.service'
import { KundenzuordnungComponent } from './components/auftrag-bearbeitung/components/kunde/kundenzuordnung.component'
import { DatenturboComponent } from './components/datenturbo/datenturbo.component'
import {
  HaushaltsmitgliedAuswahlComponent,
} from './components/auftrag-bearbeitung/components/kunde/haushaltsmitglied-auswahl/haushaltsmitglied-auswahl.component'
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { SingleSelectComponent } from './components/formular/basis-input-formular-feld/single-select/single-select.component';
import {
  GehaltComponent
} from './components/formular/basis-unterformular/basis-einkommen-unterformular/gehalt/gehalt.component'
import {
  SonderzahlungComponent
} from './components/formular/basis-unterformular/basis-einkommen-unterformular/sonderzahlung/sonderzahlung.component'

export function initApp(appLoadService: AppInitService) {
  return () => appLoadService.init()
}

export function installPwa(installPwa2HS: InstallPwaToHomeScreenService) {
  return () => installPwa2HS.init()
}

const NgxsDevTools = environment.debuggingLogs ? [NgxsReduxDevtoolsPluginModule.forRoot()] : []
const NgxsLogger = environment.debuggingLogs ? [NgxsLoggerPluginModule.forRoot()] : []

const keycloakApiService = new KeycloakApiService()
const locationWrapperService = new LocationWrapperService()

@NgModule({
  declarations: [
    DatenturboComponent,
    AppComponent,
    AuftraglistComponent,
    DokumentSplitscreenComponent,
    DokumentenviewerComponent,
    BasisFormControlDirective,
    FormularFeldDirective,
    FeatureToggleDirective,
    HeadlineComponent,
    HorizontalLineComponent,
    BetragComponent,
    CheckboxComponent,
    TextComponent,
    DropdownComponent,
    DatumComponent,
    IbanComponent,
    TextareaComponent,
    AuftragComponent,
    ProzentComponent,
    MultiTextComponent,
    UnknownComponent,
    AccessdeniedComponent,
    HinweisComponent,
    CookieZustimmungsDialogComponent,
    DezimalzahlComponent,
    GanzzahlComponent,
    DownloadAuftragComponent,
    DynSelectComponent,
    DynAutocompleteComponent,
    LeistungsradarComponent,
    LinkComponent,
    WertEinheitComponent,
    KennzeichenComponent,
    ReadonlyTemplateComponent,
    UpdatePwaComponent,
    AuftragBearbeitungComponent,
    VertragComponent,
    VertragsFormularComponent,
    KundenzuordnungComponent,
    HaushaltsmitgliedAuswahlComponent,
    SingleSelectComponent,
    GehaltComponent,
    SonderzahlungComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    DragDropModule,
    UploadComponentsModule,
    NgxsModule.forRoot([AuftragState, EinstellungenState], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: 'dceinstellungen'
    }),
    NgxsDevTools,
    NgxsLogger,
    HttpClientModule,
    ReactiveFormsModule,
    AngularIbanModule,
    FormsModule,
    DesignSystemModule,
    UploadComponentsModule,
    FotokollektorModule,
    LeistungsradarModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    httpInterceptorProviders,
    // set default locale
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: installPwa,
      deps: [InstallPwaToHomeScreenService],
      multi: true
    },
    ApplicationInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: KeycloakApiService,
      useValue: keycloakApiService
    },
    DateienHttpService,
    {
      provide: 'window',
      useValue: window,
    },
    {
      provide: 'document',
      useValue: document,
    },
    DecimalPipe,
    DatePipe
  ]
})
export class AppModule implements DoBootstrap {

  constructor(private environmentInfoService: EnvironmentInfoService,
              private userlaneInitService: UserlaneInitService,
              private formularListService: FormularListService,
              private applicationInsightsService: ApplicationInsightsService) {
  }

  ngDoBootstrap(appRef: ApplicationRef) {
    // register locale
    registerLocaleData(localeDe, 'de')

    if (window.location.pathname.startsWith('/qrfotoupload')) {
      appRef.bootstrap(AppComponent)
      this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.QR_UPLOAD_GESTARTET)
    } else {
      keycloakApiService.initKeycloak()
        .pipe(take(1))
        .subscribe(initResult => {
          if (initResult.loggedIn) {

            this.applicationInsightsService.logAppGestartetAlsBenutzertyp(keycloakApiService.getTokenParsedItem('preferred_username'), keycloakApiService.hasContextUser())

            this.userlaneInitService.initUserlane(keycloakApiService.getVBNummerFromToken(), {
              vorname: keycloakApiService.getTokenParsedItem('given_name'),
              name: keycloakApiService.getTokenParsedItem('family_name')
            })
            this.formularListService.initialLoadFormulare()
          }
          appRef.bootstrap(AppComponent)
          /* Use this only when ServiceWorker does not work
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately'})
          */
        })
    }
  }
}
