import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Breadcrumb {
  label: string;
  navigateTo: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumb: Breadcrumb;
  private breadcrumbSubject: Subject<Breadcrumb> = new Subject<Breadcrumb>();
  public breadcrumb$ = this.breadcrumbSubject.asObservable();

  setBreadcrumb(breadcrumb: Breadcrumb) {
    this.breadcrumbSubject.next(breadcrumb);
  }
  resetBreadcrumbs() {
    this.breadcrumbSubject.next(null);
  }
}
