import { State, Action, StateContext, Selector } from '@ngxs/store';

import { SetShowAusfuellhilfeOcrLight } from '../actions/einstellungen.actions';
import { Injectable } from '@angular/core';

export class EinstellungenStateModel {
  public showAusfuellhilfeOcrLight: boolean;
}

@State<EinstellungenStateModel>({
  name: 'dceinstellungen',
  defaults: {
    showAusfuellhilfeOcrLight: true
  }
})
@Injectable()
export class EinstellungenState {
  @Selector()
  static getShowAusfuellhilfeOcrLight(state: EinstellungenStateModel) {
    return state.showAusfuellhilfeOcrLight;
  }

  @Action(SetShowAusfuellhilfeOcrLight)
  setShowAusfuellhilfeOcrLight(
    { patchState }: StateContext<EinstellungenStateModel>,
    { showHilfe }: SetShowAusfuellhilfeOcrLight
  ) {
    patchState({
      showAusfuellhilfeOcrLight: showHilfe
    });
  }
}
