import { FormularFeldConfig } from '../../../store/models/formular-feld-config.model';
import { FormGroup } from '@angular/forms';
import { FormularFeldDictionaryService } from '../../auftrag-bearbeitung/components/vertrags-formular/services/formular-feld-dictionary.service';

export abstract class BasisFormularFeld {
  private _isReadonly: boolean;

  field: FormularFeldConfig;
  formularId: string; // required by components which have to ask for formular specific data like a link or dynamic controls
  auftragId: string;
  mainFormularGroup: FormGroup;

  // TODO: isReadonly wieder als einfache Variable machen, wenn der Wechsel auf das neue DesignSystem durchgeführt wird
  get isReadonly(): boolean {
    return this._isReadonly;
  }

  set isReadonly(value: boolean) {
    if (value === null || value === undefined) return;

    const oldValue = this._isReadonly;
    this._isReadonly = value;

    if (oldValue === true && value !== oldValue) {
      this.handleStepsOnSwitchedToEditMode();
    }
  }
  formularFeldDictionaryService: FormularFeldDictionaryService;

  initBasisFormularFeld(
    group: FormGroup,
    field: FormularFeldConfig,
    formularId: string,
    isReadonly: boolean,
    auftragId?: string,
    formularFeldDictionaryService?: FormularFeldDictionaryService
  ): void {
    this.field = field;
    this.formularId = formularId;
    this.mainFormularGroup = group;
    this.auftragId = auftragId;
    this.isReadonly = isReadonly;
    this.formularFeldDictionaryService = formularFeldDictionaryService;
  }

  // TODO: Kann bei der Umstellung auf das neue DesignSystem entfernt werden.
  // Hier koennen Steps hinterlegt werden, wenn vom Readonly-Modus in den Edit-Modus gewechselt wird
  handleStepsOnSwitchedToEditMode() {
    console.log('🌦️ Switched from Readonly to Nachbarbeitung');
  }
}
