import { Component } from '@angular/core';
import { BasisFormularFeld } from '../basis-formular-feld';

@Component({
  selector: 'app-hinweis',
  templateUrl: './hinweis.component.html',
  styleUrls: ['./hinweis.component.scss'],
  standalone: false
})
export class HinweisComponent extends BasisFormularFeld {}
