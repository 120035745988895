import { Component, OnDestroy } from '@angular/core';
import { CookieZustimmungService } from '../cookie-zustimmung.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cookie-zustimmungs-dialog',
  templateUrl: './cookie-zustimmungs-dialog.component.html',
  styleUrls: ['./cookie-zustimmungs-dialog.component.scss'],
  standalone: false
})
export class CookieZustimmungsDialogComponent implements OnDestroy {
  private showCookieDialogSubscription: Subscription;
  private datenschutzLink = 'https://www.dvag.de/dvag/datenschutz.html';
  showAlert: boolean = false;

  constructor(public cookieZustimmungService: CookieZustimmungService) {
    this.showCookieDialogSubscription = cookieZustimmungService.showCookieDialog$.subscribe(it => {
      this.showAlert = it;
    });
  }

  public openDatenschutz(): void {
    window.open(this.datenschutzLink, '_blank', 'noopener');
  }

  ngOnDestroy() {
    this.showCookieDialogSubscription?.unsubscribe();
  }

  closeModal() {
    this.showAlert = false;
    this.cookieZustimmungService.setCookie();
  }
}
