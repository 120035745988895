<dx-text type="ps">{{ field.label }}</dx-text>

<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <div formGroupName="{{ field.name }}">
    <dx-grid mq1="6-6/6-(6)">
      <dx-number-input
        #sonderzahlung_netto_El
        id="sonderzahlung_netto"
        label="Jährlich netto"
        [value]="initialValue?.netto?.wert"
        (focus)="saveNameForOCR('netto', sonderzahlung_netto_El, OCRType.BETRAG)"
        (valueChange)="enableFormControls($event.detail)"
        formControlName="netto"
        profile="currency-euro"
        kisynced="true"
        [errormessage]="formControl.get('netto').invalid ? getErrorMessage('netto') : null"
        [attr.templatevalue]="initialValue?.netto?.aiPredicted ? initialValue?.netto?.wert : null"
        [templateindicatortype]="'ocr'"
      ></dx-number-input>
      <dx-number-input
        #sonderzahlung_brutto_El
        id="sonderzahlung_brutto"
        [value]="initialValue?.brutto?.wert"
        (focus)="saveNameForOCR('brutto', sonderzahlung_brutto_El, OCRType.BETRAG)"
        label="Jährlich brutto"
        formControlName="brutto"
        profile="currency-euro"
        kisynced="true"
        (valueChange)="resetValueComplete()"
        (valueComplete)="onValueComplete()"
        [errormessage]="formControl.invalid || formControl.get('brutto').invalid ? getErrorMessage('brutto') : null"
        [attr.templatevalue]="initialValue?.brutto?.aiPredicted ? initialValue?.brutto?.wert : null"
        [templateindicatortype]="'ocr'"
      ></dx-number-input>
    </dx-grid>
    <dx-radio-button-group
      id="sonderzahlung-radio-button-group"
      label="Was möchten Sie tun?"
      formControlName="bestandsId"
      kisynced="true"
      (valueChange)="updateVersion($event.detail)"
    >
      <dx-radio-button
        label="Als neue Sonderzahlung hinzufügen"
        value=""
        id="sonderzahlung-radio-button-0"
      ></dx-radio-button>
      <dx-radio-button
        *ngFor="let sonderzahlung of sonderzahlungen; let i = index"
        label="Bestehende Sonderzahlung von {{ sonderzahlung.displayText }} ersetzen"
        [value]="sonderzahlung.bestandsId"
        id="sonderzahlung-radio-button-{{ i + 1 }}"
      >
      </dx-radio-button>
    </dx-radio-button-group>
  </div>
</div>

<div *ngIf="isReadonly" class="formcontrol-grid-readonly-item">
  <dx-text type="it" class="readonly-item">Jährlich netto</dx-text>
  <dx-text type="ps">
    {{ initialValue?.netto.wert ? (initialValue.netto.wert | number: '1.2-2' : 'de') + ' €' : '--' }}
  </dx-text>
  <dx-text type="it" class="readonly-item">Jährlich brutto</dx-text>
  <dx-text type="ps">
    {{ initialValue?.brutto.wert ? (initialValue.brutto.wert | number: '1.2-2' : 'de') + ' €' : '--' }}
  </dx-text>
</div>
