import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvironmentInfoService } from '../../../environment-info/src/lib/environment-info.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DxAlert } from '@dvag/design-system-angular';
import { ImageCropperComponent } from '../../../image-cropper/src/lib/image-cropper.component';
import { ModalButtonConfig } from '../../../upload-components/src/lib/model/modal-button-config.model';
import { ConfigOptions } from '../../../upload-components/src/lib/model/config-options.model';
import {
  ApplicationInsightsService,
  CustomAppInsightsError
} from '../../../../src/app/services/application-insights.service';

export interface ExtendedUploadFile {
  fileBlob: File;
  previewUrl: SafeResourceUrl;
  isPasswortGeschuetzt: boolean;
  originalFile?: File;
  isToBig?: boolean;
}

export enum DateiauswahlButtonIds {
  ADD_FILES = 'upload-dialog-add-files',
  UPLOAD = 'upload-dialog-ubernehmen',
  CROPPER_CANCEL = 'cropper-cancel',
  CROPPER_CONFIRM = 'cropper-confirm'
}

export enum FileInputErrors {
  INVALID_FILE_TYPE = 'InvalidFileType',
  FILE_TOO_LARGE = 'FileTooLarge',
  TOO_MANY_FILES = 'TooManyFiles',
  PASSWORD_PROTECTED_FILE = 'PasswordProtectedFile',
  TOTAL_FILE_SIZE_TOO_LARGE = 'TotalFileSizeTooLarge',
  FILENAME_ALREADY_EXISTS = 'FilnameAlreadyExists'
}

@Component({
  selector: 'lib-dateiauswahl',
  templateUrl: './dateiauswahl.component.html',
  styleUrls: ['./dateiauswahl.component.scss'],
  standalone: false
})
export class DateiauswahlComponent implements OnDestroy, OnInit {
  files: ExtendedUploadFile[] = [];
  mergeFilesToPDF = false;
  showMergeToPdfToggle = false;
  focusedFile = 0;
  isLoading = false;
  public isUploadable = false;
  public acceptedTypes = ['image/*', 'application/pdf'];
  public maxFilesCount = 39;
  public maxFileSize = 104857600;
  public resultFileSize: number;
  public hasToManyFiles = false;
  public hasToBigFiles = false;
  public totalFileSizeIsToBig = false;
  public hasPasswordProtectedFiles = false;
  public hasMultipleErrors = false;
  public alertTitle = '';
  public alertBody = '';
  public alertIcon = '';
  public alertType = '';
  public showCropper: boolean;
  public configMaxFilesCount: any;
  public forceCropping = false;
  readonly maxFilesCountForMergeToPdf = 150;

  @ViewChild('uploadDialogInput') uploadDialogInput: ElementRef;
  @ViewChild('dxAlert') dxAlert: DxAlert;
  @ViewChild(ImageCropperComponent) imageCropperComponent!: ImageCropperComponent;
  @ViewChild('uploadAbbrechen') uploadAbbrechenAlert: DxAlert;

  @Input() set configOptions(config: ConfigOptions) {
    if (config) {
      if (config.resultFileSizeInMByte) {
        this.resultFileSize = config.resultFileSizeInMByte * (1024 * 1024);
        this.maxFileSize = Math.min(config.resultFileSizeInMByte * (1024 * 1024), this.maxFileSize);
      } else {
        this.resultFileSize = null;
      }
      this.showMergeToPdfToggle = config.showMergeToPdfToggle || this.showMergeToPdfToggle;
      this.mergeFilesToPDF = config.mergeToPdfByDefault || this.mergeFilesToPDF;
      this.forceCropping = config.forceCropping ?? this.forceCropping;

      this.configMaxFilesCount = config.maxCountOfDocuments || this.configMaxFilesCount;
      if (this.mergeFilesToPDF) {
        this.maxFilesCount = this.maxFilesCountForMergeToPdf;
      } else {
        this.maxFilesCount = config.maxCountOfDocuments || this.maxFilesCount;
      }
      this.acceptedTypes = config.acceptedFileTypes || this.acceptedTypes;
    }

    this.useHeadline.emit(this.maxFilesCount === 1 ? 'Dokument hochladen' : 'Dokumente hochladen');
    if (this.maxFilesCount === 1) {
      this.useButtons.emit(this.getDateiauswahlButtons());
    }
    this.changeDetectorRef.detectChanges();
  }

  @Input() bucketId: string;

  @Input({ transform: booleanAttribute }) showBackButton: boolean = false;

  @Input() set modalClosedEvent(value: any) {
    if (value) {
      if (this.files.length > 0) {
        this.uploadAbbrechenAlert.visible = true;
      } else {
        this.closeModal.emit();
        this.cleanUpData();
      }
    }
  }

  @Output()
  closeModal: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  backButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  // Der Event darf nur gefeuert werden, wenn der Button jetzt hochladen gedrückt wurde.
  // Er liefert die Files und suggeriert, dass der Upload erfolgen soll. Somit feuert der Event nur einmal.
  @Output()
  uploadTriggered: EventEmitter<{ dateien: File[]; mergeToPdf: boolean }> = new EventEmitter<{
    dateien: File[];
    mergeToPdf: boolean;
  }>();

  @Output()
  useButtons: EventEmitter<ModalButtonConfig[]> = new EventEmitter<ModalButtonConfig[]>();

  @Output()
  useHeadline: EventEmitter<string> = new EventEmitter<string>();

  private backButton: ModalButtonConfig = {
    label: 'Zurück',
    slot: 'secondary-actions',
    id: 'back-button',
    type: 'text',
    icon: 'pfeil-links',
    clickFn: () => {
      this.backButtonClicked.emit();
    }
  };

  private dateiauswahlButtons: ModalButtonConfig[] = [
    {
      label: 'Dokumente hinzufügen',
      icon: 'plus',
      slot: 'primary-actions',
      id: DateiauswahlButtonIds.ADD_FILES,
      type: 'secondary-s',
      disabled: this.files.length >= this.maxFilesCount,
      clickFn: () => {
        this.uploadDialogInput.nativeElement.click();
      }
    },
    {
      label: 'Jetzt hochladen',
      icon: 'upload',
      slot: 'primary-actions',
      id: DateiauswahlButtonIds.UPLOAD,
      type: 'primary-s',
      disabled: this.isUploadable,
      clickFn: () => {
        this.uploadTriggered.emit({
          dateien: this.files.filter(file => !file.isPasswortGeschuetzt).map(file => file.fileBlob),
          mergeToPdf: this.mergeFilesToPDF
        });
      }
    }
  ];

  // Diese Buttons müssen in den ImageCropper
  private cropperButtons: ModalButtonConfig[] = [
    {
      label: 'Weiter ohne Zuschneiden',
      slot: 'secondary-actions',
      type: 'text',
      id: DateiauswahlButtonIds.CROPPER_CANCEL,
      clickFn: () => {
        console.log('Cancel Cropper ');
        this.cancelCrop();
        this.useButtons.emit(this.getDateiauswahlButtons());
        this.useHeadline.emit(this.maxFilesCount === 1 ? 'Dokument hochladen' : 'Dokumente hochladen');
      }
    },
    {
      label: 'Zuschneiden',
      slot: 'primary-actions',
      type: 'text',
      id: DateiauswahlButtonIds.CROPPER_CONFIRM,
      clickFn: () => {
        console.log('Cropper übernehmen');
        this.confirmCrop();
        this.useButtons.emit(this.getDateiauswahlButtons());
        this.useHeadline.emit(this.maxFilesCount === 1 ? 'Dokument hochladen' : 'Dokumente hochladen');
      }
    }
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    public environmentInfoService: EnvironmentInfoService,
    private appInsightsService: ApplicationInsightsService
  ) {}

  ngOnInit(): void {
    this.useButtons.emit(this.getDateiauswahlButtons());
  }

  public async handleFileInput(selectedFiles: FileList) {
    await Promise.all(
      Array.from(selectedFiles).map(async newFile => {
        const resArr = this.acceptedTypes.filter(fileType => {
          const acceptedTypeArr = fileType.split('/');
          const fileTypeArr = newFile.type.split('/');
          return (
            acceptedTypeArr[0] === fileTypeArr[0] &&
            (acceptedTypeArr[1] === '*' || acceptedTypeArr[1] === fileTypeArr[1]) &&
            fileTypeArr[1] !== 'heic' &&
            fileTypeArr[1] !== 'heif'
          );
        });
        if (resArr.length === 0) {
          this.appInsightsService.logDateiauswahlAlert(
            FileInputErrors.INVALID_FILE_TYPE,
            this.getAdditionalInfoForAppInsights({
              fileType: newFile.type
            })
          );
        }

        if (resArr.length > 0) {
          await this.addNotExistingFilesToList(newFile);
        }
      })
    );
    this.checkForValidFilesCount();
    this.checkForPasswortProtectedFiles();
    this.checkForToBigFiles();
    this.checkForMergeFilesToPdfAndTotalFileSize();

    this.setFocusedFile(this.files.length - 1);
    if (this.uploadDialogInput) {
      this.uploadDialogInput.nativeElement.value = '';
    }

    this.checkForIsUploadable();

    if (!this.isUploadable && this.files.length > 0) {
      this.handleAlertPresentation();
    }

    await this.checkForForceCropping();

    this.changeDetectorRef.detectChanges();
  }

  private async checkForForceCropping() {
    if (
      this.forceCropping &&
      this.maxFilesCount === 1 &&
      this.files.length > 0 &&
      !this.files[this.focusedFile].fileBlob.type.includes('application')
    ) {
      await this.openCropper(this.files[this.focusedFile].fileBlob);
    }
  }

  private async addNotExistingFilesToList(newFile: File) {
    if (!this.files.some(file => file.fileBlob.name === newFile.name)) {
      const datei: ExtendedUploadFile = {
        fileBlob: newFile,
        previewUrl: this.getPreviewURL(newFile),
        isPasswortGeschuetzt: await this.isDateiPasswortGeschuetzt(newFile),
        originalFile: newFile,
        isToBig: newFile.size > this.maxFileSize
      };
      datei.isPasswortGeschuetzt || datei.isToBig ? this.files.unshift(datei) : this.files.push(datei);
    } else {
      this.appInsightsService.logDateiauswahlAlert(
        FileInputErrors.FILENAME_ALREADY_EXISTS,
        this.getAdditionalInfoForAppInsights()
      );
      console.warn('DokumentUploadComponent handleFileInput(): file already exist in the list: ', newFile.name);
    }
  }

  checkForMergeFilesToPdfAndTotalFileSize() {
    this.totalFileSizeIsToBig =
      this.resultFileSize != null && this.mergeFilesToPDF && this.getTotalFileSize() > this.resultFileSize;
    if (this.totalFileSizeIsToBig) {
      this.appInsightsService.logDateiauswahlAlert(
        FileInputErrors.TOTAL_FILE_SIZE_TOO_LARGE,
        this.getAdditionalInfoForAppInsights({
          maxFileSizeInMB: this.resultFileSize / (1024 * 1024),
          totalFileSizeInMB: this.getTotalFileSize() / (1024 * 1024)
        })
      );
    }
  }

  private getTotalFileSize(): number {
    let totalSize = 0;
    for (const file of this.files) {
      totalSize += file.fileBlob.size;
    }
    return totalSize;
  }

  onDeleteFile(fileNow: File, idxNow: number) {
    if (this.files.length > idxNow && this.files[idxNow].fileBlob === fileNow) {
      this.files.splice(idxNow, 1);
      this.checkForValidFilesCount();
      this.checkForPasswortProtectedFiles();
      this.checkForToBigFiles();
      this.checkForMergeFilesToPdfAndTotalFileSize();
    }

    if (this.files.length === 0) {
      this.focusedFile = 0;
    } else {
      // Ensure to select the same file after deletion
      if (idxNow < this.focusedFile) {
        this.focusedFile -= 1;
      }

      if (this.focusedFile > this.files.length - 1) {
        this.setFocusedFile(this.files.length - 1);
      }
    }
    this.checkForIsUploadable();
    this.changeDetectorRef.detectChanges();
  }

  handleAlertPresentation() {
    if (this.hasMultipleErrors) {
      this.alertTitle = 'Hochladen nicht möglich';
      this.alertBody = '';
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
      this.dxAlert.visible = true;
      this.changeDetectorRef.detectChanges();
      return;
    }

    if (this.hasToBigFiles && !this.mergeFilesToPDF) {
      this.alertTitle = 'Zu große Dokumente gefunden';
      this.alertBody = `Mindestens ein von Ihnen hinzugefügtes Dokument hat die Maximalgröße von ${this.maxFileSize / (1024 * 1024)} MB überschritten und kann daher nicht hochgeladen werden. \n\nBitte entfernen Sie die gekennzeichneten Dokumente, komprimieren oder teilen Sie diese und fügen Sie die neuen Dateien wieder hinzu.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
    } else if (this.hasToManyFiles && this.maxFilesCount === 1) {
      this.alertTitle = 'Nur 1 Dokument möglich';
      this.alertBody = `Bitte reduzieren Sie die Anzahl der Dokumente, da maximal 1 Dokument hochgeladen werden kann.\n\nFalls es sich um einzelne Seiten eines zusammengehörigen Dokuments handelt, fügen Sie diese bitte zu einer PDF-Datei zusammen. Andernfalls entfernen Sie bitte die zusätzlichen Dokumente.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
    } else if (this.hasToManyFiles) {
      this.alertTitle = 'Zu viele Dokumente';
      this.alertBody = `Bitte reduzieren Sie die Anzahl der Dokumente, da maximal ${this.maxFilesCount} Dokumente hochgeladen werden können.\n\nFalls es sich um einzelne Seiten eines zusammengehörigen Dokuments handelt, fügen Sie diese bitte zu einer PDF-Datei zusammen. Andernfalls entfernen Sie bitte die zusätzlichen Dokumente.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
    } else if (this.hasPasswordProtectedFiles) {
      this.alertTitle = 'Kennwortgeschützte Dokumente gefunden';
      this.alertBody = `Mindestens ein von Ihnen hinzugefügtes Dokument ist kennwortgeschützt und kann daher nicht hochgeladen werden.\n
Bitte entfernen Sie die gekennzeichneten Dokumente und fügen diese erneut hinzu, sobald Sie den Kennwortschutz aufgelöst haben.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
    } else if (this.totalFileSizeIsToBig && this.mergeFilesToPDF) {
      this.alertTitle = 'Zusammengefügte PDF-Datei zu groß';
      this.alertBody = `Die Größe der zusammengefügten Datei übersteigt die maximale Größe von ${this.maxFileSize / (1024 * 1024)} MB und kann daher nicht hochgeladen werden.\n\nBitte entfernen Sie die Dateien und komprimieren Sie diese, bevor Sie sie erneut hochladen und zusammenfügen.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';
    } else {
      this.alertTitle = 'Unerwarteter Fehler';
      this.alertBody = `Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut.`;
      this.alertIcon = 'warndreieck';
      this.alertType = 'error';

      const customError: CustomAppInsightsError = new CustomAppInsightsError(
        'Unerwarteter Fehler in handleAlertPresentation()',
        { hostname: window.location.hostname }
      );
      this.appInsightsService.logError('WebComponentDateiauswahl', customError);
    }
    this.dxAlert.visible = true;
    this.changeDetectorRef.detectChanges();
  }

  checkForValidFilesCount() {
    this.hasToManyFiles = this.files.length > this.maxFilesCount;
    if (this.hasToManyFiles) {
      this.appInsightsService.logDateiauswahlAlert(
        FileInputErrors.TOO_MANY_FILES,
        this.getAdditionalInfoForAppInsights({
          maxFilesCount: this.maxFilesCount,
          userAddedFilesCount: this.files.length
        })
      );
    }
  }

  checkForPasswortProtectedFiles() {
    this.hasPasswordProtectedFiles = this.files.some(file => file.isPasswortGeschuetzt);
    if (this.hasPasswordProtectedFiles) {
      this.appInsightsService.logDateiauswahlAlert(
        FileInputErrors.PASSWORD_PROTECTED_FILE,
        this.getAdditionalInfoForAppInsights()
      );
    }
  }

  checkForToBigFiles() {
    this.hasToBigFiles = this.files.some(file => file.fileBlob.size > this.maxFileSize);
    if (this.hasToBigFiles) {
      this.appInsightsService.logDateiauswahlAlert(
        FileInputErrors.FILE_TOO_LARGE,
        this.getAdditionalInfoForAppInsights({
          maxFileSize: this.maxFileSize,
          userAddedFileSizesInMB: this.files
            .filter(file => file.isToBig)
            .map(file => file.fileBlob.size / (1024 * 1024))
        })
      );
    }
  }

  private getAdditionalInfoForAppInsights(specificInfos?: { [key: string]: any }): any {
    const additionalInfo = {
      ...specificInfos,
      bucketId: this.bucketId
    };
    if (this.configOptions?.documentUploadEndpoint) {
      additionalInfo['documentUploadEndpoint'] = this.configOptions.documentUploadEndpoint;
    }

    return additionalInfo;
  }

  dropFile(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    this.setFocusedFile(event.currentIndex);
    this.checkForIsUploadable();
    this.changeDetectorRef.detectChanges();
  }

  setFocusedFile(idx: number) {
    this.focusedFile = idx;
    this.changeDetectorRef.detectChanges();
  }

  getPreviewURL(file: File): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
  }

  changedPdfCheckBox(value: any) {
    this.mergeFilesToPDF = value.detail;
    this.checkForMergeFilesToPdfAndTotalFileSize();
    if (this.totalFileSizeIsToBig) {
      this.handleAlertPresentation();
    }

    if (this.mergeFilesToPDF === false) {
      // Zurücksetzen auf in der Config eingestellten Wert
      this.maxFilesCount = this.configMaxFilesCount;
      // Wenn die Dateien-Anzahl größer ist als der maxFilesCount, dann wird die hasToManyFiles auf true gesetzt und der Alert wird angezeigt
      if (this.files.length > this.maxFilesCount) {
        this.hasToManyFiles = true;
      }
    } else if (this.mergeFilesToPDF === true) {
      this.maxFilesCount = this.maxFilesCountForMergeToPdf;
      this.checkForValidFilesCount();
    }
    this.checkForIsUploadable();
    if (!this.isUploadable) {
      this.handleAlertPresentation();
    }
    this.useButtons.emit(this.getDateiauswahlButtons());
    this.useHeadline.emit(this.maxFilesCount === 1 ? 'Dokument hochladen' : 'Dokumente hochladen');
    this.changeDetectorRef.detectChanges();
  }

  cleanUpData() {
    this.files = [];
    this.focusedFile = 0;
    this.mergeFilesToPDF = false;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.cleanUpData();
  }

  getAltTextForPreviewImage() {
    return 'Vorschau zu Datei "' + this.files[this.focusedFile].fileBlob.name + '"';
  }

  /**
   * Prueft, ob die uebergebene Datei Passwort geschuetzt ist.
   * https://stackoverflow.com/questions/14806868/how-to-check-pdf-file-is-password-protected
   * @param file
   * @return true, wenn ein Passwort-Schutz existiert, ansonsten false
   */
  private async isDateiPasswortGeschuetzt(file: File) {
    let isEncrypted = false;
    if (file && file.type === 'application/pdf') {
      const pdfContent = await file.text();
      isEncrypted = pdfContent.split('trailer').pop().includes('/Encrypt');
    }
    return isEncrypted;
  }

  //Convert base64-encoded image to a File, and then use this File to update the corresponding entry in the files array
  async applyCroppedImage(base64Image: string) {
    const blob = await (await fetch(base64Image)).blob();

    // Convert blob to file
    const blobToFile = new File([blob], this.files[this.focusedFile].fileBlob.name, { type: 'image/png' });

    this.files[this.focusedFile].fileBlob = blobToFile;
    this.files[this.focusedFile].previewUrl = this.getPreviewURL(blobToFile);

    this.checkForIsUploadable();
    this.showCropper = false;
    this.changeDetectorRef.detectChanges();
  }

  async openCropper(fileNow: File) {
    this.isLoading = true;

    const originalFile = this.files.find(file => file.fileBlob.name === fileNow.name)?.originalFile;

    if (!originalFile) {
      console.error('Original file not found:', fileNow.name);
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
      return;
    }

    try {
      await this.imageCropperComponent.openCropper(originalFile);
    } catch (error) {
      console.error('Error during cropping:', error);
    } finally {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  isCropperOpen(isOpen: boolean) {
    if (isOpen) {
      this.useHeadline.emit('Dokument zuschneiden');
      this.useButtons.emit(this.cropperButtons);
    }
  }

  confirmCrop() {
    this.imageCropperComponent.confirmed();
  }

  cancelCrop() {
    this.imageCropperComponent.canceled();
  }

  cancelUpload() {
    this.uploadAbbrechenAlert.visible = false;
    this.closeModal.emit();
    this.cleanUpData();
  }

  checkForIsUploadable() {
    const tempValidArray = [this.hasToManyFiles, this.hasPasswordProtectedFiles, this.files.length === 0];
    if (this.mergeFilesToPDF) {
      tempValidArray.push(this.totalFileSizeIsToBig);
    } else {
      tempValidArray.push(this.hasToBigFiles);
    }
    this.isUploadable = tempValidArray.every(value => !value); //Der Upload ist nur dann möglich, wenn alle Bedingungen false sind.
    this.hasMultipleErrors = tempValidArray.filter(value => value).length > 1;
    this.useButtons.emit(this.getDateiauswahlButtons());
  }

  private getDateiauswahlButtons(): ModalButtonConfig[] {
    this.updateButtonDisabledStatus();
    if (this.maxFilesCount === 1) {
      this.dateiauswahlButtons[0].label = 'Dokument hinzufügen';
    } else {
      this.dateiauswahlButtons[0].label = 'Dokumente hinzufügen';
    }
    if (this.showBackButton) {
      return [this.backButton, ...this.dateiauswahlButtons];
    } else {
      return this.dateiauswahlButtons;
    }
  }

  private updateButtonDisabledStatus(): void {
    this.dateiauswahlButtons.forEach(button => {
      if (button.id === DateiauswahlButtonIds.UPLOAD) {
        button.disabled = !this.isUploadable || this.files.length > this.maxFilesCount;
      }
      if (button.id === DateiauswahlButtonIds.ADD_FILES) {
        button.disabled = this.files.length >= this.maxFilesCount && this.mergeFilesToPDF === false;
      }
    });
  }
}
