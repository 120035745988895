import { Injectable } from '@angular/core';
import { KundenHttpService } from '../../../store/services/kunden.http.service';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { Haushalt } from '../../../store/models/kunde.model';
import { ApplicationInsightsService } from '../../../services/application-insights.service';
import { Haushaltsmitglied } from '../components/kunde/model/haushaltsmitglied.model';
import { catchError, map, shareReplay, take, tap } from 'rxjs/operators';

@Injectable()
export class HaushaltService {
  private haushalteSubject: ReplaySubject<Haushalt[]> = new ReplaySubject(1);

  constructor(
    private kundenHttpService: KundenHttpService,
    private appInsightsService: ApplicationInsightsService
  ) {}

  fetchHaushalte(kundenNummer: string): void {
    if (!kundenNummer) {
      console.warn(
        `Die Haushalte konnten nicht geladen werden, da nötige Parameter fehlen! KundenNummer: ${kundenNummer}`
      );
      this.appInsightsService.logError('HaushaltsService.fetchHaushalte: Aufruf ohne Kundennummer nicht möglich.');
      this.haushalteSubject.next([]);
      return;
    }

    this.kundenHttpService
      .fetchHaushalte(kundenNummer)
      .pipe(take(1))
      .subscribe({
        next: (haushalte: Haushalt[]) => {
          this.haushalteSubject.next(haushalte);
        },
        error: () => {
          this.haushalteSubject.error('Could not load Haushalte.');
        }
      });
  }

  /**
   * Liefert eine Liste von Haushalten, die zu einem Kunden gehören.
   **/
  get haushalte$() {
    return this.haushalteSubject.asObservable().pipe(shareReplay(1));
  }

  /**
   * Liefert eine Liste von Haushaltsmitgliedern, die aus allen Haushalten stammen.
   **/
  get kumulierteHaushaltsMitglieder$(): Observable<Haushaltsmitglied[]> {
    // Merge die HH-Adresse und die Mitglieder zusammen
    return this.haushalte$.pipe(
      map(haushalte =>
        haushalte.flatMap(
          ({ adresse, mitglieder }) =>
            mitglieder?.map(
              mitglied =>
                ({
                  name: mitglied.vorname + ' ' + mitglied.nachname,
                  geburtsDatum: mitglied.geburtsDatum,
                  adresse: adresse.strasse + ' ' + adresse.plz + ' ' + adresse.ort,
                  kundenNummer: mitglied.nummer,
                  eweZugestimmt: mitglied.eweZugestimmt
                }) as Haushaltsmitglied
            ) ?? []
        )
      ),
      shareReplay(1)
    );
  }
}
