<dx-app id="app">
  <dx-header slot="header" id="dt-app-header" appname="Datenturbo" (navigateHome)="navigateHome()" hidemainnavigation>
    <dx-header-menu-link
      priority="3"
      *ngIf="showTestLink"
      id="header-action-foto"
      slot="menu"
      label="Test-Foto"
      (menuInteraction)="openLink('https://datenturbo.entwicklung.dvag/dashboard/elements-plain/')"
    ></dx-header-menu-link>
    <dx-header-menu-link
      priority="4"
      *ngIf="isDevelopmentEnv"
      id="header-action-swagger"
      slot="menu"
      label="Swagger-EW (VPN)"
      (menuInteraction)="openLink('https://datacollect.entwicklung.k8s.dvag.net/swagger-ui/index.html')"
    ></dx-header-menu-link>
    <dx-spacer size="8" slot="menu"></dx-spacer>
    <dx-spacer size="8" *ngIf="isDevelopmentEnv || showTestLink" slot="menu"></dx-spacer>
    <dx-header-user
      priority="1"
      id="header-user-interactive"
      slot="menu"
      accountavailable="false"
      name="{{ benutzerName }}"
      (signOut)="onLogout()"
      [imageurl]="avatarURL"
    >
    </dx-header-user>
    <div slot="user-content" *ngIf="hasVertreter" style="display: flex; flex-direction: column; gap: 16px">
      <dx-button
        type="text"
        id="more-menu_context_wechsel"
        label="Rolle wechseln"
        (click)="triggerKundenwechsel()"
      ></dx-button>
    </div>
    <dx-breadcrumb
      id="dt-breadcrumb"
      *ngIf="breadcrumb$ | async as breadcrumb"
      slot="breadcrumb"
      (navigateHome)="navigateHome()"
    >
      <dx-breadcrumb-item
        id="dt-breadcrumb-item-{{ breadcrumb.label }}"
        [label]="breadcrumb.label"
        [value]="breadcrumb.navigateTo"
      ></dx-breadcrumb-item>
    </dx-breadcrumb>
    <dx-representative-info
      id="dt-vertretung"
      *ngIf="contextUser"
      [name]="contextUser.name ?? contextUser.vbNummer"
      slot="representative"
      [type]="isAssistent ? 'assistant' : 'representative'"
    ></dx-representative-info>
  </dx-header>
  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
  <dx-footer slot="footer" *ngIf="showFooter" [fromcolor]="footerBgColorToUse">
    <div class="footer">
      <div class="anchor-tags">
        <dx-button
          id="footer-impressum"
          theme="default"
          type="text"
          label="Impressum"
          (click)="openLink(impressumUrl)"
        ></dx-button>
        <dx-button
          id="footer-datenschutz"
          theme="default"
          type="text"
          label="Datenschutzhinweis"
          (click)="openLink(datenschutzUrl)"
        ></dx-button>
        <div id="version-container" class="version" (dblclick)="toggleVersion()">
          <dx-text type="its" id="version">{{ version }}</dx-text>
        </div>
      </div>
      @if (showFeedbackButton) {
        <div class="feedback">
          <dx-button
            id="dt-feedback-geben"
            class="feedback-btn"
            type="primary-s"
            label="Feedback geben"
            (click)="navigateToUmfrage()"
          ></dx-button>
        </div>
      }
    </div>
  </dx-footer>
</dx-app>
<app-cookie-zustimmungs-dialog></app-cookie-zustimmungs-dialog>
