import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BasisNumberFormularFeld } from '../basis-number-formular-feld';
import { DecimalPipe } from '@angular/common';
import { AktivesEingabefeldHandlerService } from '../../basis-input-formular-feld/services/aktives-eingabefeld-handler.service';
import { Components } from '@dvag/design-system';
import DxNumberInput = Components.DxNumberInput;
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-prozent',
  templateUrl: './prozent.component.html',
  standalone: false
})
export class ProzentComponent extends BasisNumberFormularFeld {
  @ViewChild('focusElement') focusElement: DxNumberInput;
  @ViewChild('focusElement', { read: ElementRef }) elementRef: ElementRef;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    protected override decimalPipe: DecimalPipe
  ) {
    super(fb, focusedControlHandlerService, decimalPipe);
    this.einheit = '%';
  }

  override async focus(): Promise<void> {
    if (this.focusElement?.focusControl) {
      try {
        await this.focusElement?.focusControl();
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`);
      }
    } else {
      console.warn(
        `An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`
      );
    }
  }
}
