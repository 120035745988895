import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core'
import { Auftrag } from '../../store/models/auftrag.model'
import { AuftragBearbeitenService } from './services/auftrag-bearbeiten.service'
import { FormularListService } from '../formular/services/formular-list.service'
import { AuftragDatenbestand, AuftragKategorie, AuftragStatus } from '../../enums/auftrag.enums'
import { combineLatest, finalize, Observable, Subscription, takeWhile } from 'rxjs'
import { map } from 'rxjs/operators'
import { VertragComponent } from './components/vertrag/vertrag.component'
import { SyncStatus, Vertrag } from '../../store/models/vertrag.model'
import { NotificationService, NotificationTyp } from '../services/notification.service'
import { ApplicationInsightsService } from '../../services/application-insights.service'
import { DxAlert } from '@dvag/design-system-angular'
import { Router } from '@angular/router'
import { BreakpointAware } from '@dvag/design-system'
import { HotjarService } from '../../services/hotjar.service'
import { EinkommenService } from '../formular/basis-unterformular/basis-einkommen-unterformular/services/einkommen.service'

@Component({
  selector: 'app-auftrag-bearbeitung',
  templateUrl: './auftrag-bearbeitung.component.html',
  styleUrls: ['./auftrag-bearbeitung.component.scss'],
  providers: [EinkommenService]
})
export class AuftragBearbeitungComponent implements OnInit, OnDestroy {
  auftragBearbeitungEnum = AuftragStatus
  isFormularSubmitButtonDisabled = false
  isFormularNachbearbeitButtonDisabled = true
  auftragBearbeitungsStatus: AuftragStatus
  isNachbearbeitbar = true
  showSkeleton = true
  youngestBearbeitungsDatum = ''
  vertraegeInCommunicationWithBackend$: Observable<boolean>

  private vertraegeSubscription: Subscription

  private breakpointAware: BreakpointAware = new BreakpointAware(() => this.checkBreakPoint())
  currentBreakPointAsNumber: number = 5

  /**
   * Da die Entscheidung, ob der 'Buendel hinzufuegen' Button angezeigt werden kann oder nicht, erst
   * erfolgen kann, wenn die Formulare geladen wurden und beim Direkteinstieg über VPD das Laden der
   * Formulare noch nicht erfolgt ist, muss dies als Observable erfolgen. Damit die Ueberpruefung
   *
   */
  showAddBuendelVertragButton$: Observable<boolean> = combineLatest([
    this.auftragBearbeitenService.vertraege$.pipe(takeWhile(vertraege => (vertraege?.length ?? 0) > 0)),
    this.formularListService.formularListe$
  ]).pipe(
    map(([vertraege, formulare]) => {
      const isBuendelfaehig = formulare.find(formular => formular.id === vertraege[0].formularId)?.buendelvertragFaehig ?? false
      return isBuendelfaehig && this.auftragBearbeitungsStatus === this.auftragBearbeitungEnum.BEARBEITUNG
    })
  )
  public syncError = false

  @Input() set auftrag(auftrag: Auftrag) {
    if (auftrag && (auftrag.auftragId !== this.auftragBearbeitenService.auftrag?.auftragId || (this.auftragBearbeitenService.auftrag && auftrag.kundenNummer !== this.auftragBearbeitenService.auftrag?.kundenNummer))) {
      this.auftragBearbeitungsStatus = auftrag.kategorie === AuftragKategorie.OFFEN ? AuftragStatus.BEARBEITUNG : AuftragStatus.READONLY
      auftrag.vertraege = auftrag.vertraege?.map(vertrag => ({
          ...vertrag,
          isValid: true
        })
      )
      const isGehaltsnachweis: boolean = auftrag.vertraege.some(vertrag => vertrag.formularId === 'GEHALTSNACHWEIS')
      if(isGehaltsnachweis){
        this.einkommenService.fetchEinkommen(auftrag.kundenNummer)
      }
      this.isFormularNachbearbeitButtonDisabled = auftrag.datenbestand !== AuftragDatenbestand.BEREITGESTELLT
      this.auftragBearbeitenService.initAuftragService(auftrag, this.auftragBearbeitungsStatus)
      if (this.auftragBearbeitungsStatus === AuftragStatus.READONLY) {
        this.youngestBearbeitungsDatum = this.auftragBearbeitenService.getYoungestBearbeitungsdatumFromVertraege()
      }
    }
    this.checkForSyncError(auftrag)
  }

  @Output() submittedForm: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() kundenZuordnungChanged = new EventEmitter<Auftrag>()

  @ViewChild('uploadangabenalert') uploadAngabenAlert: DxAlert
  @ViewChildren(VertragComponent) vertragsComponentRefs!: VertragComponent[]
  @ViewChild('auftragSenden') auftragSendenAlert: DxAlert

  constructor(public auftragBearbeitenService: AuftragBearbeitenService,
              private formularListService: FormularListService,
              private notificationService: NotificationService,
              private applicationInsightsService: ApplicationInsightsService,
              private router: Router,
              private hotjarService: HotjarService,
              private einkommenService: EinkommenService) {
  }

  ngOnInit(): void {
    this.vertraegeSubscription = this.auftragBearbeitenService.vertraege$.subscribe(vertraege => {
      this.isFormularSubmitButtonDisabled = vertraege.some(vertrag => vertrag.isValid === false)
      this.isNachbearbeitbar = !vertraege.some(vertrag => vertrag.isNachbearbeitbar === false)
      if (this.syncError) {
        this.checkForSyncErrorAfterDelete(vertraege)
      }
    })

    this.vertraegeInCommunicationWithBackend$ = this.auftragBearbeitenService.vertraegeInCommunicationWithBackend$.pipe(
      map(vertragIds => vertragIds.length > 0)
    )

    this.checkBreakPoint()
  }

  ngOnDestroy() {
    this.breakpointAware.disconnect()
    this.vertraegeSubscription?.unsubscribe()
    this.auftragBearbeitenService.resetService()
  }
  checkBreakPoint() {
    this.currentBreakPointAsNumber = this.breakpointAware.getEffectiveValue({
      mq1: 1,
      mq2: 2,
      mq3: 3,
      mq4: 4,
      mq5: 5
    })
  }

  switchToEditMode() {
    if (this.auftragBearbeitungsStatus === this.auftragBearbeitungEnum.READONLY) {
      this.auftragBearbeitungsStatus = AuftragStatus.NACHBEARBEITUNG
    }
  }

  uploadData() {
    this.isFormularSubmitButtonDisabled = true
    this.uploadAngabenAlert.visible = true
    this.auftragBearbeitenService.sendAngaben(this.getReferenzenRelevanterVertraege()).pipe(
      finalize(() => {
        this.uploadAngabenAlert.visible = false
        this.submittedForm.emit(true)
        this.isFormularSubmitButtonDisabled = false
        let vertragIsKFZ = this.getReferenzenRelevanterVertraege().some(obj => obj.ausgewaehltesFormular?.id === "KFZ")
        if (vertragIsKFZ) {
          this.hotjarService.callHotjar()
        }
      }),
    ).subscribe({
      next: () => this.notificationService.showNotification('Erfolg', 'Sie haben die Daten erfolgreich übermittelt. Diese stehen Ihnen ab sofort in der Kundenakte zur Verfügung.', 3, NotificationTyp.success),
      error: (error) => {

        let errorMessage = 'Die Daten konnten aufgrund eines Fehlers nicht übernommen werden. Bitte versuchen Sie es erneut.'
        let errorTitle = 'Synchronisation fehlgeschlagen'
        let weiterleitungAnAuftragsnummer = null

        if (error.status === 412 || error.status === 404) {
          weiterleitungAnAuftragsnummer = this.auftragBearbeitenService.auftrag.auftragId
        }

        this.notificationService.showNotification(errorTitle, errorMessage, 10, NotificationTyp.achtung, weiterleitungAnAuftragsnummer)
      }
    })

    this.applicationInsightsService.logAngabenUbernommen(this.auftragBearbeitungsStatus, this.getReferenzenRelevanterVertraege().length);
  }


  containsUnsavedData(): boolean {
    return this.getReferenzenRelevanterVertraege().some(ref => ref.hasVertragUnsavedData())
  }

  // Holt sich alle Verträge ohne die Verträge, welche nur Hüllen sind
  private getReferenzenRelevanterVertraege(): VertragComponent[] {
    const currVertraege = this.auftragBearbeitenService.auftrag?.vertraege.filter(vertrag => vertrag.formularId)
    return this.vertragsComponentRefs.filter(vertragRef => currVertraege.some(vertrag => vertrag.vertragId === vertragRef.vertrag.vertragId))
  }

  /**
   * Add a new contract and scroll to
   */
  addBuendelvertrag() {
    console.log('AuftragBearbeitungComponent.addBuendelvertrag')
    this.auftragBearbeitenService.addVertragHuelle()
  }

  trackByVertragId(_, {vertragId}) {
    return vertragId
  }

  checkForSyncError(auftrag) {
    this.syncError = auftrag?.vertraege.some(vertrag => vertrag.syncStatus === SyncStatus.SYNCFEHLER)
  }

  async checkForSyncErrorAfterDelete(vertraege) {
    const anzahlVertraegeMitSyncError = vertraege.filter(vertrag => vertrag.syncStatus === SyncStatus.SYNCFEHLER).length
    if (anzahlVertraegeMitSyncError >= 1) {
      return
    } else {
      this.auftragSendenAlert.visible = true
    }
  }

  onAlertAuftragSenden(){
    this.auftragSendenAlert.visible = false
    this.uploadData()
  }

  private containsAssignedVertraegeToFremdvertraege(vertraege: Vertrag[]) {
    return vertraege.some(vertrag => vertrag.kiFremdvertragId)
  }

  emitKundenNameChanged(auftrag: Auftrag) {
    this.kundenZuordnungChanged.emit(auftrag)
  }

  async closeDocument(){
    await this.router.navigate(['/dashboard'])
  }
}
