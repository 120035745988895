import { Inject, Injectable } from '@angular/core';
import { SwUpdate, VersionDetectedEvent } from '@angular/service-worker';
import { filter, take } from 'rxjs/operators';
import { skipWhile } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {
  private navigateToUpdatePage = false;
  private reloadAfterUpdate = false;
  readonly UPDATE_URL = '/update';

  constructor(
    private swUpdate: SwUpdate,
    @Inject('window') private window: Window,
    private router: Router
  ) {}

  canReloadAfterUpdate() {
    this.reloadAfterUpdate = true;
  }

  canNavigateToUpdatePage() {
    this.navigateToUpdatePage = true;
  }

  checkForPWAUpdates() {
    if (!this.swUpdate.isEnabled) {
      console.warn('ServiceWorker is not available');
      return;
    }

    // subscribe to VersionEvents to recognize when Update is available
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionDetectedEvent => evt.type === 'VERSION_DETECTED'),
        skipWhile(() => window.location.pathname === this.UPDATE_URL),
        take(1)
      )
      .subscribe(() => {
        console.warn('Es ist ein Update Verfügbar');
        if (this.navigateToUpdatePage) {
          this.navigateToUpdatePage = false;
          this.router.navigateByUrl(this.UPDATE_URL);
        }
        this.updatePWA();
      });

    this.updatePWA();
  }

  private async updatePWA() {
    console.warn('Es wird nach einem Update geprüft und ggf. geladen.');
    const readyToActivate = await this.swUpdate.checkForUpdate();
    if (readyToActivate) {
      console.warn('Das Update wurde heruntergeladen.');
      await this.swUpdate.activateUpdate();
      if (this.reloadAfterUpdate) {
        console.warn('Die neu Version wurde aktiviert und ein Reload der Seite wird ausgeführt.');
        this.reloadAfterUpdate = false;
        this.window.location.replace('/');
      } else {
        console.warn('Die neu Version wurde aktiviert. Ein Reload der Seite wird aber nicht ausgeführt.');
      }
    } else {
      console.warn('Sie sind auf dem aktuellsten Stand 🎉');
    }
  }
}
