import { Injectable } from '@angular/core';
import { KundenHttpService } from '../../../../../store/services/kunden.http.service';
import { Observable, of, throwError } from 'rxjs';
import { Auftrag } from '../../../../../store/models/auftrag.model';
import { Haushalt } from '../../../../../store/models/kunde.model';

@Injectable({
  providedIn: 'root'
})
export class KundenzuordnungService {
  constructor(private kundenHttpService: KundenHttpService) {}

  updateKundenNummerOnAuftrag(auftragId: string, kundenNummer: string): Observable<Auftrag> {
    if (!auftragId || !kundenNummer) {
      this.handleError(
        `Die Kundenzuordnung konnte nicht geändert werden, da nötige Parameter fehlen! AuftragID: ${auftragId}; KundenNummer: ${kundenNummer}`
      );
      return of(null);
    }
    const payload = {
      kundennummer: kundenNummer
    };
    return this.kundenHttpService.updateKundenNummerOnAuftrag(auftragId, payload);
  }

  handleError(expandMsg: string) {
    console.warn(expandMsg);
  }
}
