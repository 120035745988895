import { Component } from '@angular/core';
import { BasisFormularFeld } from '../basis-formular-feld';

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
  standalone: false
})
export class HeadlineComponent extends BasisFormularFeld {}
