<div *ngIf="currentAuftrag" class="splitscreen-container">
  <div class="splitscreen-grid-container">
    <div class="document-viewer-main-container">
      <app-dokumentenviewer
        [auftrag]="currentAuftrag"
        [deselectAllElements]="deselectElements"
        (handleDownloadError)="showDownloadError()"
        (loescheAktuellenAuftrag)="showDeleteConfirmationAlert()"
      >
      </app-dokumentenviewer>
    </div>
    <div class="formular-main-container">
      <app-auftrag-bearbeitung
        [auftrag]="currentAuftrag"
        (submittedForm)="formSubmitted()"
        (kundenZuordnungChanged)="updatedAuftrag($event)"
      >
      </app-auftrag-bearbeitung>
    </div>
  </div>
</div>

<dx-alert
  #splitscreenalert
  id="splitscreen-alert"
  titlestring="Fehler beim Laden des Auftrags"
  content="Der von Ihnen aufgerufene Auftrag existiert möglicherweise nicht mehr oder kann aufgrund fehlender Berechtigungen nicht angezeigt werden."
  sublabel="Das zugehörige Dokument steht gegebenenfalls noch in der DVAG-Cloud zur Verfügung."
  icon="warndreieck"
  type="error"
>
  <dx-button id="button-cancel" type="primary-s" slot="action" label="Okay" (click)="handleAlertOkClick()"></dx-button>
</dx-alert>

<dx-alert
  #auftragloeschenalert
  id="auftrag-loeschen-alert"
  actionsdirection="row"
  titlestring="Auftrag löschen"
  content="Möchten Sie den Auftrag wirklich löschen?"
  sublabel="Gelöschte Dokumente stehen Ihnen weiterhin im DVAG Online-System zur Verfügung."
  icon="warndreieck"
  type="error"
>
  <dx-button
    id="btn-delete"
    slot="action"
    label="Löschen"
    theme="destructive"
    type="primary-s"
    icon="loeschen"
    (click)="onDeleteAuftrag()"
  ></dx-button>
  <dx-button
    id="btn-cancel"
    type="text"
    slot="action"
    label="Abbrechen"
    (click)="auftragLoeschenAlert.visible = false"
  ></dx-button>
</dx-alert>

<dx-alert
  #warningunsaveddata
  actionsdirection="column"
  id="app-warning-unsaved-data-dialog"
  icon="warndreieck"
  type="error"
  titlestring="Sie haben bereits Daten erfasst"
  content="Sind Sie sich sicher, dass Sie diese verwerfen möchten?"
>
  <dx-button
    label="Bearbeitung fortsetzen"
    type="primary-s"
    id="app-warning-unsaved-data-dialog-bleiben"
    slot="action"
    (click)="discardChangesAlert$.next(false)"
  ></dx-button>
  <dx-button
    label="Daten verwerfen"
    theme="destructive"
    type="text"
    slot="action"
    (click)="discardChangesAlert$.next(true)"
    id="app-warning-unsaved-data-dialog-verwerfen"
  ></dx-button>
</dx-alert>
