import { BasisInputFormularFeld } from '../../../../formular/basis-input-formular-feld/basis-input-formular-feld'
import { Observable, ReplaySubject } from 'rxjs'

export class FormularFeldDictionaryService {

  private fieldNamesToBasisInputFormularFelder: Map<string, BasisInputFormularFeld> = new Map<string, BasisInputFormularFeld>()
  private completedValues = new ReplaySubject<{ fieldName: string, value: any }>(1)
  public keineVergleichsOptionen = 'keine Vergleichsdaten'

  resetBasisInputFormularFeld() {
    this.fieldNamesToBasisInputFormularFelder = new Map<string, BasisInputFormularFeld>()
  }

  addBasisInputFormularFeldToFieldName(name: string, instance: BasisInputFormularFeld) {
    this.fieldNamesToBasisInputFormularFelder.set(name, instance)
  }

  getBasisInputFormularFeld(name: string): BasisInputFormularFeld {
    return this.fieldNamesToBasisInputFormularFelder.get(name)
  }

  getListOfBasisInputFormularFeldNames() {
    return Array.from(this.fieldNamesToBasisInputFormularFelder.keys())
  }

  singleformularValueCompleted(fieldName: string, value: any) {
    this.completedValues.next({
      fieldName,
      value
    })
  }

  getCompletedValues(): Observable<{ fieldName: string, value: any }> {
    return this.completedValues.asObservable()
  }
}
