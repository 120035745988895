import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, ValidatorFn } from '@angular/forms';
import 'moment/locale/de';
import { FormularFeldValidatoren } from '../../validators/formular-feld-validatoren';
import moment from 'moment';
import { BasisInputFormularFeld } from '../basis-input-formular-feld';
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service';
import { AuftragBearbeitenService } from '../../../auftrag-bearbeitung/services/auftrag-bearbeiten.service';
import { AuftragKategorie } from '../../../../enums/auftrag.enums';
import { FeldValidator } from '../../../../store/models/formular-feld-config.model';
import { DxDateInput } from '@dvag/design-system-angular';

// set moment locale
moment.locale('de');

@Component({
  selector: 'app-datum',
  templateUrl: './datum.component.html',
  styleUrls: ['./datum.component.scss'],
  standalone: false
})
export class DatumComponent extends BasisInputFormularFeld implements OnInit {
  public showHinweisAblaufdatumFortgeschrieben = false;
  public showHinweisFortschreiben = false;
  private errorInDsComponent = false;
  private savedOriginalValue: any = null;
  public isVertragsablauf = true;

  @ViewChild('focusElement') focusElement: DxDateInput;
  @ViewChild('focusElement', { read: ElementRef }) elementRef: ElementRef;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    private auftragBearbeitungService: AuftragBearbeitenService
  ) {
    super(fb, focusedControlHandlerService);
  }

  ngOnInit() {
    this.isVertragsablauf = this.field.validators.some(
      validator => validator.typ === 'HINWEIS' && validator.name === 'IN_ZUKUNFT'
    );
  }

  /**
   * Diese ValidatorFunction dient dazu, Fehler, die vom DesignSystem wegen invaliden Datums geworfen werden, an das
   * FormControl weiter zu geben und es darüber auf valide oder invalide zu setzen.
   */
  private validDate(): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return this.errorInDsComponent ? { DATE_ERROR: { valid: false } } : null;
    };
  }

  /**
   * Fängt die Fehler vom DesignSystem ab und speichert das Vorhandensein eines Fehlers in einer Variable,
   * die vom Validator validDate() verwendet wird.
   * @param error
   */
  // TODO FRAN
  triggerError(error: CustomEvent<any>) {
    if (error.detail.type === 'NO_ERROR') {
      this.errorInDsComponent = null;
      this.errormessage = '';
    } else {
      this.errorInDsComponent = true;
      this.errormessage = error.detail.message;
    }
    // Die Validierung muss hier manuell getriggert werden, da im Fall eines Fehlers der Wert vom DesignSystem gelöscht wird, aber nicht der angezeigte Wert.
    // Dies führt zum ValueChange und somit der Validierung. Löscht man aber dann den angezeigten Wert aus dem Feld, wird der Fehler zurückgesetzt,
    // aber der Wert ändert sich nicht mehr, wodurch kein ValueChange inkl Validierung getriggert wird.
    this.formControl.updateValueAndValidity();
  }

  override componentValidators(): ValidatorFn[] {
    return [this.validDate()];
  }

  override prepareOcrTextForFormControl(value: string, oldValue: any): any {
    // Hochkommatas und Klammern in jeglicher Form entfernen
    // Leerzeichen dürfen nur m Anfang und Ende entfernt werden, wegen dem erwarteten Frormat 'MMMM YY => April 23'
    value = value.replace(/[,„“´`'"‘()\[\]{}]/g, '').trim();
    const retValue = this.mapTextToDateFormat(value);
    return retValue !== 'Invalid date' ? retValue : null;
  }

  private mapTextToDateFormat(dateString: string): string {
    const formats = [
      'DD/MMMM/YYYY',
      'DD/MM/YY',
      'DD/MM/YYYY',
      'YYYY-MM-DD',
      'D-M-YY',
      'D-M-YYYY',
      'DD-MM-YY',
      'DD-MM-YYYY',
      'D.M.YY',
      'D.M.YYYY',
      'DD.MM.YY',
      'DD.MM.YYYY',
      'DD.MMMM.YYYY',
      'MM/YY',
      'MM/YYYY',
      'MMMM YYYY',
      'MMMM',
      'YYYY'
    ];
    for (const format of formats) {
      const parsedDate = moment(dateString, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format('YYYY-MM-DD');
      }
    }
    return 'Invalid date';
  }

  handleEvent(event) {
    if (event?.type === 'valueChange' && !this.isVertragsablauf) {
      this.validate(event.detail);
      return;
    }
    if (event?.type === 'valueComplete' && this.isVertragsablauf) {
      this.validate(event.detail);
      return;
    }
  }

  override validate(newValue: any): void {
    if (!newValue) {
      return;
    }

    if (
      this.field.validators?.find(
        validator => validator.name.toUpperCase() === 'IN_ZUKUNFT' && validator.typ.toUpperCase() === 'HINWEIS'
      )
    ) {
      this.handleDateHinweis(newValue);
      return;
    }

    if (this.formControl.invalid) {
      // Wenn bereits eine Errormessage gesetzt ist, dann soll keine weitere mehr angezeigt werden
      if (this.errormessage) {
        return;
      }
      this.setErrorMessage();
    } else {
      this.errormessage = '';
    }
  }
  private handleDateHinweis(newValue: any) {
    const validatorRespFn = FormularFeldValidatoren.inZukunft();
    const isDateInPast = validatorRespFn(this.formControl) !== null;

    /*  Wird das Feld zum ersten Mal befüllt und das Datum liegt in der Vergangenheit liegt,
        merke dir den originalen Wert, setze das Datum in die Zukunft und zeige einen Hinweis an */
    if (
      !this.savedOriginalValue &&
      isDateInPast &&
      this.auftragBearbeitungService.auftrag.kategorie === AuftragKategorie.OFFEN
    ) {
      this.savedOriginalValue = newValue;
      this.setDateToFuture();
      this.showHinweisAblaufdatumFortgeschrieben = true;
      this.showHinweisFortschreiben = false;
      return;
    }

    if (isDateInPast) {
      this.showHinweisAblaufdatumFortgeschrieben = false;
      this.showHinweisFortschreiben = true;
    } else {
      this.showHinweisFortschreiben = false;
    }
  }
  private setErrorMessage() {
    this.field.validators
      .filter(it => it.typ === 'FEHLER')
      .forEach((validator: FeldValidator) => {
        if (validator.name.toLowerCase() === 'in_zukunft') {
          this.errormessage = validator.fehlerText;
        }
        if (validator.name.toLowerCase() === 'bis_heute') {
          this.errormessage = validator.fehlerText;
        }
      });
  }
  /* Auf das Verganenheitsdatum zurücksetzen */
  patchValueWithOriginalValue() {
    this.patchValue(this.savedOriginalValue);
  }

  setDateToFuture(): void {
    const formularDatum = moment(this.formControl.value, 'YYYY-MM-DD');
    const today = moment().startOf('day');
    const formularDatumThisYear = formularDatum.year(today.year());

    if (formularDatumThisYear.isSameOrBefore(today)) {
      const heutigesJahrplusEins = moment().year() + 1;
      this.patchValue(formularDatum.year(heutigesJahrplusEins).format('YYYY-MM-DD'));
    } else {
      this.patchValue(formularDatum.format('YYYY-MM-DD'));
    }
  }

  override getReadonlyDisplayValue(): string {
    if (this.initialValue && moment(this.initialValue).isValid()) {
      return moment(this.initialValue).format('DD.MM.YYYY');
    } else {
      return this.initialValue;
    }
  }

  override async focus(): Promise<void> {
    if (this.focusElement?.focusControl) {
      try {
        await this.focusElement?.focusControl();
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`);
      }
    } else {
      console.warn(
        `An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`
      );
    }
  }
}
