import { Component } from '@angular/core';
import { DesignSystemModule } from '@dvag/design-system-angular';

@Component({
  selector: 'app-accessdeclined',
  standalone: true,
  imports: [DesignSystemModule],
  templateUrl: './accessdeclined.component.html'
})
export class AccessdeclinedComponent {
  goToMeineDVAG() {
    window.open('https://meine.dvag', '_self', 'noopener');
  }
}
