<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <div formGroupName="{{ field.name }}" class="werteinheit">
    <dx-number-input
      formControlName="wert"
      #focusElement
      id="{{ field.name }}_wert"
      label="{{ field.label }}"
      thousandseparator="{{ field.tausenderTrennzeichen }}"
      [profile]="currentProfile"
      [precision]="nachkommastellen"
      (focus)="storeNameOfFocusedElement(field.name)"
      (valueChange)="validate($event.detail)"
      [errormessage]="errormessage"
      [kisynced]="field.kiSync"
      [attr.templatevalue]="aiPredicted ? initialValue.wert : null"
      [templateindicatortype]="'ocr'"
    >
      @if (feldInfo) {
        <app-input-field-info slot="info" [feldName]="field.name" [feldInfo]="feldInfo"></app-input-field-info>
      }
    </dx-number-input>

    <dx-single-select
      formControlName="typ"
      #werteinheittyp
      id="{{ field.name }}_typ"
      (focus)="storeNameOfFocusedElement(field.name + '.typ')"
      label=" "
    >
      <dx-media-query-value property="columns" mq1="2"></dx-media-query-value>
      <dx-single-select-item
        *ngFor="let option of wertEinheitKonfigurations"
        id="{{ field.name }}-{{ option.typ }}"
        value="{{ option.typ }}"
        label="{{ option.label }}"
      >
      </dx-single-select-item>
    </dx-single-select>
  </div>
</div>
<app-readonly-template
  *ngIf="isReadonly"
  [field]="field"
  [displayValue]="getReadonlyDisplayValue()"
></app-readonly-template>
