import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Stage } from '../stage/stage';
import { AuftragDatenbestand, AuftragKategorie } from '../../../../../src/app/enums/auftrag.enums';
import { Vertrag } from '../../../../../src/app/store/models/vertrag.model';

export interface AuftragLight {
  auftragId: string;
  formularId: string;
  kategorie: AuftragKategorie;
  datenbestand: AuftragDatenbestand;
  ursprung?: string;
  vertraege?: Vertrag[];
}

@Injectable({
  providedIn: 'root'
})
export class AuftragLightHttpService {
  constructor(
    private httpClient: HttpClient,
    private stage: Stage
  ) {}

  fetchAuftrag(auftragId: string): Observable<AuftragLight> {
    return this.httpClient.get<AuftragLight>(`${this.stage.getBackendUrl()}/auftraege/${auftragId}`);
  }
}
