import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * This directive is used to add the nativeElement of the form control to the control instance.
 * This allows the form control to have direct access to its corresponding HTML element,
 * which can be useful for custom behavior or manipulation like setting focus on the input field.
 */

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName]',
  standalone: false
})
export class BasisFormControlDirective implements OnInit {
  constructor(
    private controlDir: NgControl,
    private host: ElementRef<HTMLFormElement>
  ) {}

  ngOnInit() {
    (this.controlDir.control as any).nativeElement = this.host.nativeElement;
  }
}
