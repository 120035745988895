import { Injectable, RendererFactory2 } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KeycloakApiService } from '../store/services/keycloak.api.service';
import { Router } from '@angular/router';

@Injectable()
export class ViernulleinsInterceptor implements HttpInterceptor {
  constructor(
    private rendererFactory: RendererFactory2,
    private keycloakApiService: KeycloakApiService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        const userTyp = this.keycloakApiService.getTokenParsedItem('user_typ');
        if (error.status === 401 && userTyp !== '2') {
          this.renderAlert();
          return of(null);
        }
        if (error.status === 401 && userTyp == '2') {
          this.navigateToAccessDeclined();
          return of(null);
        }

        return throwError(() => error);
      })
    );
  }

  private renderAlert() {
    const renderer = this.rendererFactory.createRenderer(null, null);

    const alert = renderer.createElement('dx-alert') as HTMLDxAlertElement;
    alert.titlestring = 'Rolle gewechselt';
    alert.icon = 'warndreieck';

    // ACHTUNG: Der Text muss in dieser Formatierung bleiben, damit der korrekt angezeigt werden.
    alert.content = `Da Sie in einem anderen Tab die Rolle gewechselt haben, wurde die Rolle auch hier angepasst. Ihr Vorgang wurde abgebrochen und Sie werden automatisch zur Startseite weitergeleitet.

Falls Sie parallel in beiden Rollen arbeiten möchten, öffnen Sie diese bitte jeweils in verschiedenen Webbrowsern.`;
    alert.id = 'alert-context-change';
    alert.visible = true;

    const alertButton: HTMLDxButtonElement = renderer.createElement('dx-button') as HTMLDxButtonElement;
    alertButton.label = 'Okay';
    alertButton.slot = 'action';
    alertButton.type = 'text';
    alertButton.id = 'alert-btn-context-change';
    alertButton.addEventListener('click', () => {
      this.keycloakApiService.login(window.location.origin);
    });

    renderer.appendChild(alert, alertButton);
    renderer.appendChild(document.body, alert);
  }

  private navigateToAccessDeclined() {
    this.router.navigate(['/accessdeclined']);
  }
}
