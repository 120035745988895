import { FormBuilder, FormGroup } from '@angular/forms';
import { BasisInputFormularFeld } from '../basis-input-formular-feld/basis-input-formular-feld';
import { DecimalPipe } from '@angular/common';
import { AktivesEingabefeldHandlerService } from '../basis-input-formular-feld/services/aktives-eingabefeld-handler.service';
import { FormularFeldConfig, NumberKonfiguration } from '../../../store/models/formular-feld-config.model';
import { FormularFeldDictionaryService } from '../../auftrag-bearbeitung/components/vertrags-formular/services/formular-feld-dictionary.service';

export class BasisNumberFormularFeld extends BasisInputFormularFeld {
  einheit = '';
  nachkommastellen = 2;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    protected decimalPipe: DecimalPipe
  ) {
    super(fb, focusedControlHandlerService);
  }

  override initBasisFormularFeld(
    group: FormGroup,
    field: FormularFeldConfig,
    formularId: string,
    isReadonly: boolean,
    auftragId?: string,
    formularFeldDictionaryService?: FormularFeldDictionaryService
  ): void {
    super.initBasisFormularFeld(
      group,
      field,
      formularId,
      isReadonly,
      auftragId ?? null,
      formularFeldDictionaryService ?? null
    );
    const numberConfig = this.field?.konfiguration as NumberKonfiguration;
    this.nachkommastellen = numberConfig ? numberConfig.anzahlNachkommastellen : this.nachkommastellen;
  }

  override validate(value: number): void {
    this.errormessage = '';

    for (const validator of this.field.validators) {
      switch (validator.name.toLowerCase()) {
        case 'maximum':
          this.checkMaximum(value, validator.params, validator.fehlerText);
          break;
        case 'minimum':
          this.checkMinimum(value, validator.params, validator.fehlerText);
          break;
        case 'entspricht_regex':
          this.checkRegex(value, validator.params, validator.fehlerText);
          break;
      }
    }
  }
  nonBreakingSpaceVorEinheitHinzufuegen(text: string): string {
    const einheitRegex = new RegExp(`\\s${this.einheit}`, 'gi');
    return this.einheit ? text.replace(einheitRegex, `\u00A0${this.einheit}`) : text;
  }
  private formatInsertWertMitEinheit(params: string): string {
    const isNumeric = /^\d+$/.test(params);
    if (isNumeric) {
      return this.einheit ? `${params} ${this.einheit}` : params;
    } else {
      return this.einheit ? `${this.decimalPipe.transform(params, '1.2-2')} ${this.einheit}` : params;
    }
  }

  private checkMaximum(value: number, max: number, fehlerText: string): void {
    if (value > max) {
      this.errormessage = this.nonBreakingSpaceVorEinheitHinzufuegen(fehlerText);
    }
  }

  private checkMinimum(value: number, min: number, fehlerText: string): void {
    if (value === null || value === undefined) {
      return;
    }
    if (value < min) {
      this.errormessage = this.nonBreakingSpaceVorEinheitHinzufuegen(fehlerText);
    }
  }

  private checkRegex(value: number, regexStr: string, fehlerText: string): void {
    const regex = RegExp(regexStr);
    if (!value) {
      this.errormessage = '';
    } else if (!regex.test(value?.toString())) {
      this.errormessage = fehlerText;
    }
  }

  override readValue(): any {
    const value = super.readValue();
    if (isNaN(value)) {
      return null;
    } else {
      return value;
    }
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: any): any {
    if (!ocrText) {
      return oldValue;
    }

    const tempValue = this.castOcrStringToNumberFormat(ocrText);

    if (!Number(tempValue)) {
      return oldValue;
    }

    let newValue;
    // make text concatenable depending on the oldValue
    if (!oldValue) {
      return Number(tempValue);
    }

    if (oldValue % 1 === 0) {
      // ganzzahlig
      return Number(oldValue + tempValue);
    } else {
      // gleitkomma
      return Number(oldValue + tempValue.replace(/\./g, ''));
    }
  }

  override getReadonlyDisplayValue(): string {
    return this.initialValue != null ? `${this.decimalPipe.transform(this.initialValue, '1.2-2')} ${this.einheit}` : '';
  }
}
