<div id="{{ field.name }}_label" class="link-container">
  <dx-text id="link-hinweis-text" type="it" [innerHTML]="linkLabel"></dx-text>
  <br />
  <dx-button
    type="text"
    id="{{ field.name }}_text-button"
    (click)="navigate(linkHref)"
    *ngIf="linkHref"
    class="leistungsradar-text-btn"
    [label]="linkText"
    icon="link-extern"
    iconposition="right"
  >
  </dx-button>
</div>
