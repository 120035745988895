<div id="geraet-wechsel-wechsel-container" class="switch-device-content">
  <dx-text type="ps" id="qrcode-text-top">
    Scannen Sie bitte diesen QR-Code mit Ihrem mobilen Endgerät, um den Vorgang zu beginnen.
  </dx-text>
  <div class="qr-code-container">
    <dx-loading-indicator id="qrcode-spinner" *ngIf="!qrCode" size="medium" label=""></dx-loading-indicator>
    <img
      id="qrcode-image"
      *ngIf="qrCode"
      [src]="qrCode"
      [ngClass]="{ 'grau-filter': isQrCodeAbgelaufen }"
      alt="QR-Code"
    />
    <div *ngIf="qrCode && isQrCodeAbgelaufen" id="qr-code-blocker">
      <dx-icon size="72" icon="warndreieck" color="error"></dx-icon>
    </div>
  </div>
  <div class="qr-code-gueltigkeit">
    <dx-text class="status" type="ps" id="qrcode-gueltigkeit">{{ ablaufLabel }}</dx-text>
  </div>
</div>

<dx-alert
  #geraeteWechselAbbrechen
  id="geraetewechsel-abbrechen-alert"
  icon="information"
  titlestring="Hochladen abbrechen?"
  actionsdirection="column"
  content="Damit wird der QR-Code ungültig und Sie können keine weiteren Dokumente hochladen."
>
  <dx-button
    id="geraetewechsel-abbrechen-bleiben"
    type="secondary-s"
    slot="action"
    label="Auf Seite bleiben"
    (click)="geraeteWechselAbbrechen.visible = false"
  ></dx-button>
  <dx-button
    id="geraetewechsel-abbrechen-abbrechen"
    type="secondary-s"
    slot="action"
    label="Hochladen abbrechen"
    (click)="cancelUpload()"
  ></dx-button>
</dx-alert>

<dx-alert
  #geraeteWechselZurueckAlert
  id="geraetewechsel-zurueck-alert"
  icon="information"
  titlestring="Haben Sie bereits Dokumente hinzugefügt?"
  actionsdirection="column"
  content="Falls Sie über diesen QR-Code bereits Dokumente mit einem anderen Gerät fotografiert und hinzugefügt haben, kann ein Wechsel zu Fehlern führen.

Bitte schließen Sie in diesem Fall zuerst das Fotografieren mit dem anderen Gerät ab und starten Sie einen neuen Hochladevorgang."
>
  <dx-button
    id="geraetewechsel-zurueck-zurueck"
    type="secondary-s"
    slot="action"
    label="Nein, zurück zur Auswahl"
    (click)="goBack()"
  ></dx-button>
  <dx-button
    id="geraetewechsel-zurueck-fertig"
    type="secondary-s"
    slot="action"
    label="Ja, Fotografieren abschließen"
    (click)="changeDevice()"
  ></dx-button>
</dx-alert>
