import { BasisInputFormularFeld } from '../../../../formular/basis-input-formular-feld/basis-input-formular-feld';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * This service is used to store the fields for a single form as a map of Key-Value-Pairs.
 * The form field name is used as key and the value is the associated BasisInputFormularFeld instance.
 * It allows working on the form fields, such as retrieving the current value or setting the initial value.
 *
 * The service also provides an Observable that emits the completed form field values.
 * This is used by input fields (starting with _dyn_) which have a dependency to a value of another field.
 */
export class FormularFeldDictionaryService {
  private fieldNamesToBasisInputFormularFelder: Map<string, BasisInputFormularFeld> = new Map<
    string,
    BasisInputFormularFeld
  >();
  private completedValues = new ReplaySubject<{ fieldName: string; value: any }>(1);
  public keineVergleichsOptionen = 'keine Vergleichsdaten';

  resetBasisInputFormularFeld() {
    this.fieldNamesToBasisInputFormularFelder = new Map<string, BasisInputFormularFeld>();
  }

  addBasisInputFormularFeldToFieldName(name: string, instance: BasisInputFormularFeld) {
    this.fieldNamesToBasisInputFormularFelder.set(name, instance);
  }

  getBasisInputFormularFeld(name: string): BasisInputFormularFeld {
    return this.fieldNamesToBasisInputFormularFelder.get(name);
  }

  getListOfBasisInputFormularFeldNames() {
    return Array.from(this.fieldNamesToBasisInputFormularFelder.keys());
  }

  singleformularValueCompleted(fieldName: string, value: any) {
    this.completedValues.next({
      fieldName,
      value
    });
  }

  getCompletedValues(): Observable<{ fieldName: string; value: any }> {
    return this.completedValues.asObservable();
  }
}
