import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BasisInputFormularFeld } from '../basis-input-formular-feld';
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service';
import { Components } from '@dvag/design-system';
import DxSingleSelect = Components.DxSingleSelect;

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  standalone: false
})
export class SingleSelectComponent extends BasisInputFormularFeld {
  @ViewChild('focusElement') focusElement: DxSingleSelect;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(fb, focusedControlHandlerService);
  }

  override setInitialValue(value: any): void {
    this.aiPredicted = value?.aiPredicted ?? false;
    this.initialValue = value?.wert ?? null;
    this.changeDetectorRef.detectChanges();
    this.patchValue(this.initialValue);
  }
}
