<dx-text id="zugeordneter-kunde-label" type="its">{{ label }}</dx-text>
<div
  #customselect
  id="mitgliederauswahl-select"
  class="zugeordneter-kunde-main-container"
  tabindex="0"
  [ngClass]="{ 'zugeordneter-kunde-main-container-aktive': isHaushaltSelectOpened }"
  (click)="toggleOptions()"
  (keydown.enter)="toggleOptions()"
  (keydown.space)="toggleOptions()"
  (blur)="handleBlur($event)"
>
  <dx-text type="ps" id="zugeordneter-kunde-anzeige">{{ selektierterKunde?.name }}</dx-text>
  <dx-icon icon="{{ isHaushaltSelectOpened ? 'Chevron-hoch' : 'Chevron-unten' }}" size="24" color="default" />
</div>
<div *ngIf="isHaushaltSelectOpened" class="auswahlliste" id="mitgliederauswahl-select-option_container">
  <div
    *ngFor="let mitglied of haushaltsmitglieder"
    class="auswahl-option"
    id="mitgliederauswahl-select-option_mitglied_{{ mitglied.kundenNummer }}"
    [ngClass]="{ 'selected-haushaltsmitglied': mitglied.kundenNummer === selektierterKunde.kundenNummer }"
    (click)="kundenzuordnungWechseln(mitglied.kundenNummer)"
    (keydown.enter)="kundenzuordnungWechseln(mitglied.kundenNummer)"
    (keydown.space)="kundenzuordnungWechseln(mitglied.kundenNummer)"
    tabindex="0"
  >
    <dx-text id="mitgliederauswahl-select-option_mitglied_{{ mitglied.kundenNummer }}_name" type="ps">{{
      mitglied.name
    }}</dx-text>
    <dx-text id="mitgliederauswahl-select-option_mitglied_{{ mitglied.kundenNummer }}_bd" type="it"
      >Geboren am {{ mitglied.geburtsDatum | date }}</dx-text
    >
    <dx-text
      id="mitgliederauswahl-select-option_mitglied_{{ mitglied.kundenNummer }}_address"
      *ngIf="mitglied.adresse"
      type="its"
      >{{ mitglied.adresse }}</dx-text
    >
  </div>
</div>
