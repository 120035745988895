import { Payload } from './payload';
import { GoyaMeta } from '../../../../../src/app/store/models/goya-meta';
import { BackendConfigObject } from './config-options.model';

export enum UserContext {
  VB = 'VB',
  ENDKUNDE = 'ENDKUNDE'
}

/**
 * Der Type FinalizedCloseReason ist beim Schliessen der Komponente im Frontend zu verwenden. Er gibt den Grund an,
 * der zusätzlich im Falle des Status 'FINALIZED' gesendet wird und definiert, warum der Dialog geschlossen wurde ohne Upload.
 * - DEVICE_CHANGED: Wird gesetzt für den Fall, dass das auf den neuen Button Gerät gewechselt geklickt wird.
 * - DEVICE_CHANGED_CANCELED: Wird gesetzt für den Fall, dass der X-Button bei der QR-Code-Anzeige geklickt wird.
 * - CANCELED: Wenn der Upload über die X-Buttons geschlossen wird. Dies gilt für den UploadToolDialog und auch für die Foto-Komponente.
 * - ERROR: Wird gesendet, wenn während des Uploads ein Fehler aufgetreten ist und der Upload dadurch vom Nutzer abgebrochen wurden. Es wird zusätzlich noch eine "errormessage" gesetzt
 */
export type FinalizedCloseReason = 'DEVICE_CHANGED' | 'DEVICE_CHANGED_CANCELED' | 'CANCELED' | 'ERROR';
/**
 * Der Type FinalizedReason erweitert den Typ FinalizedCloseReason um weitere Gründe, die nur vom Uploader geworfen werden können.
 * - SUCCESSFUL: Wird gesendet, sobald alle Dateien hochgeladen und ggf. verarbeitet wurden
 */
export type FinalizedReason = FinalizedCloseReason | 'SUCCESSFUL';

export interface UploadJob {
  id: string;
  bucketId?: string;
  mergeToSinglePdf: boolean;
  backendConfigObject: BackendConfigObject;
  payloads: Payload[];
}

export interface BaseEvent {
  /**
   * ID to group events for the same upload.
   */
  id: string;
  bucketId?: string;
  kundennummer?: string;

  /**
   * Upload progress in percent ranging from 0.0 to 1.0.
   */
  uploadProgress?: {
    files: number[];
    total: number;
  };
  /**
   * ConfigObject mit dem das Bucket erzeugt wurde
   */
  backendConfigObject?: BackendConfigObject;
}

export interface UploaderEvent extends BaseEvent {
  status: 'UPLOADING' | 'PDF_CREATED' | 'BACKGROUND_PROCESSING' | 'FINALIZED';
  reason?: FinalizedReason;

  /**
   * GoyaMeta.
   */
  goyaMeta?: GoyaMeta;

  /**
   * Undocumented errors. Used for debugging, not to display.
   */
  error?: any;

  /**
   * Extracted document data.
   */
  auslese?: any;

  /**
   * AuftragsId can be used to navigate to the datacollect splitscreen
   */
  auftraege?: { goyaMeta: GoyaMeta; auftragsId: string }[];
}

/**
 * Fehler Object beim Upload.
 * - message: Eine Datenturbo spezifische Meldung
 * - error: Der aufgetretene Fehler
 */
export interface JobError {
  message: string;
  error: Error;
}

/**
 * Dieser Event wird geworfen, wenn beim Upload ein Fehler auftritt. Es wird intern in Datetnturbo verwendet und wird nicht an den Konsumenten gegeben.
 * In der Uploader-Component wird auf diesen Event gehört, um ein Alert für den Retry zu triggern.
 * - job: Ist der gesamte Job inkl Payload, um den Upload erneut zu starten.
 * - message: Fehler-Meldung von Datenturbo, wo der Fehler aufgeterten ist. <== wird aktuell nicht verarbeitet
 * - error: Enthält den aufgetretenen Fehler <== wird beim Abbruch des fehlgeschlagenen Uploads mit gegeben
 */
export interface ErrorEvent {
  job: UploadJob;
  message: string;
  error: any;
}
