<div id="vers_pers_readonly_container">
  <dx-text id="vers_pers_main_headline_readonly" type="h5" style="margin-bottom: 8px">{{ unterformularName }}</dx-text>
  @if (!readonlyAngaben && !finishedLoading) {
    <dx-loading-indicator
      id="vers_pers_loading_indicator_readonly"
      size="small"
      theme="light"
      label=""
      style="align-self: flex-start"
    >
    </dx-loading-indicator>
  } @else if (!readonlyAngaben && finishedLoading) {
    <dx-text id="vers_pers_readonly_novalue" type="ps">--</dx-text>
  } @else {
    @for (values of readonlyAngaben; track values.kundennummer; let index = $index) {
      <div style="display: flex; flex-direction: column">
        <div
          id="vers_pers_action_container_{{ index }}"
          class="action-headline"
          (click)="toogleReadonly(index)"
          (keydown.enter)="toogleReadonly(index)"
          (keydown.space)="toogleReadonly(index)"
          tabindex="0"
        >
          <dx-icon
            #readonlychevrons
            id="vers_pers_action_container_icon_{{ index }}"
            icon="Chevron-unten"
            size="16"
            color="default"
            style="padding-top: 2px"
          ></dx-icon>
          <dx-text id="vers_pers_action_container_name_{{ index }}" type="ps">{{ values.kundenname }}</dx-text>
        </div>
        <div
          #readonlyAngaben
          id="vers_pers_value_container_{{ index }}"
          class="toggleable-values-container"
          style="display: none"
        >
          <!--"display: none" darf nicht in das scss, da sonst der erste klick auf den Toggle nicht funktioniert -->
          @for (field of unterformularFeldConfigs; track field.name) {
            @if (field.type !== 'HIDDEN') {
              <div id="vers_pers_value_container_{{ index }}_{{ field.name }}" class="formcontrol-grid-readonly-item">
                <dx-text type="it" id="vers_pers_value_container_{{ index }}_{{ field.name }}_label"
                  >{{ field.label }}
                </dx-text>
                <dx-text type="ps" id="vers_pers_value_container_{{ index }}_{{ field.name }}_value"
                  >{{ values[field.name] ?? '--' }}
                </dx-text>
              </div>
            }
          }
        </div>
      </div>
    }
  }
</div>
