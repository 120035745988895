import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BasisInputFormularFeld } from '../basis-input-formular-feld';
import { IbanFormatterPipe } from 'angular-iban';
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service';
import { FeldValidator } from '../../../../store/models/formular-feld-config.model';
import { DxTextInput } from '@dvag/design-system-angular';

@Component({
  selector: 'app-iban',
  templateUrl: './iban.component.html',
  providers: [IbanFormatterPipe],
  standalone: false
})
export class IbanComponent extends BasisInputFormularFeld {
  @ViewChild('focusElement') focusElement: DxTextInput;
  @ViewChild('focusElement', { read: ElementRef }) elementRef: ElementRef;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    private ibanPipe: IbanFormatterPipe
  ) {
    super(fb, focusedControlHandlerService);
  }

  override validate(value: any) {
    if (this.formControl.invalid) {
      this.field.validators
        .filter(it => it.typ === 'FEHLER')
        .forEach((validator: FeldValidator) => {
          // Wenn bereits eine Errormessage gesetzt ist, dann soll keine weitere mehr angezeigt werden
          if (this.errormessage) {
            return;
          }
          if (validator.name.toLowerCase() === 'iban') {
            this.errormessage = validator.fehlerText;
          }
        });
    } else {
      this.errormessage = '';
    }
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: any): any {
    const tempValue = ocrText.replace(/ /g, '');
    if (oldValue) {
      return oldValue + tempValue;
    } else {
      return tempValue;
    }
  }

  override setInitialValue(value: any) {
    const cleanedValue = value?.wert?.toString().replace(/ /g, '') ?? null;
    this.initialValue = cleanedValue;
    this.aiPredicted = value?.aiPredicted ?? false;
    this.patchValue(cleanedValue);
  }

  override getReadonlyDisplayValue(): string {
    return this.initialValue ? this.ibanPipe.transform(this.initialValue) : '';
  }

  override async focus(): Promise<void> {
    if (this.focusElement?.focusControl) {
      try {
        await this.focusElement?.focusControl();
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`);
      }
    } else {
      console.warn(
        `An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`
      );
    }
  }
}
