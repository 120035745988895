import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LeistungsradarHttpService } from '../../../../../store/services/leistungsradar.http.service';
import { Observable, throwError } from 'rxjs';
import { LeistungsradarResult } from '../../../../../../../projects/leistungsradar/src/dt/model/leistungsradarresult.model';
import { LeistungsradarVergleichsoptionen } from '../../../../../store/models/leistungsradar-vergleichsoptionen.model';

@Injectable({
  providedIn: 'root'
})
export class LeistungsradarService {
  constructor(
    private router: Router,
    private leistungsradarHttpService: LeistungsradarHttpService
  ) {}

  generateLeistungsVergleichUrl(vergleichId: string): string {
    if (!vergleichId) {
      return null;
    }
    return this.router.serializeUrl(this.router.createUrlTree(['/dokumentsplitscreen/leistungsradar/' + vergleichId]));
  }

  fetchLeistungsVergleich(value: string): Observable<LeistungsradarResult> {
    if (!value) {
      return throwError(() => new Error('Der Wert zur Abfrage des Leistungsradars darf nicht leer sein'));
    }
    return this.leistungsradarHttpService.fetchLeistungsVergleich(value);
  }

  generateLeistungsVergleich(vergleichsoptionen: LeistungsradarVergleichsoptionen): Observable<LeistungsradarResult> {
    if (!vergleichsoptionen) {
      return throwError(() => new Error('Zur Generierung eines Leistungsradars werden Vergleichsoptionen benötigt.'));
    }
    return this.leistungsradarHttpService.generateLeistungsVergleich(vergleichsoptionen);
  }
}
