<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup" style="padding-top: 8px">
  <dx-text-area
    #focusElement
    [formControlName]="field.name"
    id="{{ field.name }}_wert"
    label="{{ field.label }}"
    lines="4"
    (focus)="storeNameOfFocusedElement(field.name)"
  >
    @if (feldInfo) {
      <app-input-field-info slot="info" [feldName]="field.name" [feldInfo]="feldInfo"></app-input-field-info>
    }
  </dx-text-area>
</div>
<app-readonly-template
  *ngIf="isReadonly"
  [field]="field"
  [displayValue]="getReadonlyDisplayValue()"
></app-readonly-template>
