<div *ngIf="visible">
  <lib-fotokollektor
    class="fullscreen"
    [forceCropping]="this.mergedConfigOptions()?.forceCropping ?? false"
    [allowOnlyOneImage]="allowOnlyOneImage()"
    (processAborted)="cancelUpload()"
    (errorOccurred)="cancelUpload()"
    (imagesCollected)="sendImages($event)"
  >
  </lib-fotokollektor>
</div>
<dx-alert
  #configErrorAlert
  id="config-error-alert"
  type="error"
  icon="warndreieck"
  titlestring="Achtung"
  content="Es ist ein unerwarteter Fehler aufgetreten. Schließen Sie den Dialog und probieren Sie es in einigen Minuten erneut."
>
  <dx-button
    id="config-error-ok"
    type="primary-s"
    slot="action"
    label="Okay"
    (click)="closeAndResetModal()"
  ></dx-button>
</dx-alert>
