import { Component, Input, OnChanges } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dt-indicator-bar',
  templateUrl: './indicator-bar.component.html',
  styleUrls: ['./indicator-bar.component.scss'],
  standalone: false
})
export class IndicatorBarComponent implements OnChanges {
  @Input()
  barWidth: string;

  @Input()
  company: string;

  color: string;
  isCompany: string;

  ngOnChanges(): void {
    if (!this.barWidth) {
      this.barWidth = '0';
    }
    if (this.company?.toUpperCase() === 'ADVOCARD') {
      this.isCompany = 'advocard';
    } else if (this.company?.toUpperCase() === 'BADENIA') {
      this.isCompany = 'badenia';
    }
  }
}
