import * as i0 from '@angular/core';
import { Directive, Pipe, NgModule } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import * as IBAN from 'iban';
class ValidatorService {
  static validateIban(c) {
    if (c.value && !IBAN.isValid(c.value)) {
      return {
        iban: {
          value: c.value
        }
      };
    }
    return null;
  }
}
class IbanValidatorDirective {
  validate(c) {
    return ValidatorService.validateIban(c);
  }
  static {
    this.ɵfac = function IbanValidatorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IbanValidatorDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: IbanValidatorDirective,
      selectors: [["", "ibanValidator", ""]],
      standalone: false,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: IbanValidatorDirective,
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IbanValidatorDirective, [{
    type: Directive,
    args: [{
      selector: '[ibanValidator]',
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: IbanValidatorDirective,
        multi: true
      }],
      standalone: false
    }]
  }], null, null);
})();
class IbanFormatterPipe {
  transform(value, args) {
    if (IBAN.isValid(value)) {
      return IBAN.printFormat(value, ' ');
    }
    return value;
  }
  static {
    this.ɵfac = function IbanFormatterPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IbanFormatterPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "ibanFormatter",
      type: IbanFormatterPipe,
      pure: true,
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IbanFormatterPipe, [{
    type: Pipe,
    args: [{
      name: 'ibanFormatter',
      standalone: false
    }]
  }], null, null);
})();
class AngularIbanModule {
  static {
    this.ɵfac = function AngularIbanModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AngularIbanModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AngularIbanModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularIbanModule, [{
    type: NgModule,
    args: [{
      declarations: [IbanValidatorDirective, IbanFormatterPipe],
      imports: [],
      exports: [IbanValidatorDirective, IbanFormatterPipe]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-iban
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularIbanModule, IbanFormatterPipe, IbanValidatorDirective, ValidatorService };
