import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { PwaUpdateService } from '../services/pwa-update.service';

export const canActivatePwaUpdateGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const pwaUpdateService = inject(PwaUpdateService);
  pwaUpdateService.canNavigateToUpdatePage();
  pwaUpdateService.checkForPWAUpdates();
  return true;
};
