<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <dx-number-input
    #focusElement
    [formControlName]="field.name"
    id="{{ field.name }}_wert"
    label="{{ field.label }}"
    thousandseparator="{{ field.tausenderTrennzeichen }}"
    precision="2"
    unit="%"
    required="{{ field.required }}"
    (focus)="storeNameOfFocusedElement(field.name)"
    (valueComplete)="validate($event.detail)"
    (valueChange)="errormessage = ''"
    [errormessage]="errormessage"
    [kisynced]="field.kiSync"
    [attr.templatevalue]="aiPredicted ? initialValue : null"
    [templateindicatortype]="'ocr'"
  >
    @if (feldInfo) {
      <app-input-field-info slot="info" [feldName]="field.name" [feldInfo]="feldInfo"></app-input-field-info>
    }
  </dx-number-input>
</div>
<app-readonly-template
  *ngIf="isReadonly"
  [field]="field"
  [displayValue]="getReadonlyDisplayValue()"
></app-readonly-template>
