import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { KeycloakApiService } from '../store/services/keycloak.api.service';
import { map, take } from 'rxjs/operators';

export const canActivateKeycloakGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const keycloakApiService = inject(KeycloakApiService);

  return keycloakApiService.updateTokenIfNeeded().pipe(
    take(1),
    map(() => true)
  );
};
