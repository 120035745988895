import { Component } from '@angular/core';
import { BasisFormularFeld } from '../basis-formular-feld';

@Component({
  selector: 'app-horizontal-line',
  templateUrl: './horizontal-line.component.html',
  styleUrls: ['./horizontal-line.component.scss'],
  standalone: false
})
export class HorizontalLineComponent extends BasisFormularFeld {}
