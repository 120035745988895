import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Kunde } from '../model/kunde.model';
import { KundenHttpService } from '../services/kunden-http.service';
import { UploadCameraDialogComponent } from '../upload-camera-dialog/upload-camera-dialog.component';
import { FilePayload } from '../model/payload';
import { EnvironmentInfoService } from '../../../../environment-info/src/lib/environment-info.service';
import { DateiauswahlComponent } from '../../../../dateiauswahl/src/lib/dateiauswahl.component';
import { DxAlert, DxModal } from '@dvag/design-system-angular';
import {
  ApplicationInsightsService,
  CustomAppInsightsError,
  UploadDialogType
} from '../../../../../src/app/services/application-insights.service';
import { UserContext } from '../model/events';
import { Subscription } from 'rxjs';
import { BucketAccessInfo } from '../model/buckets.model';
import { ConfigOptions } from '../model/config-options.model';
import { UploadDialogService } from '../services/upload-dialog.service';
import { ModalButtonConfig } from '../model/modal-button-config.model';

enum UploadArt {
  FOTO = 'Kamera',
  DATEIAUSWAHL = 'Dateiauswahl',
  GERAETEWECHSEL = 'Geraetewechsel'
}

enum UploadOptionsButtonIds {
  DATEIAUSWAHL = 'dokumente_hochladen',
  FOTO = 'dokument_fotografieren',
  GERAETEWECHSEL = 'dokumente_qrcode'
}

// https://www.dev6.com/angular/capturing-camera-images-with-angular/
@Component({
  selector: 'lib-upload-tool-dialog',
  templateUrl: './upload-tool-dialog.component.html',
  styleUrls: ['./upload-tool-dialog.component.scss'],
  standalone: false
})
export class UploadToolDialogComponent implements OnInit, OnDestroy {
  private kundenBuckets = new Map<string, BucketAccessInfo>();
  uploadArt = UploadArt;
  kunden: Kunde[] = [];
  vorselektierterKunde: Kunde = null;
  verifiedKundenNummer: string = null;
  showZuletztVerwendeteKundenHeadline = false;
  contextUeberschrift: string;
  anzeigeStatus: 'loading' | 'status' | 'error' | 'liste' | 'none' = 'none';
  komponentenAnzeige: 'kundenauswahl' | 'geraetewechsel' | 'dateiauswahl' = 'kundenauswahl';
  ladeZuletztverwendeteKunden: boolean = true;
  showModalBackButton = false;
  showKundenSuche = false;
  mergeToPdfByDefault = false;
  createBucketSub: Subscription;
  bucket: BucketAccessInfo = null;
  selectedUploadArt: UploadArt = null;
  creatingBucket = false;
  mergedUserContext: UserContext = null;
  tempInputUserContext: UserContext = UserContext.VB;
  modalClosedEvent: CustomEvent;
  mergedConfigOptions: ConfigOptions = undefined;

  // Dienen für die Anzeige der Buttons
  modalButtons: ModalButtonConfig[];
  private modalButtonsSubscription: Subscription;

  // Dienen für die Anzeige des Modal-Titels
  public modalTitle = 'Dokumente hinzufügen';
  private modalTitleSubscription: Subscription;

  @Input()
  configoptions: ConfigOptions = {};

  @Input()
  get openModal(): () => Promise<void> {
    return async () => {
      this.modalButtonsSubscription = this.uploadDialogService.modalButtons$.subscribe({
        next: buttons => {
          this.modalButtons = buttons;
          // *********************
          // TODO: Workaround für Ticket DSYS-1302 entfernen sobald umgesetzt
          // Mit dem Setzen des Modal-Titels wird das Modal neu gerendert, wodurch auch die Buttons neu geslottet werden.
          this.modalTitle = this.modalTitle + ' ';
          // *********************
          this.changeDetectorRef.detectChanges();
        }
      });
      this.modalTitleSubscription = this.uploadDialogService.modalTitle$.subscribe({
        next: title => {
          this.modalTitle = title;
          this.changeDetectorRef.detectChanges();
        }
      });

      // TODO: Remove mergeUserContextWithConfigOptions as soon as the attribute usercontext is removed
      this.configoptions = this.mergeUserContextWithConfigOptions(this.tempInputUserContext, this.configoptions);

      const validationResult = this.uploadDialogService.validateInputParameters(
        'TOOL_DIALOG',
        this.configoptions,
        this.kundennr,
        this.haushaltsid
      );
      if (validationResult.status === 'ERROR') {
        this.uploadDialogService.closeUploadProcess('ERROR', validationResult.message);
        this.unexpectedErrorAlert.visible = true;
        return;
      }
      this.mergedConfigOptions = this.uploadDialogService.initConfigOptions(this.configoptions);
      this.mergedUserContext =
        this.mergedConfigOptions.userContext === UserContext.ENDKUNDE ? UserContext.ENDKUNDE : UserContext.VB;
      this.uploadDialogService.setModalTitle(
        this.mergedConfigOptions?.maxCountOfDocuments === 1 ? 'Dokument hinzufügen' : 'Dokumente hinzufügen'
      );
      this.dxmodal.visible = true;
      await this.generateViewByInputValues();
      this.applicationInsightsService.logOpenedUploadDialog(UploadDialogType.Tool);
    };
  }

  // Es darf bei Inputs kein camelCase verwendet werden, nur kebab-case!
  /**
   * @Deprecated
   */
  @Input() set usercontext(selectedKundenKontext: string) {
    this.applicationInsightsService.logError(
      'UploadToolDialog',
      new CustomAppInsightsError(`The deprecated input "usercontext" is used with value "${selectedKundenKontext}".`, {
        hostname: window.location.hostname
      })
    );
    if (!selectedKundenKontext) {
      this.tempInputUserContext = null;
    }
    if (!(selectedKundenKontext in UserContext)) {
      this.tempInputUserContext = UserContext.VB;
    } else {
      this.tempInputUserContext = selectedKundenKontext as UserContext;
    }
  }

  /**
   * @Deprecated
   */
  @Input() set useflow(selectedFlow: any) {
    this.applicationInsightsService.logError(
      'UploadToolDialog',
      new CustomAppInsightsError(`The deprecated input "useflow" is used with value "${selectedFlow}" used.`, {
        hostname: window.location.hostname
      })
    );
  }

  @Input()
  kundennr: string;

  @Input()
  haushaltsid: string;

  @Output()
  bucketCreatedEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('dxmodal') dxmodal: DxModal;
  @ViewChild('fotoUploadComp') fotouploadComponent: UploadCameraDialogComponent;
  @ViewChild('dateiAuswahl') dateiAuswahl: DateiauswahlComponent;
  @ViewChild('unexpectedError') unexpectedErrorAlert: DxAlert;

  private uploadOptionButtons: ModalButtonConfig[];
  private standardUploadOptionButtons: ModalButtonConfig[] = [
    {
      label: 'Von diesem Gerät hochladen',
      icon: 'upload',
      id: UploadOptionsButtonIds.DATEIAUSWAHL,
      type: 'primary-s',
      disabled:
        (this.verifiedKundenNummer === null && !this.configoptions?.useGenericCustomer) ||
        (this.creatingBucket && this.selectedUploadArt !== this.uploadArt.DATEIAUSWAHL),
      loading: this.selectedUploadArt === this.uploadArt.DATEIAUSWAHL,
      clickFn: () => this.openUploadComponent(this.uploadArt.DATEIAUSWAHL)
    },
    {
      label: 'Mit diesem Gerät fotografieren',
      icon: 'foto-kamera',
      id: UploadOptionsButtonIds.FOTO,
      type: 'primary-s',
      disabled:
        (this.verifiedKundenNummer === null && !this.configoptions?.useGenericCustomer) ||
        (this.creatingBucket && this.selectedUploadArt !== this.uploadArt.FOTO),
      loading: this.selectedUploadArt === this.uploadArt.FOTO,
      clickFn: () => this.openUploadComponent(this.uploadArt.FOTO)
    }
  ];

  // Der Gerätewechsel-Button wird nicht bei Smartphones angezeigt, daher wird er hier separat definiert
  private geraetewechselUploadOptionButton: ModalButtonConfig = {
    label: 'Mit anderem Gerät fotografieren',
    icon: 'handy',
    id: UploadOptionsButtonIds.GERAETEWECHSEL,
    type: 'primary-s',
    disabled:
      (this.verifiedKundenNummer === null && !this.configoptions?.useGenericCustomer) ||
      (this.creatingBucket && this.selectedUploadArt !== this.uploadArt.GERAETEWECHSEL),
    loading: this.selectedUploadArt === this.uploadArt.GERAETEWECHSEL,
    clickFn: () => this.openUploadComponent(this.uploadArt.GERAETEWECHSEL)
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private kundenHttpService: KundenHttpService,
    private uploadDialogService: UploadDialogService,
    public environmentInfoService: EnvironmentInfoService,
    private applicationInsightsService: ApplicationInsightsService
  ) {
    this.uploadOptionButtons = this.standardUploadOptionButtons;
    if (!this.environmentInfoService.checkDeviceIsSmartphone()) {
      this.uploadOptionButtons.push(this.geraetewechselUploadOptionButton);
    }
    this.uploadDialogService.showModalButtons(this.uploadOptionButtons);
  }

  ngOnInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.modalButtonsSubscription?.unsubscribe();
    this.modalTitleSubscription?.unsubscribe();
  }

  private mergeUserContextWithConfigOptions(userContext: UserContext, configOptions: ConfigOptions): ConfigOptions {
    const mergedConfigOptions: ConfigOptions = { ...configOptions };
    if (userContext === UserContext.ENDKUNDE) {
      mergedConfigOptions.userContext = userContext;
    }
    return mergedConfigOptions;
  }

  private async generateViewByInputValues() {
    this.kunden = [];
    this.verifiedKundenNummer = null;
    this.showUploadToolDialogButtons();
    this.kundenBuckets.clear();
    this.bucket = null;
    this.changeDetectorRef.detectChanges();
    if (!this.kundennr && !this.haushaltsid && !this.mergedConfigOptions.useGenericCustomer) {
      this.openKundenSuche();
    } else if (this.mergedConfigOptions.useGenericCustomer) {
      this.anzeigeStatus = 'liste';
      this.openKundenauswahl();
    } else if (this.haushaltsid) {
      this.ladePersonenImHaushalt(this.haushaltsid, this.kundennr);
    } else if (!this.haushaltsid && this.kundennr) {
      await this.ladeKunde(this.kundennr);
    }
    this.changeDetectorRef.detectChanges();
  }

  openKundenSuche() {
    this.showKundenSuche = true;
    this.contextUeberschrift = 'Zu welchem Kunden möchten Sie die Dokumente hochladen?';
    this.anzeigeStatus = 'none';
    this.changeDetectorRef.detectChanges();
  }

  ladePersonenImHaushalt(haushaltsid: string, kundenNummer: string) {
    this.showKundenSuche = false;
    if (kundenNummer) {
      this.contextUeberschrift = 'Wie möchten Sie die Dokumente hochladen?';
    } else {
      this.contextUeberschrift = 'Zu welcher Person im gewählten Haushalt möchten Sie die Dokumente hochladen?';
    }
    this.anzeigeStatus = 'loading';
    this.changeDetectorRef.detectChanges();

    this.kundenHttpService.ladeHaushalt(haushaltsid).subscribe({
      next: haushalt => {
        this.anzeigeStatus = 'liste';

        if (!this.mergedConfigOptions.checkEWE) {
          haushalt.mitglieder = haushalt.mitglieder.map(kunde => ({ ...kunde, eweZugestimmt: true }));
        }

        if (haushalt.mitglieder.length === 1) {
          this.kunden = haushalt.mitglieder;
          this.vorselektierterKunde = haushalt.mitglieder[0];
        } else {
          this.kunden = this.sortiereKundenlisteFuerAnzeige(haushalt.mitglieder, kundenNummer);
          this.vorselektierterKunde = this.kunden.find(kunde => kunde.nummer === kundenNummer);
        }
        this.changeDetectorRef.detectChanges();
      },
      error: err => {
        console.log(
          'PersonenAuswahlComponent ladePersonenImHaushalt() - Fehler beim Laden des Haushalts mit der ID: %s ! ',
          haushaltsid,
          err.status,
          err
        );
        this.anzeigeStatus = 'error';
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  private sortiereKundenlisteFuerAnzeige(originalKundenliste: Kunde[], kundenNummer: string): Kunde[] {
    let sortiereterKundenliste: Kunde[];
    if (kundenNummer && originalKundenliste.find(kunde => kunde.nummer === kundenNummer)) {
      sortiereterKundenliste = originalKundenliste
        .filter(kunde => kunde.nummer === kundenNummer)
        .concat(originalKundenliste.filter(kunde => kunde.nummer !== kundenNummer));
    } else {
      sortiereterKundenliste = originalKundenliste;
    }
    return sortiereterKundenliste;
  }

  async ladeKunde(kundenNummer: string) {
    this.showKundenSuche = false;
    this.contextUeberschrift =
      this.mergedUserContext === UserContext.ENDKUNDE ? '' : 'Wie möchten Sie die Dokumente hochladen?';
    this.changeDetectorRef.detectChanges();
    if (kundenNummer) {
      this.anzeigeStatus = 'loading';
      try {
        const kundeNow = await this.uploadDialogService.ladeKunde(kundenNummer);
        if (!this.mergedConfigOptions.checkEWE) {
          kundeNow.eweZugestimmt = true;
        }
        this.anzeigeStatus = 'liste';
        this.kunden = [kundeNow];
        this.vorselektierterKunde = kundeNow;
        // da die verifiedKundennummer durch die Kundenauswahl getriggert wird, diese aber im Falle des Endkunden
        // nicht angezeigt wird, sondern ein Text, muss hier verifiedKundennummer anders gesetzt werden.
        if (this.vorselektierterKunde.eweZugestimmt && this.mergedUserContext === UserContext.ENDKUNDE) {
          this.setVerifiedKundennummer(kundenNummer);
        }
      } catch (error) {
        console.log('Fehler beim Laden des Kunden: ', error);
        this.anzeigeStatus = 'error';
      } finally {
        this.changeDetectorRef.detectChanges();
      }
    } else {
      this.anzeigeStatus = 'error';
      this.changeDetectorRef.detectChanges();
    }
  }

  setKundenlistVonKundensuche(kundenliste: Kunde[]) {
    this.verifiedKundenNummer = null;
    this.vorselektierterKunde = null;
    this.changeDetectorRef.detectChanges();
    if (kundenliste?.length > 0) {
      this.kunden = this.sortiereKundenlisteFuerAnzeige(kundenliste, null);
      this.anzeigeStatus = 'liste';
    } else {
      this.anzeigeStatus = 'status';
    }
    this.changeDetectorRef.detectChanges();
  }

  handleKundenSucheInfo(info: 'loading' | 'error') {
    this.verifiedKundenNummer = null;
    this.showZuletztVerwendeteKundenHeadline = false;
    this.anzeigeStatus = info;
    switch (info) {
      case 'loading':
        this.kunden = [];
        break;
      case 'error':
        this.unexpectedErrorAlert.visible = true;
        break;
    }
    this.showUploadToolDialogButtons();
    this.changeDetectorRef.detectChanges();
  }

  // handle visibilitiy of warning alert in Child-Component
  confirmCancelUpload(event: CustomEvent) {
    event.detail.preventCloseModal();
    if (this.komponentenAnzeige === 'kundenauswahl') {
      this.closeDialogWithReason('CANCELED');
    } else {
      this.modalClosedEvent = event;
      this.changeDetectorRef.detectChanges();
    }
  }

  closeDialogWithReason(reason: 'DEVICE_CHANGED_CANCELED' | 'CANCELED' | 'DEVICE_CHANGED') {
    this.dxmodal.visible = false;
    this.uploadDialogService.closeUploadProcess(reason);
    this.resetDialog();
    this.unexpectedErrorAlert.visible = false;
  }

  private resetDialog() {
    this.modalClosedEvent = undefined;
    this.showModalBackButton = false;
    this.ladeZuletztverwendeteKunden = true;
    this.anzeigeStatus = 'none';
    this.showKundenSuche = false;
    this.vorselektierterKunde = null;
    this.kunden = [];
    this.selectedUploadArt = null;
    this.uploadDialogService.setModalTitle('Dokumente hinzufügen');
    this.komponentenAnzeige = 'kundenauswahl';
    this.createBucketSub?.unsubscribe();
    this.mergedConfigOptions = undefined;
    this.modalButtonsSubscription?.unsubscribe();
    this.modalTitleSubscription?.unsubscribe();
    this.changeDetectorRef.detectChanges();
    this.showZuletztVerwendeteKundenHeadline = false;
    this.contextUeberschrift = '';
  }

  setVerifiedKundennummer(kdNr: string) {
    this.verifiedKundenNummer = kdNr;
    this.showUploadToolDialogButtons();
    this.changeDetectorRef.detectChanges();
  }

  shouldSpanSelectCustomerContext(anzeigeStatus: 'loading' | 'status' | 'error' | 'liste' | 'none') {
    switch (anzeigeStatus) {
      case 'loading':
      case 'status':
      case 'error':
        return '1 / span 2';
      default:
        return '1';
    }
  }

  private async getBucketForSelectedCustomer(kundenNr: string): Promise<BucketAccessInfo | null> {
    if (this.kundenBuckets.has(kundenNr)) {
      const bucket = this.kundenBuckets.get(kundenNr);
      console.log('Es existiert bereits ein Bucket: ', bucket?.bucketId);
      return bucket;
    }

    const tempBucket = await this.createBucketForCustomer(kundenNr);
    this.kundenBuckets.set(kundenNr, tempBucket);
    console.log('Bucket für Kunden "%s" erzeugt: ', kundenNr, tempBucket.bucketId);
    return tempBucket;
  }

  async openUploadComponent(uploadArt: UploadArt) {
    // do not trigger again during bucket creation
    if (this.creatingBucket) {
      return;
    }

    // disable the buttons
    this.selectedUploadArt = uploadArt;
    this.creatingBucket = true;
    this.showUploadToolDialogButtons();
    this.changeDetectorRef.detectChanges();

    // load Bucket
    try {
      this.bucket = await this.getBucketForSelectedCustomer(this.verifiedKundenNummer);
      this.changeDetectorRef.detectChanges();
    } catch (e) {
      this.creatingBucket = false;
      this.selectedUploadArt = null;
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.applicationInsightsService.logUploadVarianteSelected(uploadArt);

    switch (uploadArt) {
      case UploadArt.FOTO:
        this.dxmodal.visible = false;
        this.fotouploadComponent.openModal();
        this.resetDialog();
        break;
      case UploadArt.DATEIAUSWAHL:
        this.komponentenAnzeige = 'dateiauswahl';
        this.showModalBackButton = true;
        break;
      case UploadArt.GERAETEWECHSEL:
        this.komponentenAnzeige = 'geraetewechsel';
        this.showModalBackButton = true;
    }

    this.creatingBucket = false;
    this.selectedUploadArt = null;
    this.changeDetectorRef.detectChanges();
  }

  openKundenauswahl() {
    this.ladeZuletztverwendeteKunden = false;
    this.vorselektierterKunde = this.mergedConfigOptions.useGenericCustomer
      ? null
      : this.kunden.find(kunde => kunde.nummer === this.verifiedKundenNummer);
    this.komponentenAnzeige = 'kundenauswahl';
    this.changeDetectorRef.detectChanges();
    this.showModalBackButton = false;
    this.showUploadToolDialogButtons();
    this.uploadDialogService.setModalTitle(
      this.mergedConfigOptions.maxCountOfDocuments === 1 ? 'Dokument hinzufügen' : 'Dokumente hinzufügen'
    );
    this.changeDetectorRef.detectChanges();
  }

  uploadDokument(uploadData: { dateien: File[]; mergeToPdf: boolean }) {
    if (!this.bucket.bucketId) {
      // TODO: ErrorDialog wegen BucketID
      return;
    }

    const payloads = uploadData.dateien.map(file => new FilePayload(file));
    this.uploadDialogService.uploadDokument(payloads, this.bucket.bucketId, uploadData.mergeToPdf);
    this.dxmodal.visible = false;
    this.resetDialog();
  }

  async createBucketForCustomer(kundennummer?: string): Promise<BucketAccessInfo> {
    let bucketAccessInfo: BucketAccessInfo;
    try {
      bucketAccessInfo = await this.uploadDialogService.createBucket(kundennummer ?? undefined);
      this.bucketCreatedEvent.emit(bucketAccessInfo.bucketId ?? '');
    } catch (error) {
      this.unexpectedErrorAlert.visible = true;
      this.uploadDialogService.closeUploadProcess('ERROR', 'Bucket creation failed.');
    }
    return bucketAccessInfo ?? null;
  }

  onModalClosed(e: CustomEvent) {
    if (e.detail.reason === 'background') {
      e.detail.preventCloseModal();
      return;
    }
    this.confirmCancelUpload(e);
  }

  onZuletztVerwendeKunden(zuletztVerwendeteKunden: Kunde[]) {
    this.ladeZuletztverwendeteKunden = false;
    if (!zuletztVerwendeteKunden || zuletztVerwendeteKunden.length === 0) {
      return;
    }
    this.showZuletztVerwendeteKundenHeadline = true;
    this.setKundenlistVonKundensuche(zuletztVerwendeteKunden);
  }

  showUploadToolDialogButtons() {
    this.updateUploadToolDialogButtonsStates();
    this.uploadDialogService.showModalButtons(this.uploadOptionButtons);
  }

  private updateUploadToolDialogButtonsStates(): void {
    this.uploadOptionButtons.forEach(button => {
      switch (button.id) {
        case UploadOptionsButtonIds.FOTO:
          button.disabled =
            (this.verifiedKundenNummer === null && !this.mergedConfigOptions?.useGenericCustomer) ||
            (this.creatingBucket && this.selectedUploadArt !== this.uploadArt.FOTO);
          button.loading = this.selectedUploadArt === this.uploadArt.FOTO;
          break;
        case UploadOptionsButtonIds.DATEIAUSWAHL:
          button.disabled =
            (this.verifiedKundenNummer === null && !this.mergedConfigOptions?.useGenericCustomer) ||
            (this.creatingBucket && this.selectedUploadArt !== this.uploadArt.DATEIAUSWAHL);
          button.loading = this.selectedUploadArt === this.uploadArt.DATEIAUSWAHL;
          break;
        case UploadOptionsButtonIds.GERAETEWECHSEL:
          button.disabled =
            (this.verifiedKundenNummer === null && !this.mergedConfigOptions?.useGenericCustomer) ||
            (this.creatingBucket && this.selectedUploadArt !== this.uploadArt.GERAETEWECHSEL);
          button.loading = this.selectedUploadArt === this.uploadArt.GERAETEWECHSEL;
          break;
      }
    });
  }

  handleUseButtons(buttons: ModalButtonConfig[]) {
    console.log('handleUseButtons', buttons);
    this.uploadDialogService.showModalButtons(buttons);
  }

  changeModalTitle(title: string) {
    if (title) {
      this.uploadDialogService.setModalTitle(title);
    } else {
      this.uploadDialogService.setModalTitle(
        this.mergedConfigOptions.maxCountOfDocuments === 1 ? 'Dokument hochladen' : 'Dokumente hochladen'
      );
    }
    this.changeDetectorRef.detectChanges();
  }
}
