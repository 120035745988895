import { Injectable } from '@angular/core';
import { Auftrag } from '../../../store/models/auftrag.model';
import moment from 'moment';
import 'moment/locale/de';

// set moment locale
moment.locale('de');

@Injectable({
  providedIn: 'root'
})
export class FilteredAuftraegeService {
  private momentFormatChecks = [
    { regEx: /^0[1-9]|[12]\d|3[01]$/, formats: ['D', 'DD'] }, // consider, if currentSearch is a day (d)
    { regEx: /^20\d{2}$/, formats: ['YYYY'] }, // consider, if currentSearch is a year (yyyy)
    { regEx: /^(0[1-9]|[12]\d|3[01])\.(0?[1-9]|1[012])\.?$/, formats: ['D.M', 'D.M.', 'DD.MM', 'DD.MM.'] }, // consider, if currentSearch is a month.day. (m.d.)
    { regEx: /^(0[1-9]|[12]\d|3[01])\.(0?[1-9]|1[012])\.20\d{2}$/, formats: ['D.M.YYYY'] }, // consider, if currentSearch is a complete date (m.d.y)
    {
      regEx: /^(Montag|Mo|Dienstag|Di|Mittwoch|Mi|Donnerstag|Do|Freitag|Fr|Samstag|Sa|Sonntag|So)$/,
      formats: ['dd', 'dddd']
    } // consider, if currentSearch is a week day (from the past 6 days)
  ];

  public considerAuftrag(auftrag: Auftrag, searchTerm?: string): boolean {
    // no search term, all are considered
    if (!searchTerm) {
      return true;
    }

    const foundMatch = this.momentFormatChecks
      .flatMap(check => (check.regEx.test(searchTerm) ? check.formats : []))
      .some(format => moment(auftrag.eingangsDatum).format(format) === searchTerm);

    // some of the date checks matched
    if (foundMatch) {
      return true;
    }

    // consider, if currentSearch is contained in one of the fields (fulltext)
    const searchStringLower = searchTerm.toLowerCase();

    const searchFieldIsPresent = ['kundenName', 'kundenNummer', 'datenbestand'].some(searchField =>
      auftrag[searchField]?.toLowerCase().includes(searchStringLower)
    );

    const formularNameIsPresent = auftrag.vertraege
      ?.filter(vertrag => vertrag.formularName)
      .map(vertrag => vertrag.formularName.toLowerCase())
      .some(searchChar => searchChar.includes(searchStringLower));

    return searchFieldIsPresent || formularNameIsPresent;
  }

  private sortArr(arrNow: Auftrag[], sortUp: boolean): Auftrag[] {
    return arrNow.sort((a, b) => {
      if (a.eingangsDatum < b.eingangsDatum) {
        return sortUp ? 1 : -1;
      } else if (a.eingangsDatum > b.eingangsDatum) {
        return sortUp ? -1 : 1;
      } else {
        // equal
        return 0;
      }
    });
  }

  public filterAuftraege(auftragList: Auftrag[], currentSorting: 'up' | 'down', searchTerm?: string): Auftrag[] {
    if (!auftragList || !auftragList.length) {
      return [];
    }

    return this.sortArr(
      auftragList.filter(auftrag => this.considerAuftrag(auftrag, searchTerm)),
      currentSorting === 'up'
    );
  }
}
