<lib-upload-tool-dialog #uploadtooldialog></lib-upload-tool-dialog>
<dx-container type="page" color="background" style="padding-top: 32px">
  <dx-tab-bar id="auftrag-tabbar" [selected]="selectedTab" (tabChange)="selectTab($event.detail)">
    <dx-tab-page
      id="auftragsfilter-{{ AuftragKategorie.OFFEN.toLowerCase() }}"
      label="({{ (offeneAuftraege$ | async)?.length ?? 0 }}) Offene Dokumente"
      value="{{ AuftragKategorie.OFFEN }}"
    ></dx-tab-page>
    <dx-tab-page
      id="auftragsfilter-{{ AuftragKategorie.BEARBEITET.toLowerCase() }}"
      label="Bearbeitete Dokumente"
      value="{{ AuftragKategorie.BEARBEITET }}"
    ></dx-tab-page>
  </dx-tab-bar>
</dx-container>
<dx-container type="page" color="white" class="info-filter-container">
  <dx-grid mq2="12/*" mq3="6-6/*" mq4="8-4/*" mq5="6-(3)-3/*">
    <div id="loeschhinweis" class="loeschhinweis-container">
      <dx-icon icon="information" size="24"></dx-icon>
      <dx-text
        type="ps"
        *ngIf="selectedTab === AuftragKategorie.OFFEN"
        id="loeschhinweis-{{ AuftragKategorie.OFFEN.toLowerCase() }}"
        >Die Dokumente werden nach 90 Tagen aus Datenturbo entfernt, stehen Ihnen aber weiterhin in der DVAG-Cloud zur
        Verfügung.
      </dx-text>
      <dx-text
        type="ps"
        *ngIf="selectedTab === AuftragKategorie.BEARBEITET"
        id="loeschhinweis-{{ AuftragKategorie.BEARBEITET.toLowerCase() }}"
        >Die bearbeiteten Dokumente werden nach 180 Tagen aus Datenturbo entfernt, stehen Ihnen aber weiterhin in der
        DVAG-Cloud zur Verfügung.
      </dx-text>
    </div>
    <dx-text-input
      #searchinput
      id="auftrag-filter"
      [value]="searchTerm"
      icon="filter"
      (valueChange)="filterAuftragsliste(searchinput.value)"
    ></dx-text-input>
  </dx-grid>
</dx-container>

<dx-container type="page" color="background" style="padding-bottom: 24px; flex: 1">
  <dx-grid
    mq2="12/*"
    mq3="{{ fileUploadServive.activeUploads.size > 0 ? '8-4/*' : '12/*' }}"
    mq4="{{ fileUploadServive.activeUploads.size > 0 ? '(3)-6-3/*' : '12/*' }}"
  >
    <div *ngIf="fileUploadServive.activeUploads.size > 0" id="status-file-upload" class="status-file-upload">
      <dx-loading-indicator size="small" theme="light" label=""></dx-loading-indicator>
      <dx-text id="status-file-upload__text" type="it">Ihre Dokumente werden hochgeladen.</dx-text>
    </div>
    <div
      style="margin-bottom: 16px; margin-top: 16px; display: flex; justify-content: end; align-items: center; gap: 8px"
    >
      <dx-text type="it">{{ sorting === 'up' ? 'Neueste' : 'Älteste' }} Dokumente zuerst</dx-text>
      <dx-icon
        icon="Verteilen-vertikal"
        size="24"
        id="button-sortieren"
        class="sortierung-toggle"
        color="default"
        (click)="toggleSortierung()"
      ></dx-icon>
    </div>
  </dx-grid>
  <dx-grid mq2="12/*" mq3="6-6/*" mq4="4-4-4/*" mq5="3-3-3-3/*" [ngSwitch]="selectedTab">
    <ng-container *ngSwitchCase="AuftragKategorie.OFFEN">
      <div
        class="auftrag-hinzufuegen-container"
        (click)="uploadToolDialog.openModal()"
        (keydown.enter)="uploadToolDialog.openModal()"
        (keydown.space)="uploadToolDialog.openModal()"
        tabindex="0"
      >
        <div id="auftrag-hinzufuegen" class="add-auftrag">
          <dx-icon id="auftrag-hinzufuegen-icon" icon="PlusKreis" size="72"></dx-icon>
          <dx-text id="auftrag-hinzufuegen-text" type="ps">Dokumente hochladen</dx-text>
        </div>
      </div>
      <app-auftrag
        *ngFor="let auftrag of offeneAuftraege$ | async"
        id="{{ auftrag.auftragId }}"
        [routerLink]="
          ['NACHLADBAR', 'BEREITGESTELLT'].includes(auftrag.datenbestand.toUpperCase())
            ? ['/dokumentsplitscreen/', auftrag.auftragId]
            : null
        "
        [auftrag]="auftrag"
        (deleteAuftrag)="onDeleteAuftrag($event)"
        (handleDownloadError)="showDownloadError()"
      >
      </app-auftrag>
    </ng-container>
    <ng-container *ngSwitchCase="AuftragKategorie.BEARBEITET">
      <app-auftrag
        *ngFor="let auftrag of bearbeiteteAuftraege$ | async"
        id="{{ auftrag.auftragId }}"
        [routerLink]="
          ['NACHLADBAR', 'BEREITGESTELLT'].includes(auftrag.datenbestand.toUpperCase())
            ? ['/dokumentsplitscreen/', auftrag.auftragId]
            : null
        "
        [auftrag]="auftrag"
        (deleteAuftrag)="onDeleteAuftrag($event)"
        (handleDownloadError)="showDownloadError()"
      >
      </app-auftrag>
    </ng-container>
  </dx-grid>
</dx-container>
<dx-alert
  #auftraglisteAlert
  id="auftrag-liste-alert"
  titlestring="{{ simpleSplitscreenErrorAlertTitle }}"
  sublabel="Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut."
  icon="warndreieck"
  type="error"
>
  <dx-button
    id="auftrag-liste-alert-okay"
    type="text"
    slot="action"
    label="Okay"
    (click)="closeSimpleSplitscreenErrorAlert()"
  ></dx-button>
</dx-alert>
<dx-alert
  #loeschenAlert
  id="download-alert"
  icon="warndreieck"
  type="error"
  titlestring="Auftrag löschen"
  actionsdirection="row"
>
  <dx-text id="modal_body" slot="custom" type="it">
    <p id="loeschen-alert-info">
      Möchten Sie den Auftrag {{ zuLoeschenderAuftragType }} von {{ zuLoeschenderAuftragPerson }} wirklich löschen?
    </p>
    Gelöschte Dokumente stehen Ihnen weiterhin im DVAG Online-System zur Verfügung.
  </dx-text>
  <dx-button
    id="button-delete"
    type="primary-s"
    slot="action"
    theme="destructive"
    label="Löschen"
    icon="loeschen"
    (click)="confirmedDeleteAuftrag()"
  ></dx-button>
  <dx-button
    id="button-abbrechen"
    type="text"
    slot="action"
    label="Abbrechen"
    (click)="declinedDeleteAuftrag()"
  ></dx-button>
</dx-alert>
