import {
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { Base64Payload } from '../model/payload';
import { UploadDialogService } from '../services/upload-dialog.service';
import { DxAlert } from '@dvag/design-system-angular';
import { ConfigOptions } from '../model/config-options.model';
import {
  ApplicationInsightsService,
  DT_FE_CUSTOM_EVENT,
  UploadDialogType
} from '../../../../../src/app/services/application-insights.service';

@Component({
  selector: 'lib-upload-camera-dialog',
  templateUrl: './upload-camera-dialog.component.html',
  styleUrls: ['./upload-camera-dialog.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class UploadCameraDialogComponent {
  visible = false;
  mergedConfigOptions: WritableSignal<ConfigOptions> = signal<ConfigOptions>(undefined);
  allowOnlyOneImage = computed(() => {
    const options = this.mergedConfigOptions();
    if (options) {
      return !options.mergeToPdfByDefault && options.maxCountOfDocuments === 1;
    }
    return false;
  });

  @Input()
  kundennr: string = null;

  @Input()
  configoptions: ConfigOptions = {};

  @Input()
  bucketId: string = null;

  @Input()
  get openModal(): () => void {
    return async () => {
      // Wenn bereits eine BucketId existiert, dann kommen wir vom UploadToolDialog.
      // Die ConfigOptions sind in diesem Fall bereits vom UploadToolDialog validiert und gemerged worden.
      // Daher können sie in diesem Fall einfach übernommen werden.
      if (this.bucketId && this.configoptions) {
        this.mergedConfigOptions.set(this.configoptions);
      } else {
        const validationResult = this.uploadDialogService.validateInputParameters(
          'FILE_FOTO_DIALOG',
          this.configoptions,
          this.kundennr,
          null
        );
        if (validationResult.status === 'ERROR') {
          this.uploadDialogService.closeUploadProcess('ERROR', validationResult.message);
          this.configErrorAlert.visible = true;
          return;
        }
        this.mergedConfigOptions.set(this.uploadDialogService.initConfigOptions(this.configoptions));
        await this.createBucket(this.mergedConfigOptions().useGenericCustomer ? null : this.kundennr);
        // Es soll nur der direkte Aufruf des UploadCameraDialogs getracked werden und nicht der Aufruf aus dem UploadToolDialog
        this.applicationInsightsService.logOpenedUploadDialog(UploadDialogType.Camera);
      }

      this.visible = true;
      this.changeDetectorRef.detectChanges();
    };
  }

  /**
   * Wird nur gefeuert, wenn die upload-foto-dialog direkt aufgerufen wird und nicht über den upload-tool-dialog
   */
  @Output()
  bucketCreatedEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('configErrorAlert') configErrorAlert: DxAlert;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private uploadDialogService: UploadDialogService,
    private applicationInsightsService: ApplicationInsightsService
  ) {}

  cancelUpload() {
    this.uploadDialogService.closeUploadProcess('CANCELED');
    this.closeAndResetModal();
  }

  async createBucket(kundennr?: string) {
    // Fotokomponente kann auch als eigenständige WebKomponente eingebunden werden,
    // die dann noch keine BucketID hat und eine generiert werden muss.
    if (!this.bucketId) {
      try {
        const bucketAccessInfo = await this.uploadDialogService.createBucket(kundennr ?? undefined);
        this.bucketCreatedEvent.emit(bucketAccessInfo.bucketId ?? '');
        this.bucketId = bucketAccessInfo.bucketId;
      } catch (error) {
        this.uploadDialogService.closeUploadProcess('ERROR', 'Bucket creation failed.');
        this.configErrorAlert.visible = true;
        return;
      }
    }
  }

  async sendImages(images: string[]) {
    this.uploadDialogService.uploadDokument(
      images.map(image => new Base64Payload(image)),
      this.bucketId,
      true
    );

    this.closeAndResetModal();
  }

  closeAndResetModal() {
    this.resetDialog();
    this.visible = false;
    this.changeDetectorRef.detectChanges();
  }

  private resetDialog() {
    this.kundennr = null;
    this.mergedConfigOptions.set(undefined);
    this.bucketId = null;
    this.configErrorAlert.visible = false;
  }
}
