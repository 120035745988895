import { Component, ElementRef, ViewChild } from '@angular/core';
import { BasisInputFormularFeld } from '../basis-input-formular-feld';
import { FormBuilder } from '@angular/forms';
import { FormularFeldValidatoren } from '../../validators/formular-feld-validatoren';
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service';
import { DxTextInput } from '@dvag/design-system-angular';

@Component({
  selector: 'app-kennzeichen',
  templateUrl: './kennzeichen.component.html',
  standalone: false
})
export class KennzeichenComponent extends BasisInputFormularFeld {
  @ViewChild('focusElement') focusElement: DxTextInput;
  @ViewChild('focusElement', { read: ElementRef }) elementRef: ElementRef;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService
  ) {
    super(fb, focusedControlHandlerService);
  }

  override validate(value: string): void {
    this.field.validators.forEach(validator => {
      this.errormessage = '';
      if (validator.name.toLowerCase() === 'kennzeichen') {
        const validatorResp = FormularFeldValidatoren.kennzeichen();
        if (validatorResp(this.formControl)) {
          this.errormessage = validator.fehlerText;
        }
      }

      // Wenn bereits eine Errormessage gesetzt ist, dann soll keine weitere mehr angezeigt werden
      if (this.errormessage) {
        return;
      }
    });
  }

  onValueComplete(eingabe: string) {
    this.errormessage = '';

    const kennzeichen = this.kennzeichenAufbereiten(eingabe);

    this.patchValue(kennzeichen);
  }

  private kennzeichenAufbereiten(value): string {
    if (!value) {
      return null;
    }
    const eingabeOhneSonderzeichen = value.toUpperCase().replace(/[^A-ZÄÖÜ0-9-\s]/g, '');
    const matches = eingabeOhneSonderzeichen.match(
      /^([\s]*[A-ZÄÖÜ]{1,3})([-\s]+)([A-Z]{1,2})([-\s]*)([0-9]{1,4}[A-Z]?[\s]*)?$/
    );

    if (matches === null) {
      return eingabeOhneSonderzeichen;
    }

    const bezirk = matches[1];
    const buchstaben = matches[3] ?? '';
    const ziffern = matches[5] ?? '';

    if (`${buchstaben}${ziffern}` === '') {
      return bezirk;
    } else {
      return `${bezirk}-${buchstaben} ${ziffern}`.trim();
    }
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: any): any {
    ocrText = ocrText.replace('|', 'I');
    if (!ocrText) {
      return oldValue;
    } else if (!oldValue) {
      return this.kennzeichenAufbereiten(ocrText);
    } else {
      return this.kennzeichenAufbereiten(oldValue + ocrText);
    }
  }

  override async focus(): Promise<void> {
    if (this.focusElement?.focusControl) {
      try {
        await this.focusElement?.focusControl();
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`);
      }
    } else {
      console.warn(
        `An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`
      );
    }
  }
}
