import { Injectable } from '@angular/core';
import Hotjar from '@hotjar/browser';

@Injectable({
  providedIn: 'root'
})
export class HotjarService {
  private siteId = 3716848;
  private hotjarVersion = 6;

  constructor() {
    Hotjar.init(this.siteId, this.hotjarVersion);
  }

  callHotjar() {
    Hotjar.event('dt_kfz_bearbeitet');
  }
}
