<lib-upload-camera-dialog
  #fotoUploadComp
  [kundennr]="verifiedKundenNummer"
  [bucketId]="bucket?.bucketId"
  [configoptions]="mergedConfigOptions"
></lib-upload-camera-dialog>
<dx-modal #dxmodal id="m2" height="960" width="xl" [label]="modalTitle" (modalClosed)="onModalClosed($event)">
  <div slot="content" style="height: 100%" [ngSwitch]="komponentenAnzeige">
    <div id="customer-select" *ngSwitchCase="'kundenauswahl'" class="upload-tool-content">
      <div id="dialog-context-headline" class="context-headline">
        <dx-text>{{ contextUeberschrift }}</dx-text>
      </div>

      <lib-kundensuche
        *ngIf="showKundenSuche"
        class="customer-search"
        id="upload-tool-dialog_kundensuche"
        [ladeZuletztVerwendeteKunden]="ladeZuletztverwendeteKunden"
        (benutzeKundenliste)="setKundenlistVonKundensuche($event)"
        (hatZuletztVerwendeteKunden)="onZuletztVerwendeKunden($event)"
        (showLoadingInfo)="handleKundenSucheInfo($event)"
      >
      </lib-kundensuche>

      <div
        class="select-customer-context"
        [ngStyle]="{ 'grid-column': shouldSpanSelectCustomerContext(anzeigeStatus) }"
        [ngSwitch]="anzeigeStatus"
      >
        <div id="loading-data" *ngSwitchCase="'loading'" class="spacing">
          <dx-loading-indicator
            [ngClass]="{ endkunde: mergedUserContext === 'ENDKUNDE' }"
            size="{{ mergedUserContext === 'ENDKUNDE' ? 'large' : 'small' }}"
            label="{{ mergedUserContext === 'ENDKUNDE' ? '' : 'Kunden werden geladen ...' }}"
            labelalign="{{ mergedUserContext === 'ENDKUNDE' ? '' : 'right' }}"
          >
          </dx-loading-indicator>
        </div>

        <ng-container *ngIf="!showKundenSuche">
          <div id="loading-error" class="info-text" *ngSwitchCase="'error'">
            <dx-icon icon="warndreieck" size="24" color="red-100"></dx-icon>
            <dx-text type="ps" id="loading-error__text"
              >Beim Laden der Kundendaten ist ein Fehler aufgetreten. Versuchen Sie es in einigen Minuten bitte erneut.
            </dx-text>
          </div>
        </ng-container>
        <div class="spacing" *ngIf="showZuletztVerwendeteKundenHeadline">
          <dx-text id="zuletzt-verwendete-kunden-headline" type="ps">Zuletzt ausgewählt:</dx-text>
        </div>
        <ng-container *ngSwitchCase="'liste'">
          <lib-kunden-auswahl
            *ngIf="mergedUserContext !== 'ENDKUNDE' && !mergedConfigOptions?.useGenericCustomer"
            id="upload-tool-dialog_kunden-auswahl"
            [kundenliste]="kunden"
            [vorselektierterKunde]="vorselektierterKunde"
            [adresseAnzeigen]="true"
            (benutzeKunde)="setVerifiedKundennummer($event)"
          >
          </lib-kunden-auswahl>

          <dx-grid mq1="12/*" *ngIf="mergedUserContext === 'ENDKUNDE'">
            <div class="kundenInfoText" *ngIf="!verifiedKundenNummer; else kundenInfoTextDefault">
              <dx-icon class="icon" icon="information" size="72"></dx-icon>
              <dx-text class="text" type="ps"
                >Für die Übermittlung wichtiger Unterlagen ist eine Einwilligung in die Datenverarbeitung erforderlich.
                Bitte setzen Sie sich dazu mit Ihrem Vermögensberater in Verbindung, der Ihnen bei den nächsten
                Schritten zur Seite steht.
              </dx-text>
            </div>
            <ng-template #kundenInfoTextDefault>
              <div class="kundenInfoText">
                <dx-icon class="icon" icon="upload" size="72"></dx-icon>
                <dx-text class="text" type="ps"
                  >Für eine Beratung, die genau auf Ihre Bedürfnisse zugeschnitten ist, ist ein Einblick in Ihre
                  finanzielle Situation essenziell.
                </dx-text>
                <dx-text class="text" type="ps"
                  >Übermitteln Sie daher wichtige Unterlagen wie Versicherungspolicen oder Gehaltsabrechnungen digital
                  an Ihren Vermögensberater – ganz einfach durch Hochladen oder Fotografieren.
                </dx-text>
              </div>
            </ng-template>
          </dx-grid>

          <dx-grid mq1="12/*" *ngIf="mergedConfigOptions.useGenericCustomer">
            <div class="kundenInfoText">
              <dx-icon class="icon" icon="upload" size="72"></dx-icon>
              <dx-text id="generic-customer-text" class="text-genericCustomer" type="ps">
                <ng-container *ngIf="mergedConfigOptions.maxCountOfDocuments > 1"
                  >Wie möchten Sie die Dokumente hinzufügen?</ng-container
                >
                <ng-container *ngIf="mergedConfigOptions.maxCountOfDocuments === 1"
                  >Wie möchten Sie das Dokument hinzufügen?</ng-container
                >
              </dx-text>
            </div>
          </dx-grid>
        </ng-container>
      </div>
    </div>
    <lib-switch-device
      id="upload-tool-dialog_switch-device"
      *ngSwitchCase="'geraetewechsel'"
      [qrCodeInfo]="bucket"
      [modalClosedEvent]="modalClosedEvent"
      [configOptions]="mergedConfigOptions"
      (closeModal)="closeDialogWithReason('DEVICE_CHANGED_CANCELED')"
      (goBackEvent)="openKundenauswahl()"
      (changeDeviceEvent)="closeDialogWithReason('DEVICE_CHANGED')"
      (useButtons)="handleUseButtons($event)"
      (useHeadline)="changeModalTitle($event)"
    >
    </lib-switch-device>

    <lib-dateiauswahl
      #dateiAuswahl
      id="upload-tool-dialog_dateiauswahl"
      [modalClosedEvent]="modalClosedEvent"
      [configOptions]="mergedConfigOptions"
      [showBackButton]="true"
      [bucketId]="bucket?.bucketId"
      *ngSwitchCase="'dateiauswahl'"
      (useButtons)="handleUseButtons($event)"
      (backButtonClicked)="openKundenauswahl()"
      (closeModal)="closeDialogWithReason('CANCELED')"
      (uploadTriggered)="uploadDokument($event)"
      (useHeadline)="changeModalTitle($event)"
    >
    </lib-dateiauswahl>
  </div>
  <!-- Die Unterscheidung der Buttons fuer dei Kundenauswahl und nicht Kundenauswahl muss bestehen bleiben, da die Buttons unterschiedlich angeordnet werden -->
  <dx-grid
    *ngIf="komponentenAnzeige === 'kundenauswahl'; else slottedButtons"
    mq4="12/*"
    mq5="4-4-4"
    slot="custom-actions"
  >
    <dx-button
      *ngFor="let buttonConfig of modalButtons"
      stretch
      [label]="buttonConfig.label"
      [id]="buttonConfig.id"
      [disabled]="buttonConfig.disabled ?? false"
      [loading]="buttonConfig.loading ?? false"
      [type]="buttonConfig.type"
      [icon]="buttonConfig.icon ?? null"
      (click)="buttonConfig.clickFn()"
    ></dx-button>
  </dx-grid>
  <ng-template #slottedButtons>
    <dx-button
      *ngFor="let buttonConfig of modalButtons"
      [label]="buttonConfig.label"
      [slot]="buttonConfig.slot"
      [id]="buttonConfig.id"
      [disabled]="buttonConfig.disabled ?? false"
      [type]="buttonConfig.type"
      [icon]="buttonConfig.icon ?? null"
      (click)="buttonConfig.clickFn()"
    ></dx-button>
  </ng-template>
</dx-modal>

<dx-alert
  #unexpectedError
  id="bucket-error-alert"
  type="error"
  icon="warndreieck"
  titlestring="Achtung"
  content="Es ist ein unerwarteter Fehler aufgetreten. Schließen Sie den Dialog und probieren Sie es in einigen Minuten erneut."
>
  <dx-button
    id="bucket-error-ok"
    type="primary-s"
    slot="action"
    label="Okay"
    (click)="closeDialogWithReason('CANCELED')"
  ></dx-button>
</dx-alert>
