import { Injectable } from '@angular/core';
import { BackendConfigObject, ConfigOptions } from '../model/config-options.model';
import { ModalButtonConfig } from '../model/modal-button-config.model';
import { BehaviorSubject } from 'rxjs';
import { UploadService } from '../upload.service';
import { FinalizedCloseReason, UserContext } from '../model/events';
import { BucketAccessInfo } from '../model/buckets.model';
import { BucketsHttpService } from './buckets-http.service';
import { Stage } from '../stage/stage';
import { Kunde } from '../model/kunde.model';
import { KundenHttpService } from './kunden-http.service';
import { take } from 'rxjs/operators';
import { Payload } from '../model/payload';
import { ApplicationInsightsService } from '../../../../../src/app/services/application-insights.service';

@Injectable({
  providedIn: 'root'
})
export class UploadDialogService {
  private readonly dummyButton: ModalButtonConfig = {
    label: '',
    slot: 'secondary-actions',
    id: 'dummy',
    type: 'text',
    clickFn: null
  };
  private backendConfigOptions: BackendConfigObject;

  private modalTitelSubject = new BehaviorSubject<string>('Dokumente hochladen');
  public modalTitle$ = this.modalTitelSubject.asObservable();

  private modalButtonsSubject = new BehaviorSubject<ModalButtonConfig[]>([this.dummyButton]);
  public modalButtons$ = this.modalButtonsSubject.asObservable();

  uploadConfigOptions: ConfigOptions = {};
  /**
   * Enthält die gültigen Kombinationen von Input-Parametern für die verschiedenen Dialoge. Es gibt noch weitere gültige
   * Kombinationen, aber die sind hier nicht aufgeführt, da sie bis dato von keinem Konsumenten benötigt werden
   *
   * Ausgehend von den Parametern 'kundennr', 'haushaltsId', 'useGenericCustomer', 'documentUploadEndpoint' und 'directExtractionType'
   * Da es bei der FotoKomponente und den UploadFileDialog keine HaushaltsId gibt, wird diese nicht berücksichtigt
   * x = Parameter ist gesetzt, o = Parameter ist nicht gesetzt
   * @private
   */
  private inputParamsValidationMap: { [key: string]: string[] } = {
    // kundenNr, haushaltsId, useGenericCustomer, documentUploadEndpoint,
    TOOL_DIALOG: [
      'ooooo',
      'xoooo',
      'oxooo',
      'xxooo',
      'xxoxo',
      'oxoxo',
      'xooxo',
      'ooxxo',
      'xooox',
      'oxoox',
      'xxoox',
      'ooxox',
      'xoxox',
      'oxxox',
      'xxxox',
      'xooxx',
      'oxoxx',
      'xxoxx',
      'ooxxx',
      'xoxxx',
      'oxxxx',
      'xxxxx'
    ],
    FILE_FOTO_DIALOG: ['xooo', 'xoxo', 'oxxo', 'xxxo', 'xoox', 'oxox', 'xxox', 'xoxx', 'oxxx', 'xxxx']
  };

  private legalDirectExtractionTypes = ['KFZ', 'PERSO_RUECKSEITE', 'PERSO_VORDERSEITE'];

  readonly defaultDTAuftragConfigOptions: ConfigOptions = {
    useGenericCustomer: false,
    documentUploadEndpoint: null,
    maxCountOfDocuments: 39,
    mergeToPdfByDefault: false,
    showMergeToPdfToggle: true,
    checkEWE: true,
    acceptedFileTypes: ['image', 'pdf'],
    resultFileSizeInMByte: 100,
    userContext: 'VB',
    convertToPdfBeforeUpload: true,
    convertToGrayScale: false,
    directExtractionType: null,
    forceCropping: false
  };

  readonly acceptedFileTypesMapping: Map<string, string> = new Map<string, string>([
    ['image', 'image/*'],
    ['pdf', 'application/pdf']
  ]);

  constructor(
    private uploadService: UploadService,
    private bucketsHttpService: BucketsHttpService,
    private kundenHttpService: KundenHttpService,
    private stage: Stage,
    private appinsightsService: ApplicationInsightsService
  ) {
    this.uploadConfigOptions = { ...this.defaultDTAuftragConfigOptions };
  }

  isLegalDirectExtractionType(type: string): boolean {
    return this.legalDirectExtractionTypes.includes(type);
  }

  initConfigOptions(configOptions?: ConfigOptions): ConfigOptions {
    const unmappedAcceptedFileTypes =
      configOptions.acceptedFileTypes && configOptions.acceptedFileTypes.length > 0
        ? configOptions.acceptedFileTypes
        : Array.from(this.acceptedFileTypesMapping.keys());

    const mappedAcceptedFileTypes = unmappedAcceptedFileTypes
      .filter(fileType => this.acceptedFileTypesMapping.has(fileType))
      .map(fileType => this.acceptedFileTypesMapping.get(fileType));

    this.uploadConfigOptions = {
      ...this.defaultDTAuftragConfigOptions,
      ...configOptions,
      acceptedFileTypes: mappedAcceptedFileTypes
    };

    if (!this.uploadConfigOptions.documentUploadEndpoint) {
      this.uploadConfigOptions.checkEWE = true;
    }

    console.log('Finished init configOptions', this.uploadConfigOptions);
    return this.uploadConfigOptions;
  }

  private isValidDirectExtractionType(type: string): boolean {
    if (type && !this.isLegalDirectExtractionType(type)) {
      console.error('Falscher Wert für directExtractionType: ', type);
      return false;
    }
    return true;
  }

  private isValidResultFileSize(size: number): boolean {
    if (size === null) {
      console.error('resultFileSizeInMByte darf nicht null sein');
      return false;
    }
    if (size > 100) {
      console.error('resultFileSizeInMByte darf nicht größer als 100 MB sein');
      return false;
    }
    return true;
  }

  private isValidAcceptedFileTypes(fileTypes: string[]): boolean {
    if (Array.isArray(fileTypes) && fileTypes.some(fileType => !this.acceptedFileTypesMapping.has(fileType))) {
      console.error(
        `Es werden nur die FileTypes ${Array.from(this.acceptedFileTypesMapping.keys()).join(' , ')} akzeptiert.`
      );
      return false;
    }
    return true;
  }

  private isValidEndkundeContext(configOptions: ConfigOptions, kundennr?: string, haushaltsId?: string): boolean {
    const isEndkunde = configOptions.userContext === UserContext.ENDKUNDE;
    if ((isEndkunde && configOptions.useGenericCustomer) || (isEndkunde && !kundennr) || (isEndkunde && haushaltsId)) {
      console.error('Endkunde kann nicht ohne Kundennummer oder in Kombination mit eine HaushaltsID verwendet werden');
      return false;
    }
    return true;
  }

  validateInputParameters(
    validaionForDialog: 'TOOL_DIALOG' | 'FILE_FOTO_DIALOG',
    configOptions?: ConfigOptions,
    kundennr?: string,
    haushaltsId?: string
  ): {
    status: 'OK' | 'ERROR';
    message?: string;
  } {
    const validInputParameterCombinations = this.inputParamsValidationMap[validaionForDialog];
    let inputParams = kundennr ? 'x' : 'o';
    if (validaionForDialog === 'TOOL_DIALOG') {
      inputParams += haushaltsId ? 'x' : 'o';
    }
    inputParams += configOptions.useGenericCustomer ? 'x' : 'o';
    inputParams += configOptions.documentUploadEndpoint ? 'x' : 'o';
    inputParams += configOptions.directExtractionType ? 'x' : 'o';

    if (!this.isValidDirectExtractionType(configOptions.directExtractionType)) {
      return {
        status: 'ERROR',
        message: `Dialog was opened with invalid input parameter: '${configOptions.directExtractionType}' is not allowed.`
      };
    }

    if (!this.isValidResultFileSize(configOptions.resultFileSizeInMByte)) {
      return {
        status: 'ERROR',
        message:
          'Dialog was opened with invalid input parameter: resultFileSizeInMByte is null or exceeds maximum of 100 MB'
      };
    }

    if (!validInputParameterCombinations.includes(inputParams)) {
      console.error('Falsche Kombination von Input-Parametern: ', inputParams);
      return { status: 'ERROR', message: 'Dialog was opened with invalid combination of input parameters.' };
    }

    if (!this.isValidAcceptedFileTypes(configOptions.acceptedFileTypes)) {
      return {
        status: 'ERROR',
        message: `AcceptedFileTypes can only contain ${Array.from(this.acceptedFileTypesMapping.keys()).join(' , ')}.`
      };
    }

    if (!this.isValidEndkundeContext(configOptions, kundennr, haushaltsId)) {
      return {
        status: 'ERROR',
        message: `The customer context "ENDKUNDE" can not be used without KundenNummer or in combination with HaushaltsId`
      };
    }

    return { status: 'OK' };
  }

  /**
   * Führt ein Mapping der ConfigOptions auf ein BackendConfigObject durch
   * @param valideKundennummer (optional) - Sollte nur gesetzt werden, wenn die Kundennummer validiert wurde
   * @private
   */
  private mapConfigOptionsToBackendConfigObject(valideKundennummer?: string): BackendConfigObject {
    return <BackendConfigObject>{
      kundennummer: this.uploadConfigOptions.useGenericCustomer ? null : valideKundennummer,
      documentUploadEndpoint:
        this.uploadConfigOptions.documentUploadEndpoint?.length > 0
          ? this.uploadConfigOptions.documentUploadEndpoint
          : null,
      checkEWE: this.uploadConfigOptions.useGenericCustomer ? false : this.uploadConfigOptions.checkEWE,
      maxCountOfDocuments:
        !this.uploadConfigOptions.showMergeToPdfToggle && this.uploadConfigOptions.mergeToPdfByDefault
          ? 1
          : this.uploadConfigOptions.maxCountOfDocuments,
      allowOnlyOneImagePerDocument:
        this.uploadConfigOptions.maxCountOfDocuments === 1 &&
        !this.uploadConfigOptions.mergeToPdfByDefault &&
        !this.uploadConfigOptions.showMergeToPdfToggle,
      resultFileSizeInMByte: this.uploadConfigOptions.resultFileSizeInMByte,
      convertToPdfBeforeUpload: this.uploadConfigOptions.convertToPdfBeforeUpload,
      convertToGrayScale:
        this.uploadConfigOptions.documentUploadEndpoint?.length > 0
          ? this.uploadConfigOptions.convertToGrayScale
          : false,
      adiModel: this.uploadConfigOptions.directExtractionType ?? null,
      forceCropping: this.uploadConfigOptions.forceCropping ?? false
    };
  }

  showModalButtons(buttons: ModalButtonConfig[]) {
    this.modalButtonsSubject.next(buttons ?? [this.dummyButton]);
  }

  setModalTitle(headline: string) {
    this.modalTitelSubject.next(headline);
  }

  resetService() {
    this.uploadConfigOptions = { ...this.defaultDTAuftragConfigOptions };
    this.backendConfigOptions = undefined;
    this.modalButtonsSubject.next([this.dummyButton]);
    this.setModalTitle('Dokumente hochladen');
  }

  async createBucket(kundennr?: string): Promise<BucketAccessInfo> {
    this.backendConfigOptions = this.mapConfigOptionsToBackendConfigObject(kundennr);
    return new Promise<BucketAccessInfo>((resolve, reject) => {
      if (
        !this.backendConfigOptions.kundennummer &&
        !this.backendConfigOptions.documentUploadEndpoint &&
        !this.backendConfigOptions.adiModel
      ) {
        const createBucketError = new Error(
          'Beim Erstellen des Buckets ist ein Fehler aufgetreten. Die Kombination von keine Kundennummer und keinem Endpunkt ist nicht erlaubt. BackendConfig: ' +
            this.anonymizeBackendConfigForLogging()
        );
        this.appinsightsService.logError('UploadDialogService', createBucketError);
        throw createBucketError;
      }

      this.bucketsHttpService
        .erstelleBucket(this.backendConfigOptions)
        .pipe(take(1))
        .subscribe({
          next: bucket => {
            resolve(bucket);
          },
          error: () => {
            console.warn('Beim Erstellen des Buckets ist ein Fehler aufgetreten: ', this.backendConfigOptions);
            reject(null);
          }
        });
    });
  }

  uploadDokument(payloads: Payload[], bucketId: string, mergeToPdf: boolean) {
    // call startUpload
    console.log(payloads);
    this.uploadService.startUpload(bucketId, payloads, mergeToPdf, this.backendConfigOptions);
    this.resetService();
  }

  closeUploadProcess(reason: FinalizedCloseReason, errormessage?: string) {
    const backendConfig = this.backendConfigOptions ?? {};
    this.resetService();
    console.log('closeUploadProcess: ', reason, errormessage);
    this.uploadService.closeUploadProcess(backendConfig, reason, errormessage);
  }

  ladeKunde(kundennummer: string): Promise<Kunde> {
    return new Promise<Kunde>((resolve, reject) => {
      this.kundenHttpService
        .ladeKunde(kundennummer)
        .pipe(take(1))
        .subscribe({
          next: kunde => {
            console.log('Kunde wurde geladen: ', kunde);
            if (!this.uploadConfigOptions.checkEWE) {
              kunde.eweZugestimmt = true;
            }
            resolve(kunde);
          },
          error: error => {
            console.log('Fehler beim Laden des Kunden: ', error);
            reject();
          }
        });
    });
  }

  private anonymizeBackendConfigForLogging(): string {
    return JSON.stringify({
      ...this.backendConfigOptions,
      kundennummer: this.backendConfigOptions.kundennummer ? 'xxxxxxxx' : null
    });
  }
}
