import { Component, OnInit } from '@angular/core';
import { PwaUpdateService } from '../../services/pwa-update.service';

@Component({
  selector: 'app-update-pwa',
  templateUrl: './update-pwa.component.html',
  styleUrls: ['./update-pwa.component.scss'],
  standalone: false
})
export class UpdatePwaComponent implements OnInit {
  constructor(private pwaUpdateService: PwaUpdateService) {}

  ngOnInit(): void {
    this.pwaUpdateService.canReloadAfterUpdate();
  }
}
