import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuftragBearbeitenService } from '../../services/auftrag-bearbeiten.service';
import { KundenzuordnungService } from './service/kundenzuordnung.service';
import { Auftrag } from '../../../../store/models/auftrag.model';
import { AuftragStatus } from '../../../../enums/auftrag.enums';
import { NotificationService, NotificationTyp } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from '../../../../services/application-insights.service';
import { Haushaltsmitglied } from './model/haushaltsmitglied.model';
import { DxAlert } from '@dvag/design-system-angular';
import { EinkommenService } from '../../../formular/basis-unterformular/basis-einkommen-unterformular/services/einkommen.service';
import { HaushaltService } from '../../services/haushalt.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-kundenzuordnung',
  templateUrl: './kundenzuordnung.component.html',
  styleUrls: ['./kundenzuordnung.component.scss'],
  standalone: false
})
export class KundenzuordnungComponent implements OnInit {
  @ViewChild('kundenZuordnungAlert') kundenZuordnungAlert: DxAlert;

  @Input() auftragBearbeitungsStatus: AuftragStatus;
  @Output() kundenZuordnungChanged = new EventEmitter<Auftrag>();

  anzuzeigendeHhMitglieder: Haushaltsmitglied[] = [];
  mitgliederOhneEweVorhanden = false;
  auftragBearbeitungEnum = AuftragStatus;
  loadingHaushalte = false;
  changingZugeordneterKunde = false;
  kundenauswahlKundennummer: string;

  protected readonly AuftragStatus = AuftragStatus;

  constructor(
    private haushaltService: HaushaltService,
    private kundeService: KundenzuordnungService,
    public auftragBearbeitenService: AuftragBearbeitenService,
    private notificationService: NotificationService,
    private router: Router,
    private applicationInsightsService: ApplicationInsightsService,
    private einkommenService: EinkommenService
  ) {}

  ngOnInit(): void {
    if (this.auftragBearbeitungsStatus === AuftragStatus.BEARBEITUNG) {
      this.loadingHaushalte = true;
      this.haushaltService.kumulierteHaushaltsMitglieder$.subscribe({
        next: (haushaltsmitglieder: Haushaltsmitglied[]) => {
          this.prepareHaushaltsMitgliederForDisplay(haushaltsmitglieder);
        },
        error: () => {
          this.notificationService.showNotification(
            'Es ist ein Fehler aufgetreten',
            'Der Haushalt konnte nicht geladen werden. Sie werden zurück auf das Dashboard navigiert',
            5,
            NotificationTyp.achtung
          );
          setTimeout(() => this.router.navigate(['/']), 6000);
          return;
        }
      });
    }
  }

  prepareHaushaltsMitgliederForDisplay(originHaushaltsmitglieder: Haushaltsmitglied[]) {
    // Kunden ohne EWE, sollen nicht angezeigt werden
    this.mitgliederOhneEweVorhanden = originHaushaltsmitglieder.some(mitg => !mitg.eweZugestimmt);

    const mitgliederMitEwe = originHaushaltsmitglieder.filter(mitg => mitg.eweZugestimmt);

    const zugeordneterKundeIstInMehrerenHaushalten =
      mitgliederMitEwe.filter(result => result.kundenNummer == this.auftragBearbeitenService.auftrag.kundenNummer)
        .length > 1;

    // Wenn ein zugeordneter Kunde mehrmals vorkommt, soll die Adresse nicht angezeigt werden
    const zugeordneterKunde = mitgliederMitEwe
      .filter(mitglied => mitglied.kundenNummer == this.auftragBearbeitenService.auftrag.kundenNummer)
      .splice(0, 1)
      .map(mitglied => {
        if (zugeordneterKundeIstInMehrerenHaushalten) {
          return { ...mitglied, adresse: '' };
        } else {
          return mitglied;
        }
      });
    const restlicheHaushaltMitglieder = mitgliederMitEwe.filter(
      mitglied => mitglied.kundenNummer !== this.auftragBearbeitenService.auftrag.kundenNummer
    );
    this.kundenauswahlKundennummer = this.auftragBearbeitenService.auftrag.kundenNummer;
    this.anzuzeigendeHhMitglieder = [...zugeordneterKunde, ...restlicheHaushaltMitglieder];
    this.loadingHaushalte = false;
  }

  updateKundenNummerOnAuftrag(selectedKundenNummer: string) {
    this.changingZugeordneterKunde = true;
    const auftragId = this.auftragBearbeitenService.auftrag?.auftragId;
    this.kundeService
      .updateKundenNummerOnAuftrag(auftragId, selectedKundenNummer)
      .pipe(take(1))
      .subscribe({
        next: (auftrag: Auftrag) => {
          this.changingZugeordneterKunde = false;
          this.kundenZuordnungChanged.emit(auftrag);
          if (this.auftragBearbeitenService.auftrag.vertraege[0].formularId === 'GEHALTSNACHWEIS') {
            this.einkommenService.fetchEinkommen(selectedKundenNummer);
          }
          this.applicationInsightsService.logKundenzuordnungChanged();
        },
        error: err => {
          this.changingZugeordneterKunde = false;
          this.kundenauswahlKundennummer = this.auftragBearbeitenService.auftrag?.kundenNummer;
          this.showSimpleSplitscreenErrorAlert();
          console.warn('KundeComponent: Fehler bei der Kundenzuordnung. ', err);
        }
      });
  }

  showSimpleSplitscreenErrorAlert() {
    this.kundenZuordnungAlert.visible = true;
  }
}
