import {
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AuftragBearbeitenService } from '../../services/auftrag-bearbeiten.service';
import { SyncStatus, Vertrag } from '../../../../store/models/vertrag.model';
import { filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AuftragStatus } from '../../../../enums/auftrag.enums';
import { Formular } from '../../../../store/models/formular.model';
import { FormBuilder } from '@angular/forms';
import { FormularListService } from '../../../formular/services/formular-list.service';
import { VertragService } from './service/vertrag.service';
import { VertragsFormularComponent } from '../vertrags-formular/vertrags-formular.component';
import { FremdvertraegeService, FremdvertragsAuswahl } from '../../../formular/services/fremdvertraege.service';
import { AktivesEingabefeldHandlerService } from '../../../formular/basis-input-formular-feld/services/aktives-eingabefeld-handler.service';
import { DxAlert, DxCombobox } from '@dvag/design-system-angular';
import { Router } from '@angular/router';

interface ComboboxCategory {
  /* The name or label of the category. If it is null or empty no category box will be displayed within the flyout. */
  label?: string;
  /* The options which should be displayed for this category */
  options: ComboboxOption[];
}
interface ComboboxOption {
  label: string;
  data: string;
}

@Component({
  selector: 'app-vertrag',
  templateUrl: './vertrag.component.html',
  styleUrls: ['./vertrag.component.scss'],
  providers: [VertragService],
  standalone: false
})
export class VertragComponent implements OnInit, OnChanges, OnDestroy {
  private vertragInBearbeitungSub: Subscription;
  private vertraegeSub: Subscription;
  private angabenSub: Subscription;
  private fetchFremdvertragOptionSub: Subscription;
  isOpen = false;
  anzahlSucheintraege = 10;
  anzahlVertraege = 1;
  anzahlVertraegeMitDokumententyp = 1;
  showFormularSearch = true;
  syncStatus = SyncStatus;

  dokumentTypInputValue: any;
  ausgewaehltesFormular: Formular;
  initialAngaben: any;
  private selectedFormularId: string = null;

  private readonly initFremdvertragOption = 'neuer_fremdvertrag';
  public selectedFremdvertragOption = this.initFremdvertragOption;

  private readonly neuerFremdvertragDisplayOption = 'Neu erfasster Fremdvertrag';
  public selectedFremdvertragDisplayOption = '';

  private formularListe$ = this.formularListService.formularListe$.pipe(
    filter(list => list.length > 0),
    take(1)
  );

  @Input() vertrag: Vertrag;

  auftragBearbeitungEnum = AuftragStatus;
  @Input() auftragBearbeitungsStatus: AuftragStatus;
  requestingDataFromBackend = false;
  @ViewChild('sucheformulartyp') dokumentTypSuchefeld: DxCombobox;
  @ViewChild('vertragloeschen') vertragLoeschenAlert: DxAlert;
  @ViewChild('fremdvertragalert') fremdvertragAlert: DxAlert;
  @ViewChild(VertragsFormularComponent)
  vertragsFormularComponent: VertragsFormularComponent;
  private vertraegeInCommunicationWithBackendSub: Subscription;
  public kundenNummer: string = null;
  public fvOptionen: FremdvertragsAuswahl[] = [];

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.calculateVisibleEntriesOfDsSearch();
  }

  constructor(
    private fb: FormBuilder,
    public auftragBearbeitungService: AuftragBearbeitenService,
    private vertragService: VertragService,
    private formularListService: FormularListService,
    private fremdvertraegeService: FremdvertraegeService,
    private aktivesEingabefeldHandlerService: AktivesEingabefeldHandlerService,
    private router: Router,
    @Inject('window') private window: Window
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.vertrag &&
      JSON.stringify(changes.vertrag.previousValue) !== JSON.stringify(changes.vertrag.currentValue)
    ) {
      this.formularListe$.pipe(take(1)).subscribe(formulare => {
        this.ausgewaehltesFormular = formulare.find(formular => formular.id === this.vertrag?.formularId);
        this.auftragBearbeitungService.setFormularNachbarbeitungToVertrag(
          this.vertrag.vertragId,
          this.ausgewaehltesFormular?.nachbearbeitung
        );
      });

      this.fvOptionen = this.vertrag?.kiFremdvertragOptionen;
      setTimeout(() => {
        this.selectedFremdvertragOption = this.vertrag.kiFremdvertragId ?? this.initFremdvertragOption;
      }, 50);

      if (this.hasRelevantDataForAngabenChanged(changes.vertrag.previousValue, changes.vertrag.currentValue)) {
        this.dokumentTypInputValue = this.vertrag.formularName ?? '';
        this.auftragBearbeitungService.addVertragToLadeAngaben(this.vertrag.vertragId);
        this.vertragService.loadAngabenForSelection(
          this.auftragBearbeitungService.auftrag.auftragId,
          this.vertrag.vertragId
        );
      }
      this.kundenNummer = this.auftragBearbeitungService.auftrag.kundenNummer;
      if (!this.vertrag.kiFremdvertragId) {
        this.selectedFremdvertragDisplayOption = this.neuerFremdvertragDisplayOption;
        return;
      }
      this.handleFremdvertragAuswahlOption();
    }
  }

  ngOnInit(): void {
    this.calculateVisibleEntriesOfDsSearch();
    this.selectedFormularId = this.vertrag?.formularId;
    this.vertragInBearbeitungSub = this.auftragBearbeitungService.vertragInBearbeitung$.subscribe(vertragId => {
      this.isOpen = vertragId === this.vertrag?.vertragId;
      this.checkForDisplayOfFormularSearch();
    });
    this.angabenSub = this.vertragService.angaben$.subscribe(angaben => {
      this.initialAngaben = angaben;
      this.auftragBearbeitungService.removeVertragFromLadeAngaben(this.vertrag?.vertragId);
      this.requestingDataFromBackend = false;
    });
    this.vertraegeSub = this.auftragBearbeitungService.vertraege$.subscribe(_ => {
      this.anzahlVertraege = this.auftragBearbeitungService.getAnzahlVertraege();
      this.anzahlVertraegeMitDokumententyp = this.auftragBearbeitungService.getAnzahlVertraegeMitFormularId();
    });
    this.vertraegeInCommunicationWithBackendSub =
      this.auftragBearbeitungService.vertraegeInCommunicationWithBackend$.subscribe(vertraegIds => {
        this.requestingDataFromBackend = vertraegIds.some(vertragId => vertragId === this.vertrag?.vertragId);
      });
  }

  ngOnDestroy(): void {
    this.vertragInBearbeitungSub?.unsubscribe();
    this.vertraegeSub?.unsubscribe();
    this.angabenSub?.unsubscribe();
    this.fetchFremdvertragOptionSub?.unsubscribe();
    this.vertraegeInCommunicationWithBackendSub?.unsubscribe();
  }

  async deleteVertrag(event) {
    event.stopPropagation();
    if (this.isVertragHuelle() || !this.hasVertragUnsavedData()) {
      this.auftragBearbeitungService.deleteVertrag(this.vertrag?.vertragId);
      return;
    } else {
      this.vertragLoeschenAlert.visible = true;
    }
  }

  private handleFremdvertragAuswahlOption() {
    if (
      this.auftragBearbeitungsStatus === this.auftragBearbeitungEnum.BEARBEITUNG &&
      this.vertrag.syncStatus === this.syncStatus.EINGESTELLT
    ) {
      this.selectedFremdvertragDisplayOption =
        this.vertrag.kiFremdvertragOptionen?.find(option => option.kiFremdvertragId === this.vertrag.kiFremdvertragId)
          ?.displayOption ?? '';
    } else {
      this.fetchFremdvertragOptionSub = this.fremdvertraegeService
        .fetchFremdvertragAuswahlOption(
          this.auftragBearbeitungService.auftrag.kundenNummer,
          this.vertrag.kiFremdvertragId
        )
        .subscribe({
          next: value => {
            this.selectedFremdvertragDisplayOption = value.displayOption;
          },
          error: error => {
            console.error('Error fetching Fremdvertrag option:', error);
            if (this.auftragBearbeitungsStatus !== this.auftragBearbeitungEnum.READONLY) {
              this.fremdvertragAlert.visible = true;
            } else {
              this.selectedFremdvertragDisplayOption = 'Fremdvertrag im DVAG Online System bereits gelöscht';
            }
          }
        });
    }
  }

  onFocusDokumentTypSuche(event) {
    if (!this.dokumentTypSuchefeld) {
      this.aktivesEingabefeldHandlerService.aktivesEingabefeld = null;
      return;
    }
    this.aktivesEingabefeldHandlerService.aktivesEingabefeld = {
      setText: (text: string) => {
        this.dokumentTypSuchefeld.value = text;
        this.dokumentTypSuchefeld.focusControl();
      },
      focus: () => {
        this.dokumentTypSuchefeld.focusControl();
      }
    };
  }

  /**
   * Change the formular if selected document type is not the same to prev value
   * @param event
   */
  onDokumentTypAuswahl(event: CustomEvent) {
    if (this.selectedFormularId !== event.detail?.data) {
      this.formularListe$.subscribe(formulare => {
        const searchedFormular = formulare.find(formular => formular.name === event.detail?.data);
        if (
          !searchedFormular ||
          (this.auftragBearbeitungService.isBuendelVertrag() && searchedFormular.buendelvertragFaehig === false)
        ) {
          return;
        }
        if (!this.vertrag.formularId) {
          this.auftragBearbeitungService.addVertrag(this.vertrag.vertragId, searchedFormular.id);
          return;
        }

        if (searchedFormular.id !== this.vertrag.formularId) {
          this.changeFormular(searchedFormular);
        }
      });
    }
  }

  /**
   * Filter the list of the document type select by the given search value
   * @param event
   */
  onDokumentTypSuche(event: CustomEvent<any>) {
    const searchEvent = event.detail;
    console.log(event.detail);

    this.formularListe$.subscribe(formulare => {
      // Create a new array of formulare and map them to a new array
      const selectOptions = formulare
        .filter(formular => !this.auftragBearbeitungService.isBuendelVertrag() || formular.buendelvertragFaehig)
        .map(formular => ({
          id: formular.id,
          label: formular.name,
          category: formular.kategorie,
          data: formular.name
        }));

      //console.log('Select Options:', selectOptions)

      const searchValue = searchEvent.value?.trim().toLowerCase() ?? '';

      let filteredSelectOptions = selectOptions;
      if (searchValue.length > 0) {
        filteredSelectOptions = selectOptions.filter(
          it => it.label.toLowerCase().includes(searchValue) || it.category?.toLowerCase().includes(searchValue)
        );
      }
      //console.log('Filtered Select Options:', filteredSelectOptions)

      // Group the filtered options by category
      const categoriesMap = filteredSelectOptions.reduce((acc, option) => {
        if (!acc[option.category]) {
          acc[option.category] = [];
        }
        acc[option.category].push({ label: option.label, data: option.data });
        return acc;
      }, {});

      // Convert the categories map to an array of categories, each with a label and an array of options
      const categoriesResult: ComboboxCategory[] = Object.entries(categoriesMap).map(
        ([label, options]: [string, ComboboxOption[]]) => ({ label, options })
      );

      // Provide the categories result to the combobox component to display the search results
      searchEvent.provideValues(categoriesResult);
    });
  }

  private changeFormular(formular: Formular): void {
    this.auftragBearbeitungService.changeFormularForVertrag(
      this.vertrag?.vertragId,
      formular.id,
      formular.name,
      formular.nachbearbeitung
    );
    this.ausgewaehltesFormular = formular;
  }

  updateVertragWithFremdvertrag(selectedFremdvertragOption: string) {
    let fvOption = selectedFremdvertragOption;

    if (selectedFremdvertragOption === null) {
      this.selectedFremdvertragOption = this.initFremdvertragOption;
    }

    if (selectedFremdvertragOption === this.initFremdvertragOption) {
      fvOption = null;
    }

    if (fvOption !== this.vertrag.kiFremdvertragId) {
      this.auftragBearbeitungService.changeKiFremdvertragForVertrag(this.vertrag?.vertragId, fvOption);
    }
  }

  private calculateVisibleEntriesOfDsSearch(): void {
    const height = this.window.innerHeight;
    if (height < 800) {
      this.anzahlSucheintraege = 6;
      return;
    }

    if (height < 1000) {
      this.anzahlSucheintraege = 8;
      return;
    }

    if (height < 1200) {
      this.anzahlSucheintraege = 10;
      return;
    }
    if (height >= 1200) {
      this.anzahlSucheintraege = 12;
      return;
    }
  }

  checkForDisplayOfFormularSearch() {
    const isInBearbeitung = this.auftragBearbeitungsStatus === this.auftragBearbeitungEnum.BEARBEITUNG;
    const isNotSyncedSuccessful = this.vertrag?.syncStatus !== SyncStatus.SYNCHRONISIERT;
    const cardIsOpen = this.isOpen;
    this.showFormularSearch = isInBearbeitung && isNotSyncedSuccessful && cardIsOpen;
  }

  toggleVertrag() {
    if (this.auftragBearbeitungService.getAnzahlVertraege() === 1) {
      return;
    }
    this.auftragBearbeitungService.toggleVertrag(this.vertrag?.vertragId);
  }

  onlyToggleVertragWhenNotOpen(event) {
    event.stopPropagation();
    if (this.isOpen) {
      return;
    }
    this.auftragBearbeitungService.toggleVertrag(this.vertrag?.vertragId);
  }

  private hasRelevantDataForAngabenChanged(previousVertrag: Vertrag, currentVertrag: Vertrag): boolean {
    if (this.kundenNummer && this.auftragBearbeitungService.auftrag.kundenNummer !== this.kundenNummer) {
      return true;
    }
    if (previousVertrag?.formularId !== currentVertrag?.formularId) {
      return true;
    }
    return previousVertrag?.kiFremdvertragId !== currentVertrag?.kiFremdvertragId;
  }

  getAngabenToVertrag(): any {
    return this.vertragsFormularComponent.getAngaben();
  }

  hasVertragUnsavedData(): boolean {
    // Vergleich der Angaben über JSON.stringify wäre zum Vergleich einfacher, aber dann dürfen
    // die Einrträge im Bemerkungsfeld in der Reihenfolge nicht vertrauscht werden.
    if (this.requestingDataFromBackend) {
      return false;
    }

    return this.vertragsFormularComponent?.containsUnsavedData();
  }

  private isVertragHuelle(): boolean {
    return !this.vertragsFormularComponent;
  }

  onFormValidityChanged(isValid: boolean) {
    this.auftragBearbeitungService.changeValidityStateOfVertrag(this.vertrag.vertragId, isValid);
  }

  onCreateNewFremdvertrag() {
    this.fremdvertragAlert.visible = false;
    this.updateVertragWithFremdvertrag(this.initFremdvertragOption);
  }

  async navigateToDashboard() {
    await this.router.navigate(['/dashboard']);
  }
}
