import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LeistungsradarResult } from '../../../../../../projects/leistungsradar/src/dt/model/leistungsradarresult.model';
import { filter } from 'rxjs/operators';
import { BasisInputFormularFeld } from '../basis-input-formular-feld';
import { LeistungsradarVergleichsoptionen } from '../../../../store/models/leistungsradar-vergleichsoptionen.model';
import { Subscription } from 'rxjs';
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service';
import { LeistungsradarService } from './service/leistungsradar.service';
import { Stage } from '../../../../../../projects/upload-components/src/lib/stage/stage';
import { ApplicationInsightsService } from '../../../../services/application-insights.service';

interface Anzeige {
  text: string;
  fieldname: string;
  bindewort: string;
}

@Component({
  selector: 'app-leistungsradar',
  templateUrl: './leistungsradar.component.html',
  styleUrls: ['./leistungsradar.component.scss'],
  standalone: false
})
export class LeistungsradarComponent extends BasisInputFormularFeld implements OnInit, OnDestroy {
  abhaengigkeitenChangeSub: Subscription;
  keinVergleichVerfuebar = false;
  isCalculated = false;
  anzeigeText: 'wertefehlen' | 'keinvergleichvorhanden' | 'ladevergleich' | 'vergleichexistiert' = 'wertefehlen';
  leistungsVergleich: LeistungsradarResult = null;
  abhaenigkeitenAnzeigetexte: Anzeige[] = [];

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    private leistungsradarService: LeistungsradarService,
    private stage: Stage,
    private applicationInsightsService: ApplicationInsightsService
  ) {
    super(fb, focusedControlHandlerService);
  }

  ngOnInit() {
    const abhaengigkeiten = this.field.abhaengigkeiten ?? [];

    this.abhaengigkeitenChangeSub = this.formularFeldDictionaryService
      .getCompletedValues()
      .pipe(
        filter(completedValue => abhaengigkeiten.includes(completedValue.fieldName)),
        filter(
          completedAbhaengigkeiten =>
            !this.initialValue ||
            this.formularFeldDictionaryService
              .getBasisInputFormularFeld(completedAbhaengigkeiten.fieldName)
              .hasValueChanged()
        )
      )
      .subscribe(() => {
        this.onAbhaengikeitChanged();
      });

    this.abhaenigkeitenAnzeigetexte = this.convertAbhaenigkeitenAnzeigetexte(this.field.abhaengigkeiten);
  }

  ngOnDestroy() {
    if (this.abhaengigkeitenChangeSub) {
      this.abhaengigkeitenChangeSub.unsubscribe();
    }
  }

  resetValue(): void {
    this.leistungsVergleich = null;
    this.updateFormField(null);
  }

  focusFormControl(name: string): void {
    const abstractControl = this.mainFormularGroup.get(name);
    if (abstractControl) {
      const nativeElement = (abstractControl as any).nativeElement;

      if (nativeElement.focus) {
        nativeElement.focus();
      }
      if (nativeElement.focusControl) {
        nativeElement.focusControl();
      }
    }
  }

  openLeistungsradarInNewTab(bereichsId: number, tarifId: number, sparteId: number): void {
    if (this.stage.getStage() === 'produktion') {
      window.open(
        `https://leistungsradar.prod.lradar.aws.generali-cloud.net/benchmark/lv?insurance_area=${bereichsId}&tariff=${tarifId}&sub_division=${sparteId}&idpHint=dvag-keycloak-prod`,
        '_blank'
      );
    } else {
      window.open(
        `https://leistungsradar.qual.lradar.aws.generali-cloud.net/benchmark/lv?insurance_area=${bereichsId}&tariff=${tarifId}&sub_division=${sparteId}&idpHint=dvag-keycloak-qual`,
        '_blank'
      );
    }
    this.applicationInsightsService.logLeistungsradarLinkClicked(bereichsId, tarifId, sparteId);
  }

  openMarktForschung(): void {
    if (this.stage.getStage() === 'produktion') {
      window.open(
        'https://leistungsradar.prod.lradar.aws.generali-cloud.net/compare_info_standalone',
        '_blank',
        'noopener'
      );
    } else {
      window.open(
        'https://leistungsradar.qual.lradar.aws.generali-cloud.net/compare_info_standalone',
        '_blank',
        'noopener'
      );
    }
  }

  capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  override hasValueChanged(): boolean {
    return false;
  }

  override setInitialValue(value: any) {
    this.initialValue = value?.wert;
    if (this.initialValue) {
      this.leistungsradarService.fetchLeistungsVergleich(this.initialValue).subscribe(leistungsradarResult => {
        this.handleLeistungsradarResut(leistungsradarResult);
      });
    }
  }

  private onAbhaengikeitChanged(): void {
    const eingaben = new Map<string, any>(
      this.field.abhaengigkeiten.map(abhaengigkeit => [abhaengigkeit, this.mainFormularGroup.get(abhaengigkeit).value])
    );

    this.isCalculated = false;
    this.resetValue();

    if (Array.from(eingaben.values()).includes(this.formularFeldDictionaryService.keineVergleichsOptionen)) {
      this.anzeigeText = 'keinvergleichvorhanden';
      return;
    }

    if (!this.alleAbhaenigkeitenAusgefuellt(eingaben)) {
      this.anzeigeText = 'wertefehlen';
      return;
    }
    if (this.isReadonly) {
      if (this.leistungsVergleich) {
        this.anzeigeText = 'vergleichexistiert';
      } else {
        this.anzeigeText = 'keinvergleichvorhanden';
      }
      return;
    }
    this.anzeigeText = 'ladevergleich';

    const vergleichsoptionen: LeistungsradarVergleichsoptionen = {
      formularId: this.formularId,
      auftragId: this.auftragId,
      gesellschaft: null,
      tarifjahr: null,
      tarifname: null
    };
    eingaben.forEach((wert, abhaengigkeit) => (vergleichsoptionen[abhaengigkeit] = wert));

    this.leistungsradarService.generateLeistungsVergleich(vergleichsoptionen).subscribe({
      next: leistungsradarResult => {
        this.handleLeistungsradarResut(leistungsradarResult);
      },
      error: err => {
        this.anzeigeText = 'keinvergleichvorhanden';
        this.isCalculated = true;
        this.resetValue();
      }
    });
  }

  private handleLeistungsradarResut(leistungsradarResult: LeistungsradarResult) {
    this.isCalculated = true;
    this.anzeigeText = leistungsradarResult ? 'vergleichexistiert' : 'keinvergleichvorhanden';
    this.leistungsVergleich = leistungsradarResult;
    this.updateFormField(leistungsradarResult.id);
  }

  private alleAbhaenigkeitenAusgefuellt(abhaengigkeitenUndWerte: Map<string, any>) {
    return Array.from(abhaengigkeitenUndWerte.values()).every(currentVal => !!currentVal);
  }

  private updateFormField(vergleichId: string) {
    this.mainFormularGroup.controls[this.field.name].patchValue(vergleichId);
  }

  private convertAbhaenigkeitenAnzeigetexte(abhaengigkeiten: string[]): Anzeige[] {
    return abhaengigkeiten.reduce((acc, cur, index) => {
      const isLetzterIndex = abhaengigkeiten.length - 1 == index;
      const isVorletzterIndex = abhaengigkeiten.length - 2 == index;

      // das letzte Paar soll mit "und" verbunden werdenn
      const bindewort = isVorletzterIndex ? ' und ' : ', ';

      const anzeige = {
        text:
          this.formularFeldDictionaryService.getBasisInputFormularFeld(cur)?.field.label ??
          this.capitalize(cur.toLowerCase()),
        fieldname: cur,
        bindewort: isLetzterIndex ? ', ' : bindewort // das letzte ", " gehört zum Folgetext der Anzeige.
      };
      return [...acc, anzeige];
    }, []);
  }
}
