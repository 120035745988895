import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DT_VERSION } from '../../version';

@Injectable({
  providedIn: 'root'
})
export class VersionInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req)) {
      return next.handle(req.clone({ setHeaders: { 'dt-version': DT_VERSION } }));
    } else {
      return next.handle(req);
    }
  }

  private isValidRequestForInterceptor(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.be.baseUrl);
  }
}
