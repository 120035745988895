import { Component, OnInit } from '@angular/core';
import { FormularHttpService, Options } from '../../../../store/services/formular.http.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { BasisFormularFeld } from '../basis-formular-feld';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  standalone: false
})
export class LinkComponent extends BasisFormularFeld implements OnInit {
  linkText = '';
  linkHref = '';
  linkLabel = '';

  constructor(private formularHttpService: FormularHttpService) {
    super();
  }

  ngOnInit() {
    this.formularHttpService
      .fetchOptions(this.auftragId, this.formularId, this.field.name)
      .pipe(
        catchError(err => {
          return of({
            linkText: '',
            linkHref: '',
            linkLabel: ''
          });
        })
      )
      .subscribe((option: Options) => {
        this.linkText = option.linkText;
        this.linkHref = option.linkHref;
        this.linkLabel = option.linkLabel;
      });
  }

  navigate(href: string) {
    if (href) {
      window.open(href, '_blank');
    }
  }
}
