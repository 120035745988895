import { Injectable } from '@angular/core';
import { AuftragBearbeitenService } from '../../../auftrag-bearbeitung/services/auftrag-bearbeiten.service';

export interface Eingabefeld {
  setText(text: string): void;
  focus(): void;
}

@Injectable({
  providedIn: 'root'
})
export class AktivesEingabefeldHandlerService {
  private _aktivesEingabefeld: Eingabefeld;

  constructor(private auftragBearbeitenService: AuftragBearbeitenService) {
    this.auftragBearbeitenService.vertraege$.subscribe(_ => {
      this._aktivesEingabefeld = null;
    });
    this.auftragBearbeitenService.vertragInBearbeitung$.subscribe(_ => {
      this._aktivesEingabefeld = null;
    });
  }

  set aktivesEingabefeld(eingabeFeld: Eingabefeld) {
    this._aktivesEingabefeld = eingabeFeld;
  }

  get aktivesEingabefeld(): Eingabefeld {
    return this._aktivesEingabefeld;
  }
}
