import { Component, computed, input } from '@angular/core';
import { FeldInfo } from '../../../store/models/formular-feld-config.model';
import { DesignSystemModule } from '@dvag/design-system-angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-input-field-info',
  standalone: true,
  imports: [DesignSystemModule],
  templateUrl: './input-field-info.component.html'
})
export class InputFieldInfoComponent {
  feldName = input.required<string>();
  feldInfo = input.required<FeldInfo>();

  infoTextId = computed(() => 'popover-text-' + this.feldName());
  htmlContent = computed(() => {
    const infoValue = this.feldInfo();
    const infoTextId = this.infoTextId();
    if (!infoValue || !infoTextId) {
      return '';
    }

    let htmlContent: string;
    if (infoValue.text.includes('{{link}}')) {
      if (infoValue.link && infoValue.link.url && infoValue.link.label) {
        htmlContent = infoValue.text.replace(
          '{{link}}',
          '<dx-link id="popover-text-link-' +
            this.feldName() +
            '" href="' +
            infoValue.link.url +
            '" target="_blank">' +
            infoValue.link.label +
            '</dx-link>'
        );
      } else {
        return '';
      }
    } else {
      htmlContent = infoValue.text;
    }
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  });

  showInfo = computed(() => {
    return this.htmlContent() !== '';
  });

  constructor(private sanitizer: DomSanitizer) {}
}
