import { Directive, Output, EventEmitter, HostBinding, HostListener, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[libDragDrop]',
  standalone: false
})
export class DragDropDirective {
  @Output() fileDropped = new EventEmitter<any>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('style.background-color') public background = 'white';
  @HostBinding('style.opacity') public opacity = '1';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#dedede';
    this.opacity = '0.6';
    this.changeDetectorRef.detectChanges();
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'white';
    this.opacity = '1';
    this.changeDetectorRef.detectChanges();
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'white';
    this.opacity = '1';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
    this.changeDetectorRef.detectChanges();
  }
}
