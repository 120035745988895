<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <dx-date-input
    #focusElement
    [formControlName]="field.name"
    id="{{ field.name }}_wert"
    [label]="field.label"
    (focus)="storeNameOfFocusedElement(field.name)"
    [required]="field.required"
    (errorFound)="triggerError($event)"
    [errormessage]="errormessage"
    (blur)="validate($event.detail)"
    [kisynced]="field.kiSync"
    (valueChange)="handleEvent($event)"
    (valueComplete)="handleEvent($event)"
    [attr.templatevalue]="aiPredicted ? initialValue : null"
    [templateindicatortype]="'ocr'"
  >
    @if (feldInfo) {
      <app-input-field-info slot="info" [feldName]="field.name" [feldInfo]="feldInfo"></app-input-field-info>
    }
  </dx-date-input>

  <div class="hinweis" id="hinweis" *ngIf="showHinweisAblaufdatumFortgeschrieben && !showHinweisFortschreiben">
    <dx-icon icon="information" size="24" id="info"></dx-icon>
    <dx-text type="its" id="hinweis-text"
      >Ablaufdatum wurde zur Sichtbarmachung in der KI in die Zukunft gesetzt.
      <dx-link id="hinweis-text-zuruecksetzen-link" (click)="patchValueWithOriginalValue()">
        <dx-text>Zurücksetzen</dx-text>
      </dx-link>
    </dx-text>
  </div>
  <div
    class="hinweis"
    id="hinweisFortschreiben"
    *ngIf="showHinweisFortschreiben && !showHinweisAblaufdatumFortgeschrieben"
  >
    <dx-icon icon="information" size="24" id="info-buttonFortschreiben"></dx-icon>
    <dx-text type="its" id="hinweis-textFortschreiben"
      >Für Sichtbarmachung in der KI anpassen.
      <dx-link id="hinweis-textFortschreiben-link" (click)="setDateToFuture()">
        <dx-text>Datum in die Zukunft setzen</dx-text>
      </dx-link>
    </dx-text>
  </div>
</div>
<app-readonly-template
  *ngIf="isReadonly"
  [field]="field"
  [displayValue]="getReadonlyDisplayValue()"
></app-readonly-template>
