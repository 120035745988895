import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Haushalt, Kunde } from '../models/kunde.model';
import { Fremdvertrag } from '../models/fremdvertrag.model';
import { Auftrag } from '../models/auftrag.model';
import { EinkommenKunde } from '../../components/formular/basis-unterformular/basis-einkommen-unterformular/model/einkommen.model';

@Injectable({
  providedIn: 'root'
})
export class KundenHttpService {
  constructor(private httpClient: HttpClient) {}

  fetchKunden(searchQuery: string) {
    return this.httpClient.get<Kunde[]>(environment.be.baseUrl + '/personen?name=' + searchQuery);
  }

  fetchFremdvertraege(kundenNummer: string): Observable<Fremdvertrag[]> {
    return this.httpClient.get<Fremdvertrag[]>(
      environment.be.baseUrl + '/personen/' + kundenNummer + '/fremdvertraege'
    );
  }

  fetchFremdvertragToId(kundenNummer: string, kiFremdvertragId: string): Observable<Fremdvertrag> {
    return this.httpClient.get<Fremdvertrag>(
      environment.be.baseUrl + '/personen/' + kundenNummer + '/fremdvertraege/' + kiFremdvertragId
    );
  }

  fetchHaushalte(kundenNummer: string): Observable<Haushalt[]> {
    return this.httpClient.get<Haushalt[]>(environment.be.baseUrl + '/personen/' + kundenNummer + '/haushalte');
    //return throwError(() => 'kaputtt')
  }

  updateKundenNummerOnAuftrag(auftragId: string, payload: any): Observable<Auftrag> {
    return this.httpClient.put<Auftrag>(environment.be.baseUrl + '/auftraege/' + auftragId + '/kundennummer', payload);
  }

  fetchEinkommen(kundenNummer: string): Observable<EinkommenKunde[]> {
    return this.httpClient.get<any>(`${environment.be.baseUrl}/personen/${kundenNummer}/einkommen`);
  }
}
