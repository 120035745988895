import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LeistungsradarResult } from '../../../../projects/leistungsradar/src/dt/model/leistungsradarresult.model';
import { LeistungsradarVergleichsoptionen } from '../models/leistungsradar-vergleichsoptionen.model';

@Injectable({
  providedIn: 'root'
})
export class LeistungsradarHttpService {
  constructor(private httpClient: HttpClient) {}

  generateLeistungsVergleich(vergleichsOptionen: LeistungsradarVergleichsoptionen): Observable<LeistungsradarResult> {
    return this.httpClient.post<any>(environment.be.baseUrl + '/leistungsradar/vergleiche', vergleichsOptionen);
  }

  fetchLeistungsVergleich(vergleichId: string): Observable<LeistungsradarResult> {
    return this.httpClient.get<LeistungsradarResult>(
      environment.be.baseUrl + '/leistungsradar/vergleiche/' + vergleichId
    );
  }
}
