import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormularFeldConfig } from 'src/app/store/models/formular-feld-config.model';
import { TextComponent } from '../basis-input-formular-feld/text/text.component';
import { DatumComponent } from '../basis-input-formular-feld/datum/datum.component';
import { CheckboxComponent } from '../basis-input-formular-feld/checkbox/checkbox.component';
import { DropdownComponent } from '../basis-input-formular-feld/dropdown/dropdown.component';
import { IbanComponent } from '../basis-input-formular-feld/iban/iban.component';
import { TextareaComponent } from '../basis-input-formular-feld/textarea/textarea.component';
import { ProzentComponent } from '../basis-number-input-formular-feld/prozent/prozent.component';
import { MultiTextComponent } from '../basis-input-formular-feld/multi-text/multi-text.component';
import { DynSelectComponent } from '../basis-input-formular-feld/dyn-select/dyn-select.component';
import { DynAutocompleteComponent } from '../basis-input-formular-feld/dyn-autocomplete/dyn-autocomplete.component';
import { LeistungsradarComponent } from '../basis-input-formular-feld/leistungsradar/leistungsradar.component';
import { WertEinheitComponent } from '../basis-number-input-formular-feld/wert-einheit/wert-einheit.component';
import { BasisFormularFeld } from '../basis-formular-feld/basis-formular-feld';
import { BetragComponent } from '../basis-number-input-formular-feld/betrag/betrag.component';
import { HinweisComponent } from '../basis-formular-feld/hinweis/hinweis.component';
import { HeadlineComponent } from '../basis-formular-feld/headline/headline.component';
import { DezimalzahlComponent } from '../basis-number-input-formular-feld/dezimalzahl/dezimalzahl.component';
import { HorizontalLineComponent } from '../basis-formular-feld/horizontal-line/horizontal-line.component';
import { LinkComponent } from '../basis-formular-feld/link/link.component';
import { GanzzahlComponent } from '../basis-number-input-formular-feld/ganzzahl/ganzzahl.component';
import { UnknownComponent } from '../basis-formular-feld/unknown/unknown.component';
import { KennzeichenComponent } from '../basis-input-formular-feld/kennzeichen/kennzeichen.component';
import { FormularFeldDictionaryService } from '../../auftrag-bearbeitung/components/vertrags-formular/services/formular-feld-dictionary.service';
import { SingleSelectComponent } from '../basis-input-formular-feld/single-select/single-select.component';
import { GehaltComponent } from '../basis-unterformular/basis-einkommen-unterformular/gehalt/gehalt.component';
import { SonderzahlungComponent } from '../basis-unterformular/basis-einkommen-unterformular/sonderzahlung/sonderzahlung.component';
import { VersichertePersonUnfallComponent } from '../basis-unterformular/versicherte-person-unfall/versicherte-person-unfall.component';

const componentMapper = {
  TEXT: TextComponent,
  HEADLINE: HeadlineComponent,
  HORIZONTAL_LINE: HorizontalLineComponent,
  DATUM: DatumComponent,
  BETRAG: BetragComponent,
  CHECKBOX: CheckboxComponent,
  SELECT: DropdownComponent,
  IBAN: IbanComponent,
  DEZIMAL: DezimalzahlComponent,
  GANZZAHL: GanzzahlComponent,
  TEXTAREA: TextareaComponent,
  PROZENT: ProzentComponent,
  MULTITEXT: MultiTextComponent,
  UNKNOWN: UnknownComponent,
  HINWEIS: HinweisComponent,
  DYN_SELECT: DynSelectComponent,
  DYN_AUTOCOMPLETE: DynAutocompleteComponent,
  LEISTUNGSRADAR: LeistungsradarComponent,
  LINK: LinkComponent,
  WERT_EINHEIT: WertEinheitComponent,
  KENNZEICHEN: KennzeichenComponent,
  SINGLE_SELECT: SingleSelectComponent,
  GEHALT: GehaltComponent,
  SONDERZAHLUNG: SonderzahlungComponent,
  VERSICHERTE_PERSONEN_UNFALL: VersichertePersonUnfallComponent
};

@Directive({
  selector: '[appDynFormularFeld]',
  standalone: false
})
export class FormularFeldDirective implements OnChanges {
  @Input() field: FormularFeldConfig;
  @Input() group: FormGroup;
  @Input() currentFormularId: string;
  @Input() auftragId: string;
  @Input() isReadonly: boolean;
  @Input() formularFeldDictionaryService: FormularFeldDictionaryService;
  private componentRef: ComponentRef<BasisFormularFeld>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.componentRef) {
      this.componentRef.instance.isReadonly = this.isReadonly;
    } else {
      let factory;
      try {
        factory = this.resolver.resolveComponentFactory(componentMapper[this.field.type]);
      } catch (e) {
        console.log(
          'No matching component for field "%s" found. UnknownComponent is used instead - Error: ',
          this.field.type,
          e
        );
        factory = this.resolver.resolveComponentFactory(componentMapper.UNKNOWN);
      }
      this.componentRef = this.container.createComponent<BasisFormularFeld>(factory);
      this.componentRef.instance.initBasisFormularFeld(
        this.group,
        this.field,
        this.currentFormularId,
        this.isReadonly,
        this.auftragId,
        this.formularFeldDictionaryService
      );
    }
  }
}
