import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormularHttpService } from '../../../../store/services/formular.http.service';
import { delay, distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { BasisInputFormularFeld } from '../basis-input-formular-feld';
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service';
import { Components } from '@dvag/design-system';
import DxDropdown = Components.DxDropdown;

@Component({
  selector: 'app-dyn-select',
  templateUrl: './dyn-select.component.html',
  styleUrls: ['./dyn-select.component.scss'],
  standalone: false
})
export class DynSelectComponent extends BasisInputFormularFeld implements OnInit, OnDestroy {
  @HostBinding('class.formular-field-fullwidth')
  loading = false;
  isFullWidth: boolean;
  dynSelectOptions: string[] = [];
  private initialOption: any;
  private completedValueSub: Subscription;

  @ViewChild('focusElement') focusElement: DxDropdown;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    private formularHttpService: FormularHttpService
  ) {
    super(fb, focusedControlHandlerService);
  }

  ngOnInit(): void {
    this.dynSelectOptions = this.field.selectOptions;
    this.isFullWidth = this.field.fullWidth;

    const abhaengigkeiten = this.field.abhaengigkeiten ?? [];
    // console.log('👽 %s: subscribe to getCompletedValues()', this.field.name)
    this.completedValueSub = this.formularFeldDictionaryService
      .getCompletedValues()
      .pipe(
        filter(completedValue => abhaengigkeiten.includes(completedValue.fieldName)),
        //tap(completedValue => console.log('👽 %s: triggered from completedValue of field %s with value %s', this.field.name, completedValue.fieldName, completedValue.value)),
        distinctUntilChanged((prev, curr) => prev.fieldName === curr.fieldName && prev.value === curr.value)
      )
      .subscribe(() => {
        // console.log('👽 %s: reset form control and load options', this.field.name)
        this.formControl.reset();
        this.dynSelectOptions = [];
        this.ladeOptionen();
      });

    if (abhaengigkeiten.length === 0) {
      this.ladeOptionen();
    }
  }

  ngOnDestroy() {
    this.completedValueSub?.unsubscribe();
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: unknown): any {
    if (this.dynSelectOptions.includes(ocrText)) {
      return ocrText;
    } else {
      return oldValue;
    }
  }

  override readValue() {
    if (this.formControl.value === this.formularFeldDictionaryService.keineVergleichsOptionen) {
      return null;
    } else {
      return this.formControl.value;
    }
  }

  override setInitialValue(value: any): void {
    console.log('zzz %s: setInitialValue is called with value: ', this.field.name, value);
    super.setInitialValue(value);
    if (value) {
      this.initialOption = value.wert;
      // console.log('👽 %s: setInitialValue is called with value: ', this.field.name, value)
      // hier sollte nur das Value gesetzt werden, was auch im FormControl steht und nur dann, wenn auch eines vorhanden ist
      this.patchValue(value.wert);
      const currentValue = this.readValue();
      if (currentValue || this.field.abhaengigkeiten.length === 0) {
        this.addToCompletedValues(currentValue);
      }
    }
  }

  keineOptionenVerfuegbar(): void {
    this.patchValue(this.formularFeldDictionaryService.keineVergleichsOptionen);
    this.addToCompletedValues(this.formularFeldDictionaryService.keineVergleichsOptionen);
  }

  private ladeOptionen(): void {
    // console.log('👽 %s: ladeOptionen', this.field.name);
    const eingaben = new Map<string, any>(
      this.field.abhaengigkeiten.map(abhaengigkeit => [abhaengigkeit, this.mainFormularGroup.get(abhaengigkeit)?.value])
    );

    // console.log('👽 %s: Werte der Abhängigkeiten: ', this.field.name, Array.from(eingaben));
    if (Array.from(eingaben.values()).includes(this.formularFeldDictionaryService.keineVergleichsOptionen)) {
      return;
    }

    // Prüfe, ob jedes key ein value hat, wenn nicht, dann skippe das Laden der Optionen
    if (!Array.from(eingaben.values()).every(value => !!value)) {
      console.log(
        '%s: Es gibt nicht zu jeder Abhängigkeit einen Wert. Daher werden keine Optionen geladen. Abhängigkeiten: ',
        this.field.name,
        Array.from(eingaben)
      );
      return;
    }

    this.loading = true;
    this.formularHttpService
      .fetchDynSelectOptions(this.formularId, this.field.name, Object.fromEntries(eingaben))
      .pipe(
        take(1),
        tap(options => {
          // console.log('👽 %s: options: ', this.field.name, options.length);
          if (options?.length > 0) {
            this.dynSelectOptions = options;
            // console.log(`👽 ${this.field.name}: 🦾🦾🦾 this.this.dynSelectOptions: ${this.dynSelectOptions}`)
          } else if (options.length === 0) {
            this.dynSelectOptions = [this.formularFeldDictionaryService.keineVergleichsOptionen];
          }
        }),
        // Ab Design-System 0.43: Options müssen vor dem patchValue gesetzt sein, sonst kann die Option nicht ausgewählt werden
        delay(100)
      )
      .subscribe({
        next: options => {
          // console.log('👽 %s: options: ', this.field.name, options)
          if (options.length > 0) {
            if (options.length === 1) {
              this.patchValue(options[0]);
              this.addToCompletedValues(options[0]);
            }
            //TODO: this.initialOptions muss bei Änderung von Gesellschaft resetet werden
            if (this.initialOption) {
              this.patchValue(this.initialOption);
              this.addToCompletedValues(this.initialOption);
            }
          } else {
            this.keineOptionenVerfuegbar();
          }
        },
        error: err => {
          this.keineOptionenVerfuegbar();
          console.log(
            'An error occured by selecting data for the field %s with the dependant values: ',
            this.field.name,
            eingaben,
            err
          );
        },
        complete: () => (this.loading = false)
      });
  }

  override async focus(): Promise<void> {
    if (this.focusElement?.focusControl) {
      try {
        await this.focusElement?.focusControl();
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`);
      }
    } else {
      console.warn(
        `An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`
      );
    }
  }
}
