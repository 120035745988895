<dx-card [ngClass]="{ auftragFocusHover: isInteractable }">
  <dx-card-header
    id="{{ auftrag?.auftragId }}-kundenname"
    icon="{{ syncError ? 'warndreieck' : null }}"
    iconcolor="{{ syncError ? 'error' : null }}"
    label="{{ auftrag?.kundenName }}"
    lines="1"
  ></dx-card-header>
  <dx-card-content>
    <ng-container [ngSwitch]="auftrag?.datenbestand">
      <section
        *ngSwitchCase="auftragDatenbestandEnum.BEREITSTELLUNG"
        id="auftrag-bereitstellung-{{ auftrag?.auftragId }}"
      >
        <dx-loading-indicator
          id="auftrag-spinner-{{ auftrag?.auftragId }}"
          size="medium"
          theme="light"
          labelalign="bottom"
          label="Dokument wird vorbereitet"
        ></dx-loading-indicator>
      </section>

      <section
        *ngSwitchCase="auftragDatenbestandEnum.BEREITSTELLUNGSFEHLER"
        id="auftrag-bereitstellungsfehler-{{ auftrag?.auftragId }}"
        class="auftrag-bereitstellungsfehler"
      >
        <div class="bereitstellungsfehler-icon-text">
          <dx-icon
            id="auftrag-fehlgeschlagen-icon-{{ auftrag?.auftragId }}"
            *ngIf="!isInteractable"
            icon="warndreieck"
            color="error"
            size="48"
          ></dx-icon>
          <dx-text id="auftrag-fehlgeschlagen-text-{{ auftrag?.auftragId }}" type="ps"
            >Vorbereitung fehlgeschlagen</dx-text
          >
        </div>
        <div class="footer-container">
          <ng-container *ngTemplateOutlet="footerActions"></ng-container>
        </div>
      </section>

      <section *ngSwitchDefault id="auftrag-offen-erfasst-{{ auftrag?.auftragId }}" class="auftrag-default">
        <dx-text id="auftrag-formular-typ-{{ auftrag?.auftragId }}" type="ps">{{
          auftrag?.vertraege?.length > 1
            ? 'Bündelvertrag'
            : (auftrag.vertraege?.[0].formularName ?? 'Typ nicht zugeordnet')
        }}</dx-text>
        <main>
          <div class="auftrag-thumbnail">
            <figure [style.background-image]="'url(' + imageUrl + ')'"></figure>
          </div>
          <div class="auftrag-status-text">
            <dx-text
              *ngIf="auftrag.datenbestand !== AuftragDatenbestand.NACHLADBAR"
              id="seitenzahl-{{ auftrag?.auftragId }}"
              type="it"
            >
              {{ auftrag?.dateien?.length }} Seite<span *ngIf="auftrag?.dateien?.length !== 1">n</span>
            </dx-text>
            <dx-text id="eingangsdatum-{{ auftrag?.auftragId }}" type="it">{{ anzeigeEingangsDatum }}</dx-text>
            <dx-text id="datenquelle-{{ auftrag?.auftragId }}" type="it">{{ quelle }}</dx-text>
          </div>
        </main>
        <footer>
          <div *ngIf="!isLeistungsradarFaehig" id="leistungsradar-placeholder-{{ auftrag?.auftragId }}"></div>
          <div
            *ngIf="isLeistungsradarFaehig"
            id="leistungsradar-{{ auftrag?.auftragId }}"
            class="leistungsradar-headline"
          >
            <img
              id="leistungsradar-icon-{{ auftrag?.auftragId }}"
              src="./assets/components/leistungsradar/generali.svg"
              alt="Generali-Logo"
            />
            <dx-text id="leistungsradar-label-{{ auftrag?.auftragId }}" type="its">Leistungsradar</dx-text>
          </div>
          <ng-container *ngTemplateOutlet="footerActions"></ng-container>
        </footer>
      </section>
    </ng-container>
  </dx-card-content>
</dx-card>

<ng-template #footerActions>
  <div id="footer-actions" class="footer-actions">
    <app-download-auftrag
      *ngIf="auftrag?.datenbestand !== auftragDatenbestandEnum.BEREITSTELLUNG"
      id="download-button-{{ auftrag?.auftragId }}"
      [auftragsId]="auftrag.auftragId"
      [kundenNummer]="auftrag.kundenNummer"
      [goyaId]="auftrag.goyaId"
      iconOnly="true"
      (handleDownloadError)="onDownloadError()"
    ></app-download-auftrag>
    <dx-icon
      *ngIf="auftrag?.datenbestand !== auftragDatenbestandEnum.BEREITSTELLUNG"
      id="loeschen-button-{{ auftrag?.auftragId }}"
      icon="loeschen"
      size="24"
      color="error"
      (click)="onDeleteAuftrag($event)"
    >
    </dx-icon>
  </div>
</ng-template>
