import { Inject, Injectable } from '@angular/core';

@Injectable()
export class CookiesStorageService {
  public constructor(@Inject('document') public document: any) {}

  public getCookie(cookieName: string): string {
    let value = '';
    const name = cookieName + '=';
    const currentPageCookies = document.cookie.split(';');
    const cookie = currentPageCookies.map(cookieNow => cookieNow.trim()).find(cookieNow => cookieNow.startsWith(name));
    if (cookie) {
      value = cookie.substring(name.length, cookie.length);
    }

    return value;
  }

  public setCookie(cookieName: string, cookieValue: string, daysToExpire: number) {
    const dateNow = new Date();
    dateNow.setTime(dateNow.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + dateNow.toUTCString();
    this.document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
  }
}
