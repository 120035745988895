<div *ngIf="kunden?.length === 0" class="keinekunden">
  <dx-text id="kunde-nicht-gefunden" type="ps"
    >Dieser Kunde konnte nicht gefunden werden. Bitte überprüfen Sie Ihre Eingabe.
  </dx-text>
</div>
<dx-radio-button-group id="haushalt_radio-group" class="haushalt_radio-group" [value]="selektierterKunde?.nummer">
  <dx-list-item
    *ngFor="let kunde of groupedKunden; let idx = index"
    (click)="selektiereKunde(kunde)"
    [value]="kunde.nummer"
    id="kunde_{{ idx }}"
    type="radio"
    [disabled]="!kunde.eweZugestimmt"
    label="{{ kunde?.vorname }} {{ kunde?.nachname }}"
    sublabel="{{ kunde?.geburtsDatum ? 'Geboren am ' + (kunde.geburtsDatum | date: 'd. MMMM yyyy') : '' }} {{
      showKundenAdresse && kunde.adressen?.length === 1
        ? '\n' + kunde.adressen[0]?.strasse + ', ' + kunde.adressen[0]?.plz + ' ' + kunde.adressen[0]?.ort
        : ''
    }}"
    contentenabled="true"
  >
    <div
      *ngIf="kunde.adressen?.length > 1; else keineEwe"
      slot="content"
      style="display: flex; flex-direction: row; align-items: center"
    >
      <dx-info id="info{{ idx }}" size="content" (click)="$event.stopPropagation()">
        <dx-popover id="popover{{ idx }}" slot="modal">
          <dx-text type="it">
            {{ getCustomerText(kunde) }}
          </dx-text>
          <br />
          <div *ngFor="let adresse of kunde.adressen; let i = index">
            <dx-text *ngIf="showKundenAdresse" type="it"
              >{{ i + 1 }}. {{ adresse.strasse }}, {{ adresse.plz }} {{ adresse.ort }}</dx-text
            >
          </div>

          <dx-text *ngIf="!kunde.eweZugestimmt" type="it">
            <br />Damit Sie Dokumente hochladen können, holen Sie bitte zunächst die Einwilligung zur Datenverarbeitung
            ein.
          </dx-text>
        </dx-popover>
      </dx-info>
    </div>

    <ng-template #keineEwe>
      <dx-info
        *ngIf="!kunde.eweZugestimmt"
        slot="content"
        id="infoEWE{{ idx }}"
        size="content"
        (click)="$event.stopPropagation()"
      >
        <dx-popover id="popoverEWE{{ idx }}" slot="modal">
          <dx-text type="it">
            Damit Sie Dokumente hochladen können, holen Sie bitte zunächst die Einwilligung zur Datenverarbeitung ein.
          </dx-text>
        </dx-popover>
      </dx-info>
    </ng-template>
  </dx-list-item>
</dx-radio-button-group>
