import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { detectStage } from '../projects/upload-components/src/lib/stage/stage';
import { defineCustomElements as defineDesignSystemIcons } from '@dvag/design-system-icons/loader';
import { defineCustomElements as defineImageCropper } from '@dvag/img-cropper-normalizer/loader';

const stage = detectStage(document.location.hostname);
defineImageCropper(window).then();
defineDesignSystemIcons(window);

if (environment.production) {
  enableProdMode();
  if (stage === 'produktion') {
    if (window) {
      window.console.log = () => {
        // do nothing.
      };
      window.console.error = () => {
        // do nothing.
      };
    }
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
