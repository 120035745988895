<input
  #uploadDialogInput
  type="file"
  id="hidden-file-select"
  class="hidden-file-select"
  [accept]="acceptedTypes.join(',')"
  (change)="handleFileInput($event.target.files)"
  [multiple]="mergeFilesToPDF || maxFilesCount !== 1"
/>
<lib-image-cropper
  [shouldHideFooter]="true"
  (croppingDone)="applyCroppedImage($event)"
  (cropperOpen)="isCropperOpen($event)"
>
</lib-image-cropper>

<div class="file-upload-content">
  <div class="dialog-content" id="upload-dialog_tab-dateiauswahl" [ngClass]="{ 'no-grid-gap': files.length === 0 }">
    <div class="content-label">
      <dx-text
        *ngIf="!mergeFilesToPDF; else anzahlAnzeigeOnMergeFilesToPDF"
        id="dateiauswahl-anzahl-dateien"
        type="it"
        [ngClass]="{ error: hasToManyFiles }"
      >
        <span>{{ files.length }} von max. {{ maxFilesCount }}</span>
        {{ maxFilesCount === 1 ? 'Dokument' : 'Dokumenten' }} hinzugefügt
      </dx-text>
      <ng-template #anzahlAnzeigeOnMergeFilesToPDF>
        <dx-text id="dateiauswahl-anzahl-dateien-alone" type="it">
          <span>{{ files.length }} {{ files.length === 1 ? 'Dokument' : 'Dokumente' }} hinzugefügt</span>
        </dx-text>
      </ng-template>
    </div>
    <div class="files-container">
      <div
        class="dropzone"
        [ngClass]="{ 'dropbox-border': files.length >= 0 }"
        id="selected-files__dropzone"
        libDragDrop
        (fileDropped)="handleFileInput($event)"
      >
        <div *ngIf="files.length === 0" class="no-files-text">
          <dx-icon icon="dokumente" size="72"></dx-icon>
          <dx-text type="ps" id="files-container-no-files-text">
            <ng-container *ngIf="maxFilesCount === 1; else multipleFilesText">
              Ziehen Sie Ihr Dokument per Drag-and-drop in dieses Feld oder nutzen Sie den Button “Dokument hinzufügen”.
            </ng-container>
            <ng-template #multipleFilesText>
              Ziehen Sie Ihre Dokumente per Drag-and-drop in dieses Feld oder nutzen Sie den Button “Dokumente
              hinzufügen”.
            </ng-template>
          </dx-text>
        </div>
        <div
          cdkDropList
          (cdkDropListDropped)="dropFile($event)"
          [cdkDropListDisabled]="!mergeFilesToPDF"
          class="file-list"
        >
          <div *ngFor="let file of files; let idxNow = index" class="file-element" cdkDrag>
            <div class="file-drag-placeholder" *cdkDragPlaceholder></div>
            <lib-file-list-item
              [extendedFile]="file"
              [isPDF]="file.fileBlob.type.includes('application')"
              [focused]="idxNow === focusedFile"
              [showDragAndDropIcon]="mergeFilesToPDF && files.length > 1"
              (deleteFile)="onDeleteFile(file.fileBlob, idxNow)"
              (click)="setFocusedFile(idxNow)"
              (openCropperClicked)="openCropper(file.fileBlob)"
            ></lib-file-list-item>
          </div>
        </div>
      </div>
    </div>

    <div class="preview-container" *ngIf="files.length >= 1">
      <div *ngIf="files[focusedFile]?.isPasswortGeschuetzt" class="no-preview icon-message">
        <dx-icon icon="schloss-geschlossen" color="gray-16" size="72"></dx-icon>
        <dx-text type="it" class="pw-info-text">Das hinzugefügte Dokument ist passwortgeschützt.</dx-text>
        <dx-text type="it" class="pw-info-text"
          >Bitte entfernen Sie die mit einem Schlosssymbol gekennzeichneten Dokumente und fügen diese erneut hinzu,
          sobald Sie den Passwortschutz aufgelöst haben.
        </dx-text>
      </div>
      <object
        *ngIf="
          files[focusedFile]?.fileBlob.type.includes('application') &&
          environmentInfoService.checkiOSDevice() &&
          !files[focusedFile]?.isPasswortGeschuetzt
        "
        [data]="files[focusedFile]?.previewUrl"
        type="application/pdf"
        class="preview-file preview-pdf-file"
      >
        <dx-text>Ihr Browser unterstützt leider keine PDF Vorschau</dx-text>
      </object>
      <iframe
        *ngIf="
          files[focusedFile]?.fileBlob.type.includes('application') &&
          !environmentInfoService.checkiOSDevice() &&
          !files[focusedFile]?.isPasswortGeschuetzt
        "
        [src]="files[focusedFile]?.previewUrl"
        allowFullScreen
        class="preview-file preview-pdf-file"
        style="padding: 4px"
        title=""
      >
        <dx-text>Ihr Browser unterstützt leider keine PDF Vorschau</dx-text>
      </iframe>
      <div
        *ngIf="files[focusedFile]?.fileBlob.type.includes('image/tiff') && !files[focusedFile]?.isPasswortGeschuetzt"
        class="no-preview icon-message"
        id="no-preview-image-file"
      >
        <dx-icon icon="fotodokument" color="gray-16" size="72"></dx-icon>
        <dx-text type="it" class="pw-info-text">Keine Vorschau verfügbar</dx-text>
      </div>
      <img
        *ngIf="
          files[focusedFile]?.fileBlob.type.includes('image') &&
          !files[focusedFile]?.fileBlob.type.includes('image/tiff') &&
          !files[focusedFile]?.isPasswortGeschuetzt
        "
        [src]="files[focusedFile]?.previewUrl"
        id="preview-image-file"
        class="preview-file preview-image-file"
        [alt]="getAltTextForPreviewImage()"
      />
    </div>
  </div>
  <div
    *ngIf="
      (showMergeToPdfToggle && configMaxFilesCount === 1 && files.length >= 1) ||
      (showMergeToPdfToggle && files.length > 1)
    "
    class="pdf-checkbox-container"
  >
    <dx-checkbox
      *ngIf="showMergeToPdfToggle"
      label="Zu einer PDF-Datei zusammenfügen"
      id="upload-dialog-toggle-merge-to-pdf"
      (checkedChange)="changedPdfCheckBox($event)"
      [checked]="mergeFilesToPDF"
      [disabled]="hasPasswordProtectedFiles"
    >
    </dx-checkbox>
  </div>
  <div *ngIf="!showMergeToPdfToggle && mergeFilesToPDF" class="hinweis">
    <dx-icon icon="information" size="24"></dx-icon>
    <dx-text type="ps">Dateien werden zu einem PDF zusammengeführt.</dx-text>
  </div>
</div>

<dx-alert
  #dxAlert
  id="dx-alert"
  icon="{{ alertIcon }}"
  type="{{ alertType }}"
  titlestring="{{ alertTitle }}"
  content="{{ alertBody }}"
>
  @if (hasMultipleErrors) {
    <div slot="custom" style="display: flex; flex-direction: column; gap: 16px">
      <dx-text>Mögliche Ursachen:</dx-text>
      <dx-text-list id="multiple_upload_errors">
        <dx-text id="multiple_upload_errors_protected">Mindestens ein Dokument ist kennwortgeschützt.</dx-text>
        @if (mergeFilesToPDF) {
          <dx-text id="multiple_upload_errors_filesize_merge"
            >Die Größe der zusammengefügten Datei übersteigt die maximale Größe von
            {{ maxFileSize / (1024 * 1024) }} MB.</dx-text
          >
        } @else {
          <dx-text id="multiple_upload_errors_filesize_merge"
            >Mindestens ein Dokument überschreitet {{ maxFileSize / (1024 * 1024) }} MB.</dx-text
          >
          <dx-text id="multiple_upload_errors_filescount"
            >Die maximale Dokumentenanzahl von {{ maxFilesCount }} wurde überschritten.</dx-text
          >
        }
      </dx-text-list>
      <dx-text id="multiple_upload_errors_instruction"
        >Bitte entfernen Sie die gekennzeichneten Dokumente{{
          !mergeFilesToPDF ? ' und/oder reduzieren Sie die Anzahl auf das erlaubte Maximum' : ''
        }}.</dx-text
      >
    </div>
  }
  <dx-button id="button-ok" type="primary-s" slot="action" label="Okay" (click)="dxAlert.visible = false"> </dx-button>
</dx-alert>

<dx-loading-indicator-overlay type="alert" visible="{{ isLoading }}">
  <dx-loading-indicator size="medium" theme="light" label="Bild wird geladen ..."> </dx-loading-indicator>
</dx-loading-indicator-overlay>

<dx-alert
  #uploadAbbrechen
  id="upload-abbrechen-alert"
  type="error"
  icon="warndreieck"
  titlestring="Hochladen abbrechen?"
  actionsdirection="column"
  content="Die hochgeladenen Dokumente wurden nicht gespeichert."
>
  <dx-button
    id="upload-abbrechen-bleiben"
    type="primary-s"
    slot="action"
    label="Auf Seite bleiben"
    (click)="uploadAbbrechen.visible = false"
  ></dx-button>
  <dx-button
    id="upload-abbrechen-abbrechen"
    type="text"
    slot="action"
    label="Hochladen abbrechen"
    (click)="cancelUpload()"
  ></dx-button>
</dx-alert>
