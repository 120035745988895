import { Component } from '@angular/core';
import { BasisFormularFeld } from '../basis-formular-feld';

@Component({
  selector: 'app-unknown',
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.scss'],
  standalone: false
})
export class UnknownComponent extends BasisFormularFeld {}
