import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName]'
})
export class BasisFormControlDirective implements OnInit {

  constructor(
    private controlDir: NgControl,
    private host: ElementRef<HTMLFormElement>) {
  }

  ngOnInit() {
    (this.controlDir.control as any).nativeElement = this.host.nativeElement;
  }
}
