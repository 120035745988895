<dx-error-page
  id="dt-errorpag-accessdenied"
  reason="Keine Zugangsberechtigung"
  description="Damit Sie die Anwendung nutzen können, benötigen Sie noch folgende Berechtigungen im Online System:"
>
  <p>
    <dx-text-list>
      <dx-text *ngIf="missingPermissions?.includes('permission.apps.dc.ocrlight_erlauben')">
        Leider sind Sie noch kein Pilot für diese Anwendung. Daher können Sie diese nicht nutzen.
      </dx-text>
      <dx-text *ngIf="missingPermissions?.includes('permission.ki.oki.app')">
        Zugriff auf die KI wurde nicht gesetzt
      </dx-text>
      <dx-text *ngIf="missingPermissions?.includes('permission.ki.oki.kundensuche')">
        Zugriff auf die Kundensuche wurde nicht gesetzt
      </dx-text>
      <dx-text *ngIf="missingPermissions?.includes('permission.basis.cloud.app')">
        Zugriff auf die Cloud wurde nicht gesetzt
      </dx-text>
      <dx-text *ngIf="missingPermissions?.includes('permission.ki.oki.vip_kunden')">
        Zugriff auf ausgeblendete Akten wurde nicht gesetzt
      </dx-text>
    </dx-text-list>
  </p>
  <p>
    <dx-text>Bitte wenden Sie sich an Ihren Betreuer.</dx-text>
  </p>
</dx-error-page>
