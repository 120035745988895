import { Component } from '@angular/core';
import { UploaderEvent } from '../../projects/upload-components/src/lib/model/events';
import { FileUploadService } from './components/auftraglist/services/file-upload.service';
import { EnvironmentInfoService } from '../../projects/environment-info/src/lib/environment-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent {
  constructor(
    private fileUploadService: FileUploadService,
    private environmentInfoService: EnvironmentInfoService,
    private router: Router
  ) {
    this.router.events.subscribe(_ => {
      if (this.environmentInfoService.checkBrowserIsSafari()) {
        const url = this.router.url;
        if (url.includes('/dokumentsplitscreen')) {
          document.body.classList.add('prevent-scrolling');
        } else {
          document.body.classList.remove('prevent-scrolling');
        }
      }
    });
  }

  uploadFiles(event: UploaderEvent) {
    if (event.status === 'UPLOADING') {
      this.fileUploadService.addUpload(event.id);
    } else {
      this.fileUploadService.removeUpload(event.id);
    }
  }
}
