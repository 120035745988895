<dx-alert id="alert" titlestring="Wir verwenden Cookies" visible="{{ showAlert }}">
  <div slot="custom" class="cookie-text-content">
    <dx-text type="ps"
      >Unsere Website verwendet Cookies, um Ihnen das bestmögliche Erlebnis zu bieten. Durch die Nutzung unserer Seite
      erklären Sie sich damit einverstanden.</dx-text
    >
    <dx-text type="ps"
      >Weitere Informationen zu Cookies finden Sie unter
      <dx-link id="datenschutz-link" (click)="openDatenschutz()"
        ><dx-text>Datenschutzhinweis</dx-text></dx-link
      ></dx-text
    >
  </div>
  <dx-button id="confirm-cookie" type="text" slot="action" label="Okay" (click)="closeModal()"></dx-button>
</dx-alert>
