import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class RequestIdInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req)) {
      return next.handle(req.clone({ setHeaders: { requestid: this.createRequestId() } }));
    } else {
      return next.handle(req);
    }
  }

  private createRequestId() {
    return (uuid.v4() as string).replace(/-/g, '').substr(0, 10);
  }

  private isValidRequestForInterceptor(req: HttpRequest<any>): boolean {
    return (
      (req.url.startsWith(environment.be.baseUrl) || req.url.startsWith(environment.zob.baseUrl)) &&
      !req.headers?.has('requestid')
    );
  }
}
