import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LeistungsradarComponent } from './leistungsradar.component';
import { CommonModule } from '@angular/common';
import { LeistungsradarVergleichComponent } from './components/leistungsradar-vergleich/leistungsradar-vergleich.component';
import { LeistungsradarQuickcheckComponent } from './components/leistungsradar-quickcheck/leistungsradar-quickcheck.component';
import { IndicatorBarComponent } from './components/leistungsradar-quickcheck/indicator-bar/indicator-bar.component';
import { DesignSystemModule } from '@dvag/design-system-angular';

@NgModule({
  declarations: [
    LeistungsradarComponent,
    LeistungsradarVergleichComponent,
    LeistungsradarQuickcheckComponent,
    IndicatorBarComponent
  ],
  imports: [DesignSystemModule, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [LeistungsradarComponent, LeistungsradarVergleichComponent, LeistungsradarQuickcheckComponent]
})
export class LeistungsradarModule {
  constructor() {
    const urlAdress = window.location.href;
    if (urlAdress.includes('leistungsradar')) {
      window.document
        .getElementById('fav')
        .setAttribute('href', 'https://www.generali.de/leistungsradarweb/assets/icon/favicon.png');
    }
  }
}
