import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { Vertrag } from '../models/vertrag.model';

@Injectable({
  providedIn: 'root'
})
export class VertragHttpService {
  constructor(private httpClient: HttpClient) {}

  addVertrag(auftragId: string, payload: any): Observable<Vertrag> {
    return this.httpClient.post<Vertrag>(environment.be.baseUrl + '/auftraege/' + auftragId + '/vertraege', payload);
  }

  deleteVertrag(auftragId: string, vertragId: string) {
    return this.httpClient.delete(environment.be.baseUrl + '/auftraege/' + auftragId + '/vertraege/' + vertragId);
  }

  updateFormulartyp(auftragId: string, vertragId: string, payload: any) {
    return this.httpClient.put<any>(
      environment.be.baseUrl + '/auftraege/' + auftragId + '/vertraege/' + vertragId + '/formular',
      payload
    );
  }

  fetchFormularAngaben(auftragId: string, vertragId: string) {
    return this.httpClient.get<any>(
      environment.be.baseUrl + '/auftraege/' + auftragId + '/vertraege/' + vertragId + '/angaben-mit-quelle'
    );
  }

  updateFormularAngaben(auftragId: string, vertragId: string, payload: any) {
    return this.httpClient.put<any>(
      environment.be.baseUrl + '/auftraege/' + auftragId + '/vertraege/' + vertragId + '/angaben',
      payload
    );
  }

  updateKiFremdvertrag(auftragId: string, vertragId: string, payload: any) {
    return this.httpClient.put<any>(
      environment.be.baseUrl + '/auftraege/' + auftragId + '/vertraege/' + vertragId + '/fremdvertrag',
      payload
    );
  }
}
