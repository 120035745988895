import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Stage } from '../stage/stage';
import { Observable } from 'rxjs';
import { Kunde } from '../model/kunde.model';

@Injectable({
  providedIn: 'root'
})
export class VbHttpService {
  constructor(
    private httpClient: HttpClient,
    private stage: Stage
  ) {}

  getZuletztVerwendeteKunden(): Observable<Kunde[]> {
    // Die EWE-Prüfung der zuletzt verwendeten Kunden passiert im Backend
    return this.httpClient.get<Kunde[]>(`${this.stage.getBackendUrl()}/vb/zuletztverwendetekunden`).pipe();
  }
}
