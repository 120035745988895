import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import * as uuid from 'uuid';
import { Router } from '@angular/router';

export enum NotificationTyp {
  info = 'information',
  achtung = 'achtung',
  success = 'success'
}

interface NotificationKonfiguration {
  icon: string;
  type: 'default' | 'success' | 'error';
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private renderer: Renderer2;

  private notifikationIconKonfigurations = new Map<NotificationTyp, NotificationKonfiguration>([
    [NotificationTyp.info, { icon: 'Information', type: 'default' }],
    [NotificationTyp.achtung, { icon: 'Achtung', type: 'error' }],
    [NotificationTyp.success, { icon: 'Check-Kreis', type: 'success' }]
  ]);

  constructor(
    rendererFactory: RendererFactory2,
    private router: Router
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public showNotification(
    headline: string,
    text: string,
    duration?: number,
    notificationTyp?: NotificationTyp,
    auftragsNummer?: string
  ) {
    const id = uuid.v4();
    const toast = this.renderer.createElement('dx-notification-toast') as HTMLDxNotificationToastElement;
    toast.titlestring = headline;
    toast.id = id;
    let innerHtmlContent = `<dx-text type='ps'>${text}</dx-text>`;

    if (auftragsNummer) {
      innerHtmlContent += `<dx-button slot="action" type="secondary-s" label="Wiederholen" icon="pfeil-rechts" iconposition="right" id="notification-action-button"></dx-button>`;
    }

    toast.innerHTML = innerHtmlContent;
    toast.closeafter = duration ?? null;
    toast.visible = true;
    if (notificationTyp) {
      toast.type = this.notifikationIconKonfigurations?.get(notificationTyp)?.type;
      toast.icon = this.notifikationIconKonfigurations?.get(notificationTyp)?.icon;
    }
    this.renderer.appendChild(document.body, toast);

    if (auftragsNummer) {
      const actionButton = document.getElementById(`notification-action-button`) as HTMLDxButtonElement;
      if (actionButton) {
        actionButton.addEventListener('click', () => {
          this.router.navigate(['/dokumentsplitscreen', auftragsNummer]);
          this.renderer.removeChild(document.body, toast);
        });
      }
    }

    toast.addEventListener('closeNotification', () => {
      this.renderer.removeChild(document.body, toast);
    });
  }
}
