<div id="document-assignment_{{ file.fileBlob.name }}" class="document-assignment" [class.focused-element]="isFocused">
  <div class="text">
    <dx-text class="filename" type="ps" color="{{ file.isPasswortGeschuetzt || file.isToBig ? 'error' : '' }}">
      <dx-icon
        *ngIf="file.isPasswortGeschuetzt"
        icon="schloss-geschlossen"
        class="icon-left"
        size="24"
        color="error"
      ></dx-icon>
      <dx-icon *ngIf="file.isToBig" icon="warndreieck" class="icon-left" size="24" color="error"></dx-icon
      >{{ file.fileBlob.name }}</dx-text
    >
    <dx-text color="gray-50" type="ps" class="filesize"
      ><span>{{ fileSizeString }}</span></dx-text
    >
  </div>
  <div class="icons">
    <dx-button
      *ngIf="(license$ | async) && !isPDF && !file.isPasswortGeschuetzt"
      id="{{ file.fileBlob.name }}_cropping"
      type="text"
      icon="crop"
      class="cropping"
      (click)="onOpenCropper()"
    >
    </dx-button>
    <dx-button
      id="{{ file.fileBlob.name }}_loeschen"
      type="text"
      class="icon-muell"
      icon="loeschen"
      theme="destructive"
      (click)="triggerFileDelete()"
    ></dx-button>
    <dx-button type="text" class="icon-move" icon="verschieben" [hidden]="hideDragAndDropIcon"></dx-button>
  </div>
</div>
