import { Injectable } from '@angular/core';
import { KundenHttpService } from '../../../store/services/kunden.http.service';
import { Fremdvertrag } from '../../../store/models/fremdvertrag.model';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FremdvertragsAuswahl {
  kiFremdvertragId: string;
  formularId: string;
  displayOption: string;
}

@Injectable({
  providedIn: 'root'
})
export class FremdvertraegeService {
  constructor(private kundenHttpService: KundenHttpService) {}

  /**
   * Loads a list of type Fremdvertrag to given kundennummer
   * @param kundennummer
   * @return Observable with an Array of active Fremdvertrag
   */
  fetchFremdvertraege(kundennummer: string): Observable<Fremdvertrag[]> {
    if (!kundennummer) {
      return throwError(
        () => new Error(`Fuer die Abfrage von Fremdvertraegen zu einem Kunden wird dessen Kundennummer benoetigt`)
      );
    }
    return this.kundenHttpService.fetchFremdvertraege(kundennummer);
  }

  /**
   * Loads the Fremdvertrag to given kundennummer and kiFremdvertragsId
   * @param kundennummer
   * @param kiFremdvertragId
   * @return Observable with the corresponding Fremdvertrag
   */
  fetchFremdvertrag(kundennummer: string, kiFremdvertragId: string): Observable<Fremdvertrag> {
    if (!kundennummer || !kiFremdvertragId) {
      return throwError(
        () =>
          new Error(
            `Fuer die Abfrage eines Fremdvertrags eines Kunden wird dessen Kundennummer und die ID des Fremdvertrags benoetigt`
          )
      );
    }
    return this.kundenHttpService.fetchFremdvertragToId(kundennummer, kiFremdvertragId);
  }

  /**
   * Loads a list of active contracts of type Fremdvertag to the given kundennummer and maps it to an array containing objects of type FremdvertragsAuswahl
   * @param kundennummer
   */
  fetchFremdvertragAuswahlOptionen(kundennummer: string): Observable<FremdvertragsAuswahl[]> {
    return this.fetchFremdvertraege(kundennummer).pipe(
      map(fremdvertraege =>
        fremdvertraege.map(fremdvertrag => ({
          kiFremdvertragId: fremdvertrag.kiFremdvertragId,
          formularId: fremdvertrag.formularId,
          displayOption: this.getFremdvertragDisplayOption(fremdvertrag)
        }))
      )
    );
  }

  fetchFremdvertragAuswahlOption(kundennummer: string, kiFremdvertragId: string): Observable<FremdvertragsAuswahl> {
    return this.fetchFremdvertrag(kundennummer, kiFremdvertragId).pipe(
      map(fremdvertrag => ({
        kiFremdvertragId: fremdvertrag.kiFremdvertragId,
        formularId: fremdvertrag.formularId,
        displayOption: this.getFremdvertragDisplayOption(fremdvertrag)
      }))
    );
  }

  private getFremdvertragDisplayOption(fremdvertrag: Fremdvertrag): string {
    const optionValues: string[] = [];
    if (fremdvertrag.gesellschaft) {
      optionValues.push(fremdvertrag.gesellschaft);
    } else {
      optionValues.push('Unbekannte Gesellschaft');
    }
    if (fremdvertrag.beitrag) {
      const mappedBeitrag = fremdvertrag.beitrag.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      optionValues.push('Beitrag: ' + mappedBeitrag + ' €');
    } else {
      optionValues.push('Beitrag: -');
    }
    if (fremdvertrag.vertragsnummer) {
      optionValues.push('Vertr.Nr.: ' + fremdvertrag.vertragsnummer);
    } else {
      optionValues.push('Vertr.Nr.: -');
    }
    if (!fremdvertrag.aktiv) {
      optionValues.push('aufgehoben');
    }
    if (optionValues.length === 0) {
      optionValues.push('Fremdvertrag mit unzureichenden Angaben');
    }
    return optionValues.join(' / ');
  }
}
