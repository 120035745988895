<div
  id="leistungsradar-headline"
  class="leistungsradar-headline"
  [ngStyle]="{ margin: isReadonly ? '16px 0 8px 0' : '0' }"
>
  <img
    id="leistungsradar-headline_icon"
    src="../../../../../assets/components/leistungsradar/generali.svg"
    alt="Generali-Logo"
  />
  <dx-text id="leistungsradar-headline_label" type="ps">Leistungsradar</dx-text>
</div>
<ng-container [ngSwitch]="anzeigeText">
  <div *ngSwitchCase="'wertefehlen'" [id]="'leistungsradar-hinweis_' + anzeigeText" class="leistungsradar-hinweis">
    <dx-text *ngIf="!isReadonly" type="ps">
      Ergänzen Sie die Felder
      <ng-container *ngFor="let anzeige of abhaenigkeitenAnzeigetexte">
        <dx-link
          [id]="'leistungsradar-hinweis_link_' + anzeige.fieldname"
          (click)="focusFormControl(anzeige.fieldname)"
          >{{ anzeige.text }}</dx-link
        >{{ anzeige.bindewort }}</ng-container
      >
      um einen direkten Leistungsvergleich zu erhalten.
    </dx-text>
    <dx-text *ngIf="isReadonly" type="ps"> Es liegt kein Leistungsvergleich zu den Angaben vor. </dx-text>
  </div>
  <div
    *ngSwitchCase="'keinvergleichvorhanden'"
    [id]="'leistungsradar-hinweis_' + anzeigeText"
    class="leistungsradar-hinweis"
  >
    <dx-text *ngIf="!isReadonly" type="ps">
      Es konnte kein Leistungsvergleich gefunden werden. Über die Generali Marktbeobachtung erhalten Sie bei Bedarf
      Unterstützung.
    </dx-text>
    <dx-text *ngIf="isReadonly" type="ps"> Es liegt kein Leistungsvergleich zu den Angaben vor. </dx-text>
  </div>
  <div
    *ngSwitchCase="'ladevergleich'"
    [id]="'leistungsradar-hinweis_' + anzeigeText"
    class="leistungsradar-hinweis center-content"
  >
    <dx-loading-indicator size="medium" label=""></dx-loading-indicator>
  </div>
  <div *ngSwitchDefault [id]="'leistungsradar-hinweis_' + anzeigeText" class="leistungsradar-quickcheck split">
    <dt-leistungsradar-quickcheck [vergleich]="leistungsVergleich"></dt-leistungsradar-quickcheck>
  </div>
</ng-container>

<dx-button
  id="leistungsradar-button-marktforschung"
  type="text"
  *ngIf="anzeigeText === 'keinvergleichvorhanden' && !isReadonly"
  class="leistungsradar-text-btn"
  (click)="openMarktForschung()"
  icon="link-extern"
  label="Marktbeobachtung kontaktieren"
  iconposition="right"
>
</dx-button>
<dx-button
  id="leistungsradar-oeffnen-button"
  type="text"
  *ngIf="anzeigeText !== 'keinvergleichvorhanden' && (!isReadonly || isCalculated)"
  [disabled]="!isCalculated"
  class="leistungsradar-text-btn"
  (click)="
    openLeistungsradarInNewTab(
      leistungsVergleich?.bereich.id,
      leistungsVergleich?.tarif.id,
      leistungsVergleich?.sparte?.id
    )
  "
  icon="link-extern"
  label="Zum Generali Leistungsradar"
  iconposition="right"
>
</dx-button>

<!-- Das folgende versteckte Input wird benötigt, um in den Angaben die VergleichsID mit an den Auftrag zu übergeben -->
<span [formGroup]="mainFormularGroup">
  <input [formControlName]="field.name" [hidden]="true" />
</span>
