import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './accessdenied.component.html',
  standalone: false
})
export class AccessdeniedComponent implements OnInit {
  public isLoggedIn = false;
  public missingPermissions = [];
  private routerState;

  constructor(private router: Router) {
    this.routerState = this.router.getCurrentNavigation()?.extras.state;
  }

  ngOnInit() {
    if (this.routerState) {
      this.missingPermissions = this.routerState.missingPermissions;
    } else {
      // go home (and check again)
      this.router.navigate(['/']);
    }
  }
}
