import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Auftrag } from 'src/app/store/models/auftrag.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { defaultIfEmpty, map } from 'rxjs/operators';
import { BoundingBoxData } from 'src/app/store/models/bounding-box-data.model';
import { OcrPage } from '../../store/models/ocr.model';
import { AuftragService } from '../services/auftrag.service';
import { ClickAndCollectService } from '../services/click-and-collect.service';
import { AuftragDatenbestand } from '../../enums/auftrag.enums';
import { ApplicationInsightsService } from '../../services/application-insights.service';
import { EnvironmentInfoService } from '../../../../projects/environment-info/src/lib/environment-info.service';
import { BreakpointAware } from '@dvag/design-system';

@Component({
  selector: 'app-dokumentenviewer',
  templateUrl: './dokumentenviewer.component.html',
  styleUrls: ['./dokumentenviewer.component.scss'],
  standalone: false
})
export class DokumentenviewerComponent implements OnInit, OnDestroy {
  public seltext = '';

  @Input() set auftrag(value: Auftrag) {
    //Wenn AuftragDatenbestand von NACHLADBAR in BEREIGESTELLT wechselt darf nicht raus gesprungen werden (KUBA-4450)
    if (this.currentAuftrag?.auftragId === value?.auftragId && this.currentAuftrag.dateien.length > 0) {
      return;
    }
    this.currentAuftrag = value;
    switch (this.currentAuftrag.datenbestand) {
      case AuftragDatenbestand.BEREITGESTELLT:
        this.pages = Array(this.currentAuftrag.dateien?.length);
        this.onImageChange();
        break;
      default:
        console.log('keine Dateien da');
    }
  }

  @Input()
  set deselectAllElements(triggerValue: number) {
    this.deselectAll();
  }

  @Output() handleDownloadError = new EventEmitter<any>();
  @Output() loescheAktuellenAuftrag = new EventEmitter<any>();

  private breakpointAware: BreakpointAware = new BreakpointAware(() => this.checkBreakPoint());
  currentBreakPointAsNumber: number = 5;

  public markNCopyechecked = false;
  public currentAuftrag: Auftrag;
  public fiftyfifty = false;
  public pages: {
    dateiId: string;
    saveBlobUrl: SafeUrl;
    imageWidth: number;
    imageHeight: number;
    ocr: OcrPage;
  }[] = [];

  private ocrinfoEl;

  private startZoom = 1;
  private zoomMin = 0.1;
  private zoomMax = 4;
  private safeAreaContentWidth = 20; // wird als SafeArea bei der Berechnung des Normalizer-Zoom benötigt

  zoom = this.startZoom;
  zoomedWidth = 0;
  zoomedHeight = 0;
  rotation = 0;
  normalizerZoom = 1;
  contentWidth = 0;
  contentHeight = 0;

  public imageIndex = 0;
  public errors: string[] = [];

  public boundingBoxData: BoundingBoxData[] = [];

  public zoomMinReached = false;
  public zoomMaxReached = false;

  private isIOSDevice: boolean = false;

  @ViewChild('ocrinfo', { static: true }) ocrinfoViewRef: ElementRef;
  @ViewChild('scroller', { static: true }) scroller: ElementRef;
  @ViewChild('content', { static: true }) content: ElementRef;
  loading = false;

  isDevelop = this.environmentInfoService.isDevelopmentEnv();

  constructor(
    private domSanitizer: DomSanitizer,
    private auftragService: AuftragService,
    private clickAndCollectService: ClickAndCollectService,
    private applicationInsightsService: ApplicationInsightsService,
    public environmentInfoService: EnvironmentInfoService
  ) {}

  @HostListener('window:resize', ['$event']) onResize() {
    this.onImageChange(false);
    this.setPaginationStyle();
  }

  doClick() {
    // focused-Formcontrol should be focused when no bounding box is clicked
    this.clickAndCollectService.setTextToFormControl(null);
  }

  ngOnInit() {
    this.isIOSDevice = this.environmentInfoService.checkiOSDevice() || this.environmentInfoService.checkiOS15Device();
    console.log('IOs-Device?: ', this.isIOSDevice);

    // get container element and size
    this.ocrinfoEl = this.ocrinfoViewRef.nativeElement;

    const positionOcrinfo = eventNow => {
      //eventNow.preventDefault()
      const clientX = eventNow.touches ? eventNow.touches[0].clientX : eventNow.clientX;
      const clientY = eventNow.touches ? eventNow.touches[0].clientY : eventNow.clientY;
      this.ocrinfoEl.style.left = clientX + 10 + 'px';
      this.ocrinfoEl.style.top = clientY + 30 + 'px';
    };

    // handle mouse moves to update OCR info
    this.scroller.nativeElement.addEventListener('mousemove', positionOcrinfo, true);
    // handle clicks to tell form to re-focus on last input
    this.scroller.nativeElement.addEventListener(
      'click',
      event => {
        if (event.target.tagName.toLowerCase() !== 'polygon') {
          this.doClick();
        }
      },
      true
    );
    this.setPaginationStyle();
    this.checkBreakPoint();
  }

  ngOnDestroy() {
    this.breakpointAware.disconnect();
  }

  checkBreakPoint() {
    this.currentBreakPointAsNumber = this.breakpointAware.getEffectiveValue({
      mq1: 1,
      mq2: 2,
      mq3: 3,
      mq4: 4,
      mq5: 5
    });
  }

  public updateOcrinfo(showText) {
    this.ocrinfoEl.innerText = ['o:', 'O:', '0:'].includes(showText) ? '😮' : showText;
    if (showText === '') {
      this.ocrinfoEl.style.display = 'none';
    } else {
      this.ocrinfoEl.style.display = 'block';
    }
  }

  public hasNextPage(): boolean {
    return this.imageIndex < this.pages.length - 1;
  }

  public hasPreviousPage(): boolean {
    return this.imageIndex > 0;
  }

  public onPrevImg() {
    if (!this.isIOSDevice) {
      this.doClick();
    }
    if (this.imageIndex > 0) {
      this.imageIndex--;
    }
    this.onImageChange(false);
    this.applicationInsightsService.logDokumentenViewerActionClicked('vorherige_Seite');
  }

  public onNextImg() {
    if (!this.isIOSDevice) {
      this.doClick();
    }
    if (this.imageIndex < this.pages.length - 1) {
      this.imageIndex++;
    }
    this.onImageChange(false);
    this.applicationInsightsService.logDokumentenViewerActionClicked('nächste_Seite');
  }

  public onImageChange(resetZoom = true) {
    this.loading = true;
    const requestedPage = this.imageIndex;

    const loadRequests = forkJoin(
      this.currentAuftrag.dateien
        .map((_, index) => index)
        .filter(index => (requestedPage === 0 ? index < 3 : requestedPage === index))
        .filter(index => !this.pages[index])
        .map(index => this.loadImage(index))
    );

    loadRequests.pipe(defaultIfEmpty(null)).subscribe(() => {
      if (requestedPage === this.imageIndex) {
        // Das Image ist ausschlaggebend für den gesamten content
        this.contentWidth = this.pages[requestedPage].imageWidth;
        this.contentHeight = this.pages[requestedPage].imageHeight;
        this.normalizerZoom = (this.scroller.nativeElement.clientWidth - this.safeAreaContentWidth) / this.contentWidth;

        if (resetZoom) {
          this.resetZoom();
        }
        this.createBoundingBoxes(this.pages[requestedPage].ocr);
        this.loading = false;
      }
    });
  }

  public loadImage(idx: number): Observable<void> {
    return this.auftragService.fetchDateiWithOCR(this.currentAuftrag.auftragId, this.currentAuftrag.dateien[idx]).pipe(
      map(([imgResponse, ocr]) => {
        this.pages[idx] = {
          dateiId: this.currentAuftrag.dateien[idx],
          saveBlobUrl: this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(imgResponse.blob)),
          imageWidth: imgResponse.width,
          imageHeight: imgResponse.height,
          ocr
        };
      })
    );
  }

  public onSelectValue(id: string) {
    const index = this.boundingBoxData.findIndex(elem => elem.id === id);

    if (index > -1) {
      this.deselectAll();
      this.boundingBoxData[index].isSelected = true;
      this.clickAndCollectService.setTextToFormControl(this.boundingBoxData[index].dataText);
      this.applicationInsightsService.logClickAndCollect();
    }
  }

  public deselectAll() {
    this.boundingBoxData.forEach(element => {
      element.isSelected = false;
    });
  }

  private createBoundingBoxes(ocrResult: OcrPage) {
    this.rotation = -ocrResult.angle;

    const lines = ocrResult.lines.map(
      (line, index) =>
        ({
          type: 'line',
          id: 'line-' + index,
          dataText: line.text,
          boundingBox: line.boundingBox.reduce(this.toXYKoordinaten, ''),
          isSelected: false,
          xValue: this.getKoordValue(line.boundingBox.reduce(this.toXYKoordinaten, '')).x + 5,
          yValue: this.getKoordValue(line.boundingBox.reduce(this.toXYKoordinaten, '')).y,
          wordLength: this.getWordlength(line.boundingBox.reduce(this.toXYKoordinaten, '')),
          fontSize: this.getFontSize(line.boundingBox.reduce(this.toXYKoordinaten, ''))
        }) as BoundingBoxData
    );

    const words = ocrResult.lines
      .map(line => line.words)
      .reduce((acc, val) => acc.concat(...val), [])
      .map(
        (word, index) =>
          ({
            type: 'word',
            id: 'word-' + index,
            dataText: word.text,
            boundingBox: word.boundingBox.reduce(this.toXYKoordinaten, ''),
            isSelected: false,
            xValue: this.getKoordValue(word.boundingBox.reduce(this.toXYKoordinaten, '')).x,
            yValue: this.getKoordValue(word.boundingBox.reduce(this.toXYKoordinaten, '')).y,
            wordLength: this.getWordlength(word.boundingBox.reduce(this.toXYKoordinaten, '')),
            fontSize: this.getFontSize(word.boundingBox.reduce(this.toXYKoordinaten, ''))
          }) as BoundingBoxData
      );

    this.boundingBoxData = [...lines, ...words];
  }

  getKoordValue(boundingBox): any {
    return boundingBox
      .split(' ') // boundingBox="100,400 101,401" => bildet ["100,400" "101,401"]
      .map(xyCoord => {
        const [x, y] = xyCoord.split(',');
        return { x, y };
      }) // bildet [{x: 100, y:400},{x: 101, y:401} ] objekte))
      .reduce((acc, cur) => ({ x: Math.min(acc.x, cur.x), y: Math.max(acc.y, cur.y) })); //{x: 153, y: 151} kleinste x- und y-Wert
  }

  getWordlength(boundingBox): any {
    const minX = boundingBox
      .split(' ')
      .map(xyCoord => {
        const [x, y] = xyCoord.split(',');
        return { x, y };
      })
      .reduce((prev, curr) => (prev.x < curr.x ? prev : curr));

    const maxX = boundingBox
      .split(' ')
      .map(xyCoord => {
        const [x] = xyCoord.split(',');
        return { x };
      })
      .reduce((prev, curr) => {
        return prev.x > curr.x ? prev : curr;
      });
    return Math.abs(maxX.x - minX.x);
  }

  getFontSize(boundingBox): any {
    const minY = boundingBox
      .split(' ')
      .map(xyCoord => {
        const [x, y] = xyCoord.split(',');
        return { x, y };
      })
      .reduce((prev, curr) => (prev.y < curr.y ? prev : curr));

    const maxY = boundingBox
      .split(' ')
      .map(xyCoord => {
        const [x, y] = xyCoord.split(',');
        return { x, y };
      })
      .reduce((prev, curr) => {
        return prev.y > curr.y ? prev : curr;
      });
    return Math.abs(maxY.y - minY.y);
  }

  public changeZoom(addToZoom: number) {
    if (!this.isIOSDevice) {
      this.doClick();
    }
    console.log('addZoom: ', addToZoom);
    this.zoom += addToZoom;
    console.log('zoom: ', this.zoom);
    this.zoom = Math.max(this.zoom, this.zoomMin);
    console.log('this.zoom = Math.max(this.zoom, this.zoomMin)', this.zoom);
    this.zoom = Math.min(this.zoom, this.zoomMax);
    console.log('this.zoom = Math.min(this.zoom, this.zoomMax)', this.zoom);

    this.zoomMaxReached = this.zoom === this.zoomMax;
    this.zoomMinReached = this.zoom === this.zoomMin;

    console.log('scroller: ', this.scroller.nativeElement.clientHeight, this.scroller.nativeElement.clientWidth);
    console.log(`this.zoomedWidth = ${this.contentWidth} * ${this.zoom} * ${this.normalizerZoom} = `, this.zoomedWidth);
    console.log(
      `this.zoomedHeight = ${this.contentHeight} * ${this.zoom} * ${this.normalizerZoom} = `,
      this.zoomedHeight
    );

    this.zoomedWidth = this.contentWidth * this.zoom * this.normalizerZoom;
    this.zoomedHeight = this.contentHeight * this.zoom * this.normalizerZoom;

    console.log(`this.zoomedWidth = ${this.contentWidth} * ${this.zoom} * ${this.normalizerZoom} = `, this.zoomedWidth);
    console.log(
      `this.zoomedHeight = ${this.contentHeight} * ${this.zoom} * ${this.normalizerZoom} = `,
      this.zoomedHeight
    );

    this.applicationInsightsService.logDokumentenViewerActionClicked('zoom');
  }

  public resetZoom(zoom: number = this.startZoom) {
    this.changeZoom(zoom - this.zoom);
    // TODO: reset document scroll to top
    this.applicationInsightsService.logDokumentenViewerActionClicked('Auf_Breite_anpasssen');
  }

  public showFullDoc() {
    if (!this.isIOSDevice) {
      this.doClick();
    }

    let zoomPercent = (this.scroller.nativeElement.clientHeight / this.contentHeight / this.normalizerZoom) * 0.9;
    if (this.startZoom < zoomPercent) {
      zoomPercent = this.startZoom;
    }
    this.resetZoom(zoomPercent);
    this.applicationInsightsService.logDokumentenViewerActionClicked('ganzes_Dokument_anzeigen');
  }

  private toXYKoordinaten(acc: string, val: number, index: number, array: number[]) {
    if (index % 2 === 1) {
      return (acc + ' ' + array[index - 1] + ',' + array[index]).trim();
    }
    return acc;
  }

  public setPaginationStyle() {
    if (window.innerWidth >= 769) {
      this.fiftyfifty = false;
    } else {
      this.fiftyfifty = true;
    }
  }

  public toggleSelectNCopyMode() {
    this.markNCopyechecked = !this.markNCopyechecked;
  }

  updateOcrinfoForSelection() {
    if (document.getSelection().toString()) {
      this.seltext = document.getSelection().toString();
    }
    this.seltext = this.seltext.replace(/(\n)/gm, ' ');
    console.log('this.seltext', this.seltext);
    this.ocrinfoEl.innerText = this.seltext;
    if (this.seltext === '') {
      this.ocrinfoEl.style.display = 'none';
    } else {
      this.ocrinfoEl.innerText = 'Zu kopierender Text: ' + this.seltext;
      this.ocrinfoEl.style.display = 'block';
    }
  }

  copySelectedText() {
    console.log('seltex', this.seltext);
    document.execCommand('copy');
    this.clickAndCollectService.setTextToFormControl(this.seltext);
  }

  public hideOcrinfo() {
    this.ocrinfoEl.style.display = 'none';
    this.ocrinfoEl.innerText = '';
  }

  downloadButtonClicked() {
    this.applicationInsightsService.logDokumentenViewerActionClicked('Download');
  }

  deleteAktuellenAuftrag() {
    this.loescheAktuellenAuftrag.emit();
    this.applicationInsightsService.logDokumentenViewerActionClicked('Lösche_Auftrag');
  }
}
