import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Auftrag } from '../models/auftrag.model';
import { DeleteAuftrag, FetchAuftraege } from '../actions/auftrag.actions';
import { AuftragHttpService } from '../services/auftrag.http.service';
import { Injectable } from '@angular/core';

export class AuftragStateModel {
  public auftraege: Auftrag[];
}

@State<AuftragStateModel>({
  name: 'auftraege',
  defaults: {
    auftraege: []
  }
})
@Injectable()
export class AuftragState {
  @Selector()
  static getAuftragList(state: AuftragStateModel) {
    return state.auftraege;
  }

  constructor(private auftragHttpService: AuftragHttpService) {}

  @Action(FetchAuftraege, { cancelUncompleted: true })
  getAuftraege({ patchState }: StateContext<AuftragStateModel>) {
    return this.auftragHttpService.fetchAuftraege().pipe(
      tap(result => {
        patchState({
          auftraege: result
        });
      })
    );
  }

  @Action(DeleteAuftrag)
  deleteAuftrag({ getState, patchState }: StateContext<AuftragStateModel>, { auftragId }: DeleteAuftrag) {
    return this.auftragHttpService.deleteAuftrag(auftragId).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.auftraege.filter(auftrag => auftrag.auftragId !== auftragId);
        patchState({
          auftraege: filteredArray
        });
      })
    );
  }
}
