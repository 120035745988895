import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { FormularFeldValidatoren } from '../../../validators/formular-feld-validatoren';
import { AktivesEingabefeldHandlerService } from '../../../basis-input-formular-feld/services/aktives-eingabefeld-handler.service';
import { Observable, Subscription } from 'rxjs';
import { Einkommen, EinkommenDTO } from '../model/einkommen.model';
import { BasisEinkommenUnterformular } from '../basis-einkommen-unterformular';
import { EinkommenService } from '../services/einkommen.service';

@Component({
  selector: 'app-gehalt',
  templateUrl: './gehalt.component.html',
  styleUrls: ['./gehalt.component.scss'],
  standalone: false
})
export class GehaltComponent extends BasisEinkommenUnterformular implements OnInit, OnDestroy {
  anzahlGehaelterMaximum = 99.9;

  gehaelter: Einkommen[] = null;
  gehaelter$: Observable<Einkommen[]>;
  gehaelterSub: Subscription;

  @HostBinding('class.formular-field-fullwidth') isFullWidth: boolean;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService,
    private einkommenService: EinkommenService
  ) {
    super(fb, focusedControlHandlerService);
  }

  ngOnInit(): void {
    this.gehaelter$ = this.einkommenService.gehalt$;
    this.gehaelterSub = this.gehaelter$.subscribe((data: Einkommen[]) => {
      this.gehaelter = data;
    });
  }

  override setInitialValue(value: any) {
    value = this.checkInitialValueForExistingEinkommen(value, this.gehaelter);
    super.setInitialValue(value);

    this.formControl.get('netto').setValue(value.netto?.wert);
    this.formControl.get('brutto').setValue(value.brutto?.wert);
    this.formControl.get('anzahlMonate').setValue(value.anzahlMonate?.wert);
  }

  override buildFormControl(): AbstractControl {
    return this.fb.group(
      {
        netto: [
          { value: '', disabled: false },
          [FormularFeldValidatoren.maximum(this.maximum), FormularFeldValidatoren.minimum(this.minimum)]
        ],
        brutto: [
          { value: '', disabled: true },
          [FormularFeldValidatoren.maximum(this.maximum), FormularFeldValidatoren.minimum(this.minimum)]
        ],
        anzahlMonate: [
          { value: '12', disabled: true },
          [
            FormularFeldValidatoren.required(),
            FormularFeldValidatoren.maximum(this.anzahlGehaelterMaximum),
            FormularFeldValidatoren.minimum(this.minimumAnzahlGehaelter)
          ]
        ],
        version: [''],
        bestandsId: [{ value: this.initialValue?.bestandsId || '', disabled: true }]
      },
      { validators: FormularFeldValidatoren.nettoLessThanOrEqualToBrutto() }
    );
  }

  override enableFormControls(nettoValue: string): void {
    const nettoIsSet = !!nettoValue;
    const bruttoControl = this.formControl.get('brutto');
    const anzahlControl = this.formControl.get('anzahlMonate');
    const einkommenOptionControl = this.formControl.get('bestandsId');

    if (bruttoControl && anzahlControl && einkommenOptionControl) {
      if (nettoIsSet) {
        bruttoControl.enable();
        anzahlControl.enable();
        einkommenOptionControl.enable();
      } else {
        bruttoControl.disable();
        anzahlControl.disable();
        einkommenOptionControl.disable();
      }
    }
  }

  override updateVersion(selectedBestandsId: string): void {
    const selectedEinkommen = this.gehaelter.find(einkommen => einkommen.bestandsId === selectedBestandsId);

    if (selectedEinkommen) {
      this.formControl.get('version').patchValue(selectedEinkommen.version);
    }
  }

  override getErrorMessage(fieldName: string): string {
    const control = this.formControl.get(fieldName);
    if (!control) {
      return null;
    }
    if (fieldName === 'anzahlMonate') {
      if (control.hasError('maximum')) {
        return `Bitte wählen Sie einen Wert unter 100.`;
      }
      if (control.hasError('minimum')) {
        return `Bitte wählen Sie einen Wert über 0.`;
      }
      if (control.hasError('required')) {
        return `Dieses Feld darf nicht leer sein.`;
      }
    }
    return super.getErrorMessage(fieldName);
  }

  ngOnDestroy(): void {
    if (this.gehaelterSub) {
      this.gehaelterSub.unsubscribe();
    }
    this.einkommenService.resetGehalt();
  }
}
