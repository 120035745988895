import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { BasisInputFormularFeld } from '../basis-input-formular-feld';
import { FormularFeldConfig, TextKonfiguration } from '../../../../store/models/formular-feld-config.model';
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service';
import { DxTextInput } from '@dvag/design-system-angular';
import { FormularFeldDictionaryService } from '../../../auftrag-bearbeitung/components/vertrags-formular/services/formular-feld-dictionary.service';

@Component({
  selector: 'app-multi-text',
  templateUrl: './multi-text.component.html',
  styleUrls: ['./multi-text.component.scss'],
  standalone: false
})
export class MultiTextComponent extends BasisInputFormularFeld implements OnInit {
  @HostBinding('class.formular-field-fullwidth') isFullWidth: boolean;
  @ViewChild('additem') addItemElement: DxTextInput;
  public multitextFormGroup: FormGroup;
  public finalValue: string[] = [];
  override initialValue: string[];
  maxZeichen: number;

  constructor(
    public override fb: FormBuilder,
    public override focusedControlHandlerService: AktivesEingabefeldHandlerService
  ) {
    super(fb, focusedControlHandlerService);
  }

  override initBasisFormularFeld(
    group: FormGroup,
    field: FormularFeldConfig,
    formularId: string,
    isReadonly: boolean,
    auftragId?: string,
    formularFeldDictionaryService?: FormularFeldDictionaryService
  ) {
    super.initBasisFormularFeld(group, field, formularId, isReadonly, auftragId, formularFeldDictionaryService);
    if (this.field.konfiguration) {
      this.maxZeichen = (this.field.konfiguration as TextKonfiguration).maxZeichen ?? null;
    }
  }

  ngOnInit() {
    this.isFullWidth = this.field.fullWidth;
    this.multitextFormGroup = this.fb.group({
      addItem: [[]]
    });

    // set local value display
    this.mainFormularGroup.controls[this.field.name].valueChanges
      .pipe(filter(value => value))
      .subscribe(value => (this.finalValue = value.slice(0)));
  }

  public onAddItem(): void {
    const inputValue = this.addItemElement.value.trim();

    if (!inputValue) {
      return;
    }

    this.finalValue.push(inputValue);

    this.updateFormField();

    // clean value
    this.multitextFormGroup.controls.addItem.patchValue('');
  }

  public onDelItem(delIndex): void {
    this.finalValue.splice(delIndex, 1);
    this.updateFormField();
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: string[]): string[] {
    if (this.maxZeichen && ocrText.length > this.maxZeichen) {
      ocrText = ocrText.substr(0, this.maxZeichen);
    }

    if (oldValue) {
      return oldValue.concat(ocrText);
    } else {
      return [ocrText];
    }
  }

  override async focus(): Promise<void> {
    await this.addItemElement.focusControl();
  }

  override hasValueChanged(): boolean {
    const formControlValue = this.readValue();
    if (!this.initialValue && formControlValue) {
      return true;
    }

    if (Array.isArray(this.initialValue) && formControlValue) {
      const arraycheck = this.initialValue.filter(kiValue => formControlValue.some(value => kiValue === value));
      if (arraycheck.length === this.initialValue.length && this.initialValue.length === formControlValue.length) {
        // nur die Reihenfolge ist ggf. unterschiedlich somit hat sich nichts geändert
        return false;
      }
      return true;
    }
    return false;
  }

  private updateFormField(): void {
    let value;
    if (this.finalValue.length === 0 && this.initialValue === null) {
      value = null;
    } else {
      value = this.finalValue;
    }
    this.mainFormularGroup.controls[this.field.name].patchValue(value);
  }
}
