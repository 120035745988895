<ng-container *ngIf="!isReadonly">
  <div [formGroup]="mainFormularGroup">
    <dx-checkbox
      #focusElement
      [id]="field.name + '_wert'"
      [formControlName]="field.name"
      (focus)="storeNameOfFocusedElement(field.name)"
      [required]="field.required"
      [label]="field.label"
      [kisynced]="field.kiSync"
      [attr.templatevalue]="aiPredicted ? initialValue : null"
      [templateindicatortype]="'ocr'"
    >
      @if (feldInfo) {
        <app-input-field-info slot="info" [feldName]="field.name" [feldInfo]="feldInfo"></app-input-field-info>
      }
    </dx-checkbox>
  </div>
</ng-container>
<app-readonly-template
  *ngIf="isReadonly"
  [field]="field"
  [displayValue]="getReadonlyDisplayValue()"
></app-readonly-template>
