<div style="width: 100%; height: 100%; display: flex; justify-content: right; align-items: center">
  <dx-button
    id="btn-dokument-schliessen"
    class="dokument-schliessen-button"
    icon="x-gross"
    type="text"
    label="Dokument schließen"
    iconposition="right"
    (click)="closeDocument()"
  ></dx-button>
</div>

<div class="content-container" id="content-component">
  <dx-card class="kundencard">
    <dx-card-content id="kundenkontext">
      <div class="kundencard-content">
        <app-kundenzuordnung
          [auftragBearbeitungsStatus]="auftragBearbeitungsStatus"
          (kundenZuordnungChanged)="emitKundenNameChanged($event)"
        >
        </app-kundenzuordnung>
        <dx-text
          *ngIf="auftragBearbeitungsStatus === auftragBearbeitungEnum.READONLY"
          id="concat-bearbeitungs-datum"
          type="it"
          >Erfasst am {{ youngestBearbeitungsDatum | date }}
        </dx-text>
      </div>
    </dx-card-content>
  </dx-card>

  <dx-accordion-legend *ngIf="auftragBearbeitungsStatus !== auftragBearbeitungEnum.READONLY" open="false">
    <dx-legend kisynchint ocrhint requiredhint="false"></dx-legend>
  </dx-accordion-legend>

  <app-vertrag
    *ngFor="let vertrag of auftragBearbeitenService.vertraege$ | async; trackBy: trackByVertragId"
    [id]="vertrag.vertragId"
    [vertrag]="vertrag"
    [auftragBearbeitungsStatus]="auftragBearbeitungsStatus"
  >
  </app-vertrag>
</div>

<footer
  *ngIf="
    auftragBearbeitungsStatus !== auftragBearbeitungEnum.READONLY ||
    (auftragBearbeitungsStatus === auftragBearbeitungEnum.READONLY && isNachbearbeitbar)
  "
>
  <div class="footer-content">
    <dx-button
      id="formular-nachbearbeiten-button"
      class="button-right"
      label="Angaben bearbeiten"
      *ngIf="auftragBearbeitungsStatus === auftragBearbeitungEnum.READONLY"
      type="text"
      (click)="switchToEditMode()"
      [disabled]="isFormularNachbearbeitButtonDisabled || (vertraegeInCommunicationWithBackend$ | async)"
    >
    </dx-button>
    <dx-button
      class="button-left"
      label="Bündel hinzufügen"
      type="text"
      *ngIf="showAddBuendelVertragButton$ | async"
      [disabled]="syncError"
      id="fremdvertrag-hinzufuegen-button"
      (click)="addBuendelvertrag()"
      icon="plus-kreis"
    >
    </dx-button>
    <dx-button
      class="button-right"
      type="primary-m"
      label="{{ currentBreakPointAsNumber > 4 ? 'Daten übernehmen' : '' }}"
      icon="{{ currentBreakPointAsNumber <= 4 ? 'Speichern' : '' }}"
      *ngIf="
        auftragBearbeitungsStatus === auftragBearbeitungEnum.BEARBEITUNG ||
        auftragBearbeitungsStatus === auftragBearbeitungEnum.NACHBEARBEITUNG
      "
      id="formular-speichern"
      (click)="uploadData()"
      [disabled]="isFormularSubmitButtonDisabled || (vertraegeInCommunicationWithBackend$ | async)"
    >
    </dx-button>
  </div>
</footer>

<dx-loading-indicator-overlay #uploadangabenalert type="alert">
  <div style="display: flex; flex-direction: column; gap: 16px">
    <dx-text type="ps">Bitte warten ...</dx-text>
    <dx-loading-indicator label="Ihre getätigten Angaben werden übernommen." size="medium"></dx-loading-indicator>
  </div>
</dx-loading-indicator-overlay>

<dx-alert
  #auftragSenden
  id="alert"
  [visible]="false"
  icon="check-kreis"
  titlestring="Synchronisation abgeschlossen"
  content="Die nicht synchronisierten Verträge wurden entfernt. Die bereits übertragenen Verträge werden in den Bereich Bearbeitet verschoben."
>
  <dx-button id="ok-button" type="text" label="Okay" slot="action" (click)="onAlertAuftragSenden()"></dx-button>
</dx-alert>
