import { Component, Input, OnChanges } from '@angular/core';
import { LeistungsradarResult } from '../../model/leistungsradarresult.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dt-leistungsradar-quickcheck',
  templateUrl: './leistungsradar-quickcheck.component.html',
  styleUrls: ['./leistungsradar-quickcheck.component.scss'],
  standalone: false
})
export class LeistungsradarQuickcheckComponent implements OnChanges {
  result: LeistungsradarResult;
  barWidthBase: string;
  barWidthCompare: string;
  company: string;
  isCompany: string;

  @Input() set vergleich(value: LeistungsradarResult) {
    if (value) {
      this.result = value;
      this.barWidthCompare = value.quickcheck?.comparePercent?.toFixed(0);
      this.barWidthBase = value.quickcheck?.basePercent?.toFixed(0);
      this.company = value.bereich?.name;
    }
  }

  ngOnChanges(): void {
    if (this.result.bereich.name.toUpperCase() === 'ADVOCARD') {
      this.isCompany = 'advocard';
    } else if (this.result.bereich.name.toUpperCase().includes('BADENIA')) {
      this.isCompany = 'badenia';
    } else {
      this.isCompany = 'generali';
    }
  }
}
