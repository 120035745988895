<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <dx-single-select
    #focusElement
    columns="2"
    [formControlName]="field.name"
    id="{{ field.name }}_wert"
    label="{{ field.label }}"
    [required]="field.required"
    [kisynced]="field.kiSync"
    [value]="formControl.value"
    [attr.templatevalue]="aiPredicted ? initialValue : null"
    [templateindicatortype]="'ocr'"
  >
    @if (feldInfo) {
      <app-input-field-info slot="info" [feldName]="field.name" [feldInfo]="feldInfo"></app-input-field-info>
    }
    <dx-single-select-item
      *ngFor="let option of field.selectOptions"
      id="{{ field.name }}-{{ option }}"
      [value]="option"
      label="{{ option }}"
    >
    </dx-single-select-item>
  </dx-single-select>
</div>
<app-readonly-template
  *ngIf="isReadonly"
  [field]="field"
  [displayValue]="getReadonlyDisplayValue()"
></app-readonly-template>
