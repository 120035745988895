<ng-template #cardSearchHeader>
  <dx-combobox
    style="width: 100%"
    #sucheformulartyp
    usagemode="new"
    id="formulartyp-options_{{ vertrag?.vertragId }}"
    placeholder="Dokumenttyp auswählen"
    [value]="dokumentTypInputValue"
    (selectSearchOption)="onDokumentTypAuswahl($event)"
    (search)="onDokumentTypSuche($event)"
    emptysearchtext="Es wurde kein passender Eintrag gefunden"
    (click)="onlyToggleVertragWhenNotOpen($event)"
    (focus)="onFocusDokumentTypSuche($event)"
  ></dx-combobox>
</ng-template>

<dx-card>
  <!-- Kein Dokumententyp gesetzt -->
  <dx-card-content *ngIf="!vertrag?.formularId" id="card-header_{{ vertrag?.vertragId }}">
    <div style="display: flex">
      <ng-container *ngTemplateOutlet="cardSearchHeader"></ng-container>
      <dx-button
        class="delete-button"
        id="delete-vertrag_{{ vertrag?.vertragId }}"
        (click)="deleteVertrag($event)"
        icon="loeschen"
        type="text"
        theme="destructive"
      >
      </dx-button>
    </div>
  </dx-card-content>

  <!-- Dokumententyp gesetzt -->
  <dx-card-collapsable
    *ngIf="vertrag?.formularId"
    id="card-{{ vertrag?.vertragId }}"
    [open]="isOpen"
    [chevronvisible]="anzahlVertraege > 1"
  >
    <dx-card-header
      slot="header"
      id="collapsable-card-header_{{ vertrag?.vertragId }}"
      (click)="toggleVertrag()"
      [style.cursor]="anzahlVertraege > 1 ? 'pointer' : null"
    >
      <div
        class="action-container-geschlossen"
        [ngClass]="{
          'chevron-not-visible': anzahlVertraege === 1,
          'readonly-kein-fremdvertrag': !ausgewaehltesFormular?.buendelvertragFaehig
        }"
      >
        <!--Card geschlossen -->
        <div
          *ngIf="!showFormularSearch"
          class="geschlossen"
          id="auftrags-header__nachbearbeitung-{{ vertrag?.vertragId }}"
          [ngClass]="{ 'sync-icon-present': vertrag?.syncStatus !== syncStatus.EINGESTELLT }"
        >
          <div class="auftrags-header-nachbearbeitung texte">
            <!-- FormularName -->
            <dx-text
              type="ps"
              id="formulartyp_{{ vertrag?.vertragId }}"
              [color]="!vertrag.isValid ? 'error' : null"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
            >
              {{ vertrag?.formularName }}
            </dx-text>
            <!-- Fremdvertrag-anzeige -->
            <ng-container *ngIf="ausgewaehltesFormular?.buendelvertragFaehig">
              <div
                *ngIf="selectedFremdvertragDisplayOption === ''"
                id="formulartyp-sublable-skeleton_{{ vertrag?.vertragId }}"
                class="skeleton-box vertrag-header-skeleton"
              ></div>
              <dx-text
                *ngIf="selectedFremdvertragDisplayOption !== ''"
                id="formulartyp-sublable-fremdvertrag_{{ vertrag?.vertragId }}"
                type="its"
                class="selected-fremdvertra-option-label"
                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
              >
                {{ selectedFremdvertragDisplayOption }}
              </dx-text>
            </ng-container>
          </div>
          <!-- Status Icons -->
          <dx-icon
            *ngIf="
              vertrag?.syncStatus === syncStatus.SYNCHRONISIERT &&
              auftragBearbeitungsStatus === auftragBearbeitungEnum.BEARBEITUNG
            "
            icon="check-kreis"
            color="success"
            size="24"
          >
          </dx-icon>
          <dx-icon *ngIf="vertrag?.syncStatus === syncStatus.SYNCFEHLER" icon="warndreieck" color="error" size="24">
          </dx-icon>

          <!-- Delete Button -->
          <dx-button
            class="delete-button"
            id="delete-vertrag_{{ vertrag?.vertragId }}"
            *ngIf="
              anzahlVertraegeMitDokumententyp > 1 &&
              auftragBearbeitungsStatus === auftragBearbeitungEnum.BEARBEITUNG &&
              vertrag.syncStatus !== syncStatus.SYNCHRONISIERT
            "
            (click)="deleteVertrag($event)"
            icon="loeschen"
            type="text"
            theme="destructive"
          >
          </dx-button>
        </div>
      </div>

      <!--Card geöffnet und search-->
      <div class="action-container" [ngClass]="{ 'chevron-not-visible': anzahlVertraege === 1 }">
        <ng-container *ngIf="showFormularSearch">
          <ng-container *ngTemplateOutlet="cardSearchHeader"></ng-container>
          <dx-button
            class="delete-button"
            id="delete-vertrag_{{ vertrag?.vertragId }}"
            *ngIf="anzahlVertraegeMitDokumententyp > 1"
            (click)="deleteVertrag($event)"
            icon="loeschen"
            type="text"
            theme="destructive"
          >
          </dx-button>
        </ng-container>
      </div>
    </dx-card-header>

    <dx-card-content>
      <div class="card-content-container">
        <div class="formular-auswahl">
          <div *ngIf="auftragBearbeitungsStatus === auftragBearbeitungEnum.BEARBEITUNG">
            <div
              *ngIf="vertrag.syncStatus === syncStatus.SYNCFEHLER"
              class="fremdvertrag__text-container"
              id="hinweis-syncfehler"
            >
              <dx-icon icon="warndreieck" size="24" color="error"></dx-icon>
              <dx-text type="ps" id="hinweis-syncfehler-text"
                >Die Daten konnten aufgrund eines Fehlers nicht in die KI übertragen werden.
              </dx-text>
            </div>

            <div
              *ngIf="
                (auftragBearbeitungService.ladeFremdvertraege$ | async) === true &&
                ausgewaehltesFormular?.kiFremdvertragFormular
              "
            >
              <div class="fremdvertrag__text-container" id="fremdvertrag_loadingbox">
                <dx-loading-indicator
                  id="fremdvertrag_loadingbox_text"
                  size="small"
                  theme="light"
                  label="Bestehende Fremdverträge werden geladen."
                  labelalign="right"
                ></dx-loading-indicator>
              </div>
            </div>

            <ng-container
              id="select-fremdvertrag__container"
              *ngIf="
                vertrag.syncStatus !== syncStatus.SYNCHRONISIERT &&
                vertrag.kiFremdvertragOptionen?.length > 0 &&
                (auftragBearbeitungService.ladeFremdvertraege$ | async) === false
              "
            >
              <div class="fremdvertrag__text-container" id="select-fremdvertrag__hinweis-container">
                <dx-icon icon="information" size="24"></dx-icon>

                <dx-text
                  type="ps"
                  id="select-fremdvertrag-hinweis-zugeordnet"
                  *ngIf="vertrag.kiFremdvertragId; else keinFvZugeordnet"
                >
                  Es wurde ein bereits erfasster Vertrag zugeordnet.
                </dx-text>
                <ng-template #keinFvZugeordnet>
                  <dx-text type="ps" id="select-fremdvertrag__hinweis">
                    Es wurden erfasste Verträge zum ausgewählten Dokumenttyp gefunden. Möchten Sie diese ergänzen?
                  </dx-text>
                </ng-template>
              </div>

              <dx-dropdown
                id="select-fremdvertrag__wert"
                class="select-fremdvertrag-container"
                label="Gefundene Verträge"
                clearable="false"
                (valueChange)="updateVertragWithFremdvertrag($event.detail)"
                [value]="selectedFremdvertragOption"
              >
                <dx-list-item
                  value="neuer_fremdvertrag"
                  id="select-fremdvertrag_wert_Neuen-Vertrag-erfassen"
                  label="Neuen Vertrag erfassen"
                >
                </dx-list-item>
                <dx-list-item
                  *ngFor="let fremdvertrag of fvOptionen"
                  [value]="fremdvertrag.kiFremdvertragId"
                  id="select-fremdvertrag_wert_{{ fremdvertrag.kiFremdvertragId }}"
                  [label]="fremdvertrag.displayOption"
                >
                </dx-list-item>
              </dx-dropdown>
            </ng-container>
            <div
              id="vertrag-synchronisiert-hinweis_{{ vertrag?.vertragId }}"
              *ngIf="vertrag.syncStatus === syncStatus.SYNCHRONISIERT"
              class="hinweis-nachbearbeitung"
            >
              <dx-icon
                id="vertrag-synchronisiert-hinweis-icon_{{ vertrag?.vertragId }}"
                icon="information"
                size="24"
              ></dx-icon>
              <dx-text id="vertrag-synchronisiert-hinweis-text_{{ vertrag?.vertragId }}" type="ps">
                Dieser Vertrag wurde bereits erfolgreich an das DVAG Online-System übermittelt. Eine Änderung des
                zugeordneten Fremdvertrags ist für diesen Vertrag nicht mehr möglich.
              </dx-text>
            </div>
          </div>
          <div
            *ngIf="
              auftragBearbeitungsStatus === auftragBearbeitungEnum.READONLY ||
              auftragBearbeitungsStatus === auftragBearbeitungEnum.NACHBEARBEITUNG
            "
            id="formular-header__nachbearbeitung"
            class="auftrags-info-nachbearbeitung"
          >
            <div
              *ngIf="
                auftragBearbeitungsStatus === auftragBearbeitungEnum.READONLY &&
                !this.ausgewaehltesFormular?.nachbearbeitung
              "
              class="hinweis-nachbearbeitung"
            >
              <dx-icon icon="information" size="24"></dx-icon>
              <dx-text id="hinweis-nachbearbeitung" type="ps">
                Nachträgliche Änderungen sind hier nicht möglich. Laden Sie das Dokument erneut hoch, ändern Sie die
                Daten im DVAG Online-System oder in der Vermögensplanung Digital.
              </dx-text>
            </div>
          </div>
        </div>
      </div>
      <app-vertragsformular
        [formular]="ausgewaehltesFormular"
        [auftragBearbeitungsStatus]="auftragBearbeitungsStatus"
        [showLoadingIndicator]="requestingDataFromBackend"
        [auftragId]="auftragBearbeitungService.auftrag?.auftragId"
        [angaben]="initialAngaben"
        (isFormularValid)="onFormValidityChanged($event)"
      >
      </app-vertragsformular>
    </dx-card-content>
  </dx-card-collapsable>
</dx-card>

<dx-alert
  #vertragloeschen
  id="alert"
  icon="warndreieck"
  type="error"
  actionsdirection="row"
  titlestring="Vertrag entfernen"
  content="Möchten Sie den Vertrag vom Typ {{ vertrag?.formularName }} wirklich aus dem Bündel entfernen?"
>
  <dx-button
    id="button-delete"
    type="primary-s"
    slot="action"
    theme="destructive"
    label="Löschen"
    icon="loeschen"
    (click)="auftragBearbeitungService.deleteVertrag(vertrag?.vertragId)"
  ></dx-button>
  <dx-button
    id="button-abbrechen"
    type="text"
    slot="action"
    label="Abbrechen"
    (click)="vertragloeschen.visible = false"
  ></dx-button>
</dx-alert>

<dx-alert
  #fremdvertragalert
  id="fremdvertrag-alert"
  icon="warndreieck"
  type="error"
  actionsdirection="column"
  titlestring="Fremdvertrag nicht mehr vorhanden"
  content="Der ursprünglich zugeordnete Fremdvertrag existiert nicht mehr in der Akte. Daher können die Daten nicht übernommen werden.

Sie können mit diesem Dokument einen neuen Fremdvertrag anlegen. Wenn Sie das nicht möchten, können Sie das Dokument schließen und es aus Datenturbo entfernen. Bei Bedarf laden Sie es vorher herunter, um es zu sichern."
>
  <dx-button
    id="dokument-schliessen"
    type="secondary-s"
    slot="action"
    label="Dokument schließen"
    (click)="navigateToDashboard()"
  ></dx-button>
  <dx-button
    id="button-neu-fremdvertrag"
    type="secondary-s"
    slot="action"
    label="Neuen Fremdvertrag anlegen"
    (click)="onCreateNewFremdvertrag()"
  ></dx-button>
</dx-alert>
