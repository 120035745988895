<dx-text type="ps">{{ field.label }}</dx-text>

<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <div formGroupName="{{ field.name }}">
    <dx-grid mq1="6-6/6-(6)">
      <dx-number-input
        #gehalt_netto_El
        id="gehalt_netto"
        label="Monatlich netto"
        (focus)="saveNameForOCR('netto', gehalt_netto_El, OCRType.BETRAG)"
        (valueChange)="enableFormControls($event.detail)"
        (valueComplete)="onValueComplete()"
        formControlName="netto"
        profile="currency-euro"
        kisynced="true"
        [errormessage]="formControl.invalid || formControl.get('netto').invalid ? getErrorMessage('netto') : null"
        [attr.templatevalue]="initialValue?.netto?.aiPredicted ? initialValue?.netto?.wert : null"
        [templateindicatortype]="'ocr'"
      ></dx-number-input>
      <dx-number-input
        #gehalt_brutto_El
        id="gehalt_brutto"
        (focus)="saveNameForOCR('brutto', gehalt_brutto_El, OCRType.BETRAG)"
        label="Monatlich brutto"
        formControlName="brutto"
        profile="currency-euro"
        kisynced="true"
        (valueComplete)="onValueComplete()"
        (valueChange)="resetValueComplete()"
        [errormessage]="formControl.invalid || formControl.get('brutto').invalid ? getErrorMessage('brutto') : null"
        [attr.templatevalue]="initialValue?.brutto?.aiPredicted ? initialValue?.brutto?.wert : null"
        [templateindicatortype]="'ocr'"
      ></dx-number-input>
      <dx-number-input
        #anzahlElement
        id="anzahlMonate"
        (focus)="saveNameForOCR('anzahlMonate', anzahlElement, OCRType.BETRAG)"
        label="Anzahl der Gehälter"
        formControlName="anzahlMonate"
        kisynced="true"
        precision="1"
        [errormessage]="formControl.get('anzahlMonate').invalid ? getErrorMessage('anzahlMonate') : null"
        [attr.templatevalue]="initialValue?.brutto?.aiPredicted ? initialValue?.brutto?.wert : null"
        [templateindicatortype]="'ocr'"
      ></dx-number-input>
    </dx-grid>
    <dx-radio-button-group
      id="gehalt-radio-button-group"
      label="Was möchten Sie tun?"
      formControlName="bestandsId"
      kisynced="true"
      (valueChange)="updateVersion($event.detail)"
    >
      <dx-radio-button id="radio-button-0" label="Als neues Einkommen hinzufügen" value=""></dx-radio-button>
      <dx-radio-button
        *ngFor="let einkommen of gehaelter; let i = index"
        label="Bestehendes Einkommen von {{ einkommen.displayText }} ersetzen"
        [value]="einkommen.bestandsId"
        id="radio-button-{{ i + 1 }}"
      >
      </dx-radio-button>
    </dx-radio-button-group>
  </div>
</div>

<div *ngIf="isReadonly" class="formcontrol-grid-readonly-item">
  <dx-text type="it" class="readonly-item">Monatlich netto</dx-text>
  <dx-text type="ps">
    {{ initialValue?.netto?.wert ? (initialValue.netto?.wert | number: '1.2-2' : 'de') + ' €' : '--' }}
  </dx-text>

  <dx-text type="it" class="readonly-item">Monatlich brutto</dx-text>
  <dx-text type="ps">
    {{ initialValue?.brutto.wert ? (initialValue.brutto.wert | number: '1.2-2' : 'de') + ' €' : '--' }}
  </dx-text>

  <dx-text type="it" class="readonly-item">Anzahl der Gehälter</dx-text>
  <dx-text type="ps">{{ initialValue?.anzahlMonate.wert ? initialValue?.anzahlMonate.wert : '--' }}</dx-text>
</div>
