import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationWrapperService {
  reloadApp() {
    window.location.replace('/dashboard');
  }
}
